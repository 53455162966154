import React from "react";
import { useState } from "react";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import {
  ThumbList,
  Img,
  ImgContainer,
  ListItemContainer,
  InfoContainer,
  ListContainer,
  SelectionBox,
  ListTitle,
  Buttons,
} from "./styles";

function Lista(props) {
  const { types, rooms, handlerCopy, handlerEdit, filter, handlerDelete } =
    props;

  return (
    <ListContainer>
      {types.map((type, index) => (
        <ListItemContainer key={index}>
          <ListTitle>
            <h3>{type}</h3>
          </ListTitle>
          <ThumbList>
            {rooms.map((room, index) => {
              const shouldRender = filter === "all" || filter === room.status;
              const legenda = room.status === "active" ? "" : "Inativo";
              if (room.type.description === type) {
                return (
                  shouldRender && (
                    <ImgContainer key={index}>
                      <SelectionBox>
                        <Img src={room.thumbPath}></Img>
                      </SelectionBox>
                      <InfoContainer>
                        <div>
                          <p className="room-title">{room.title}</p>
                          <p className="room-title room-inativo">{legenda}</p>
                        </div>
                        <Buttons>
                          <i
                            style={{
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            className="far fa-pen icon"
                            onClick={() => handlerEdit(room, index)}
                          ></i>

                          <i
                            onClick={() => handlerCopy(room, index)}
                            style={{
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            className="far fa-clone copy-butoon"
                          ></i>
                          <i
                            style={{
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            className="far fa-trash icon trash-cam"
                            onClick={() => {
                              handlerDelete(room);
                            }}
                          ></i>
                        </Buttons>
                      </InfoContainer>
                    </ImgContainer>
                  )
                );
              }
            })}
          </ThumbList>
        </ListItemContainer>
      ))}
    </ListContainer>
  );
}

export default Lista;
