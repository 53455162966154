import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const ModalBackground = (props) => {
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    return (
        <Container onClick={props.onClick} width={props.width} fontColor={fontColor}></Container>
    )
}

export default ModalBackground