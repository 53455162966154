import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//import { Offline, Online } from "react-detect-offline";
import { hotjar } from 'react-hotjar';
import ReactGA from "react-ga4";

import * as contractActions from './../../store/modules/contract/actions'
import CircularProgress from '@material-ui/core/CircularProgress';

import * as loginActions from './../../store/modules/login/actions'
import * as userRoomsActions from './../../store/modules/userRooms/actions'

// Styles
import { Loading, OfflineDiv, Creditos, Menu } from './styles'

// Components
import RoomsList from './components/RoomsList'
import NavigationBar from '../../components/NavigationBar'
import * as JivoSite from "react-jivosite"
const NewRoom = (props) => {
  const history = useHistory()
  const [roomType, setRoomType] = useState(
    props.location.state ? props.location.state.type : false
  );
  const [isConnectionUnstable, setIsConnectionUnstable] = useState(false)
  const [menu, setMenu] = useState(false);

  const isRoomsLoading = useSelector(state => state.contractReducer.contractRooms.isLoading)
  let contractId = useSelector(state => state.contractReducer.contract.contractId)

  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const logoPath = useSelector(state => state.contractReducer.contract.config.logoPath)

  const user = useSelector((state) => state.loginReducer.user);
  const TestUser = user.test; // useSelector((state) => state.loginReducer.user.test);
  const admin = user.type==="admin" ? true : false;
  //const admin = useSelector((state) => state.loginReducer.user).type==="admin" ? true : false;

  const dispatch = useDispatch()

  const handleLogoutClick = () => {
    dispatch(loginActions.loginFailure())
    dispatch(userRoomsActions.returnUserRoomsInitialState())
    setTimeout(() => {
      window.location.reload()
    }, 100);
  }

  useEffect(() => {
    if (navigator.connection) {
      const handleConnectionChange = () => navigator.connection.downlink < 0.45 ? setIsConnectionUnstable(true) : setIsConnectionUnstable(false)
      handleConnectionChange()
      navigator.connection.onchange = handleConnectionChange
    }

    hotjar.initialize(2967126,6)
    ReactGA.initialize('G-D62RELWLRF')
    ReactGA.send("pageview");

  }, []);

  useEffect(() => {    

    if (TestUser) {
      contractId = "61031cdbf770c825dca7647b";
    }

    if (isRoomsLoading) {
      contractId && dispatch(contractActions.getContractRooms(contractId))
    }

  }, [contractId]);

  return (
    <>
      {isRoomsLoading ? (
        <Loading>          
          <img src="https://tinyurl.com/2zjml9oh" />
          {/*<div className="titulo-2">CRIA</div>
          <div className="titulo-3">STUDIO</div> 
          <CircularProgress />*/}
        </Loading>
      ) : (
        <RoomsList type={roomType} history={history} />
      )}
      {/*<Offline>
          <OfflineDiv backgroundColor={backgroundColor}>
              Você está offline. Verifique sua conexão!
              <i className="fas fa-wifi"></i><i className="fas fa-slash"></i>
          </OfflineDiv>
      </Offline>
      <Online>
        {isConnectionUnstable &&
          <OfflineDiv>
            Conexão instável. Verifique sua conexão!
            <i className="fas fa-wifi"></i><i className="fas fa-slash"></i>
          </OfflineDiv>
        }
      </Online>*/}
      {/* <NavigationBar viewIndex={2}></NavigationBar> */}

      <Creditos>
        Desenvolvido por <b>CRIA Studio</b> LTDA - Todos os direitos reservados.
      </Creditos>

      {!isRoomsLoading && (
        <Menu>
          <div
            className={!menu ? "icone" : "icone ajuste"}
            onClick={() => setMenu(!menu)}
          >
            {!menu ? (
              <i class="fas fa-ellipsis-v"></i>
            ) : (
              <i class="fas fa-times"></i>
            )}
          </div>
          {menu && (
            <div className="content">
              {admin && (
                <button onClick={() => history.push("/admin")}>
                  Administrar
                </button>
              )}

              { contractId !== '62a896763d6e4f04a460b668' && (
              <button onClick={() => history.push("/minha-conta")}>
                <i class="fas fa-user"></i> Minha conta
              </button>
              )}

              <button onClick={handleLogoutClick}>
                <i class="fas fa-sign-out-alt"></i> Sair
              </button>
            </div>
          )}
          <JivoSite.Widget id="WmBEAzGeWt" />
        </Menu>
      )}
    </>
  );
}

export default NewRoom