import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
  height: 38px;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  @media (max-width: 768px){
    .logoClass{
      position: absolute;
      right: 25px;
    }
  }

  @media only screen and (min-width: 768px) {
    h3 {
      margin-left: 24px !important;
    }

    img {
      right: 24px !important;
    }
  }

  i {
    font-size: 16px;
    color: ${props => props.brandColor};
    left: 16px;
    position: absolute;
  }

  i:hover {
    opacity: .8;
    cursor: pointer;
  }

  img {
    height: 24px;
  }

  .p-greeting {
    left: 12px;
    position: absolute;    
    margin: 0px;
  }  
  
  .p-greeting  strong {
    font-weight: 500;
  }

  button{
    margin-left: 5px;
    border-radius: 30px;
    padding: 5px 18px;
    border: 0;
    background: limegreen;
    color: white;
  }

  .testWarning{
    margin-left: 5px;
    border-radius: 30px;
    padding: 5px 18px;
    border: 0;
    background: #119956;
    color: white;
  }

  .testOver{
    margin-left: 5px;
    border-radius: 30px;
    padding: 5px 18px;
    border: 0;
    background: gray;
    color: white;
  }

  .modalTest{
    text-align: center;
    padding: 51px;
    width: 35%;
    border-radius: 10px;
    position: absolute;
    top: 326px;
    background: white;
    z-index: 2;
  }

  .fundo{
    z-index: 1;
    top: 0;
    height: 100%;
    position: fixed;
    width: 100%;
    background: #3a3a3a6e;
  }

  .modalTest h1{
    margin-bottom: 10px
  }

`