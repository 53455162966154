import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import api from '../../services/api'

import * as loginActions from './../../store/modules/login/actions'
import * as userRoomsActions from './../../store/modules/userRooms/actions'

// Styles
import { Container, Header } from './styles'

const NewPassword = () => {
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const dispatch = useDispatch()
    const history = useHistory()

    const userId = useSelector(state => state.loginReducer.user.id)
    const contractId = useSelector(state => state.contractReducer.contractId)

    const isLoggedIn = useSelector(state => state.loginReducer.isLoggedIn)
    const isMyRoomsLoading = useSelector(state => state.userRoomsReducer.isLoading)
    const myRooms = useSelector(state => state.userRoomsReducer.rooms)

    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
    const alertColor = useSelector(state => state.contractReducer.contract.config.alertColor)
    const logoPath = useSelector(state => state.contractReducer.contract.config.logoPath)

    useEffect(() => {
        isLoggedIn && !isMyRoomsLoading && ((myRooms && myRooms != []) ? history.push("/") : history.push("/novo-ambiente"))
        isLoggedIn && isMyRoomsLoading && dispatch(userRoomsActions.getUserRooms({ "user" : userId, "contract" : contractId }))
    }, [isLoggedIn, isMyRoomsLoading])

    const handleSubmit = (e) => {
        e.preventDefault();
        password.length > 3 ? 
            (password === confirmpassword ? 
            (
                api.post('reset/' + (new URL(document.location)).searchParams.get("token"), {
                    "password": password
                })
                .then((response) => dispatch(loginActions.loginSubmit({ "login" : response.data.email, "password" : password , setErrorMessage })))
                .catch(error => console.log(error))
            )
            : setErrorMessage("As senhas não são iguais. Tente novamente."))
        : setErrorMessage("A senha deve conter 4 ou mais caracteres.")
    }

    return (
        <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor} brandContrastColor={brandContrastColor} alertColor={alertColor}>
            <Header>
                <img src={logoPath} alt="Logotipo"></img>
            </Header>
            <h2>Redefinição de senha</h2>
            <h4>
                Para redefinir sua senha, basta completar<br/>
                os campos abaixo e "Redefinir senha".
            </h4>
            <h5>{errorMessage}</h5>
            <div className="input-container">
                <input type="password" placeholder="Nova senha" onChange={event => setPassword(event.target.value)}/>
                <i className="fas fa-envelope"></i>
            </div>
            <div className="input-container">
                <input type="password" placeholder="Confirme sua nova senha" onChange={event => setConfirmPassword(event.target.value)}/>
                <i className="fas fa-lock"></i>
            </div>
            <button className="submit-button" onClick={handleSubmit}>Redefinir senha</button>
        </Container>
    )
}

export default NewPassword