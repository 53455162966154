import axios from "axios";

const api = axios.create({
  //baseURL: "http://localhost:5000/api/",
  baseURL: "https://api.moodboardstudio.com.br/api/",
});

api.interceptors.request.use(async (config) => {
  let isLoggedIn = JSON.parse(localStorage.getItem("persist:appcria"));
  if (isLoggedIn) isLoggedIn = JSON.parse(isLoggedIn.loginReducer).isLoggedIn;

  if (isLoggedIn) {
    let token = JSON.parse(localStorage.getItem("persist:appcria"));
    token = JSON.parse(token.loginReducer).token;

    config.headers["x-access-token"] = token;
  }
  return config;
});

export default api;
