import styled from 'styled-components'

export const ContainerRooms = styled.div`
display: flex;
width: 40%;
height: 200px;
justify-content: center;
align-items: center;
text-align: center;
margin-top: 20px;
padding: 30px;
border-radius: 8px;
background-color: ${props => props.backgroundColor};
box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
transition: 0.2s;

.text {
  font-size: 20px;
  color: ${props => props.fontColor};
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  transition: 0.2s;
}

@media only screen and (min-width: 1229px) {
  width: 38%;

  .text{
    font-size: 20px;
  }
}

@media only screen and (min-width: 1919px) {
  width: 38%;
  height: 250px;

  .text{
    font-size: 25px;
  }
}

@media only screen and (max-width: 700px) {
  width: 60%;
  margin-left: 20%;

  .text{
    font-size: 15px;
  }
}
`

export const Container = styled.div`
  display: flex;
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  margin-left: 12px;
  height: calc(${props => props.innerHeight}px - 88px);
  align-content: start;
  height: auto;
  flex-flow: wrap;
  margin: 0px;
  padding: 24px;
  justify-content: center;
  overflow-y: auto;

  @media only screen and (max-width: 480px) {
    overflow-y: hidden;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 10px !important;
    width: 100% !important;

    h3 {
      display: none;
    }

    div.room-options {
    display: flex;
    justify-content: center;
    height: calc(${props => props.innerHeight}px - 88px);
  }

    div.room-options div.new-room {
      width: 22vw !important;
      margin: .5px .6rem 1.4rem .6rem !important;
      width: 280px !important;
      height: 224px !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    div.room-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: calc(${props => props.innerHeight}px - 88px);
    }
  }

  h3 {
    display: none;
  }

  div.room-options {
    display: flex;
    justify-content: center;
    height: calc(${props => props.innerHeight}px - 88px);
  }

  div.room-options div.new-room {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 88px;
    width: 90vw;
    border-radius: 8px;
    background-color: ${props => props.backgroundColor};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    margin: 1.4rem .2rem 1.4rem .2rem;
    cursor: pointer;
  }

  div.room-options div.new-room i {
    font-size: 22px;
    margin-right: 8px;
    opacity: .8;
    color: #479a73;
  }

  div.room-options div.new-room h2 {
    font-size: 16px;
    opacity: .5;
  }
 
  .active {
    z-index: 4;
  }

`

export const Filters = styled.div`
display: flex; 
align-items: center;
padding: 12px 24px;
justify-content: center;

form {
  display: contents;
  flex-direction: column;
}

form input, form div {
  margin:0px 12px 0px 8px;
  border-radius: 15px;
  cursor: pointer;
}

form input {  
  padding: 2px 12px;
  border: solid 1px lightgray;
  max-width: 148px;
}

form .Dropdown-control {
  padding: 4px 24px 4px 10px;  
  width: 168px;
}

form .Dropdown-control .Dropdown-arrow {
  top: 10px;
}

button {
  /* right: 0px; */
  /* position: absolute; */
  margin-right: 24px;
  padding: 8px 24px;
  border-radius: 15px;
  border: none;
  box-shadow: 1px 1px 2px 1px #5c5c5c70;
  background-color: rgb(50, 205, 50);
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-left: 18px;
}

button i {
  margin-right: 8px;
  color: white;
}

.btn-new-order:hover {
  opacity: .7;
}

`