import * as types from '../types'

export function getAllMaterialsReduced(payload) {
    return {
        type: types.GET_ALL_MATERIALS_REDUCED,
        payload,
    }
}

export function getAllMaterialsReducedSuccess(payload) {
    return {
        type: types.GET_ALL_MATERIALS_REDUCED_SUCCESS,
        payload
    }
}