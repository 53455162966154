import styled from 'styled-components'

export const Container = styled.div`
/*display: flex;
flow-flex: row wrap;
justify-content: space-around;
align-items: flex-start;
align-content: flex-start;*/
gap: 12px;
/* width: 100%;
height: 100%; 
padding: 24px;*/
background-color: ${props => props.backgroundColor};

h1 {
  margin: 12px;
  color: ${props => props.fontColor};
}

label {
  margin-top: 10px;  
  margin-bottom: 4px;
  margin-left: 4px;
  font-family: 'Montserrat', sans-serif;
  color: ${props => props.fontColor};
  font-weight: bold;
}

input {
  padding: 4px 8px;
  border-radius: 15px;  
  border: 1px #bebebe solid;
}

.dados-contrato {
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 20%);
  /* margin-top: 18px; */
  border-radius: 15px;
  padding: 32px;
  /*max-width: 60vw;*/
  height: 60vh;
}

.title-dados-cadastrais {
  margin-bottom: 22px;
  margin-left: 4px;
}

.botoes-sessao-dados-cadastrais {
  display: inline-block;
  color: ${props => props.fontColor};
  border: none;
  box-shadow: 0 0.2px 4px rgb(0 0 0 / 20%);
  border-radius: 15px;
  margin-bottom: 14px;
}

.botao-sessao {
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;  
  background-color: transparent;
  font-weight: 500;
}

.sessao-selecionada-dados-cadastrais {
  color: ${props => props.backgroundColor};
  background-color: ${props => props.brandColor};
}


.botao-dados-cadastrais {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-left: 20px;
  padding-right: 12px;
}

.botao-endereco {
  padding-left: 14px;
  padding-right: 14px;
}

.botao-cores {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-left: 12px;
  padding-right: 20px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.formControl {
  margin: spacing(1);
  minWidth: 120;
}

`

export const ButtonGroup  = styled.div`

`

export const Button  = styled.div`

`