import styled from 'styled-components'

export const AlertMessage = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    height: ${props => props.innerHeight}px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-blend-mode: overlay;
    background-size: auto 100% !important;
    font-family: 'Montserrat', sans-serif;
    overflow-y: auto;
    background: white;

    button.backbutton {
        background: transparent;
        width: 89px;
        font-size: 14px;
        position: absolute;
        left: 5%;
        color: #9b9493d4;
        border: 0;
        top: 45px;
    }

    .elementor-video {
        border: 11px solid #fff;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .inputemail {
        background-color: #ffffff;
        border-color: black !important;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid;
        padding: .5rem 1rem;
        width: 100%;
    }

    .botaosolicitar {
        margin-top: 10px;
        border-radius: 0;
        border: 0;
        padding: .5rem 1rem;
        color: white;
        background: black;
    }

    .container {
        display: flex;
        width: 60%;
    }

    .MuiAlert-message .snackbar-alert-message {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .mobile-birthdate-input {
        appearance: none;
        background-image: url(../../../assets/icons/calendar-icon-124x120.png);
        background-repeat: no-repeat;
        background-position: right 0.9rem center;
        background-size: 1.2rem;
    }

    .birthdate-label {
        position: absolute;
        opacity: .6;
        top: 32%;
        left: 4%;
        font-weight: 500;
        font-size: 16px;
        pointer-events: none;
        color: black;
    }

    @media (max-width: 1020px) {
        .grid-container {
            grid-template-rows: 36% 64%;;
            /* grid-template-columns: auto auto; */
            grid-template-columns: none !important;
        }

        .grid-container .grid-right-data {
            padding: 0px 0px 0px 0px;
        }

        .grid-container .right-data-header-title {
            font-size: 16px;
        }

        .grid-container .right-data-header-default {
            font-size: 14px;
            line-height: 1.3em;
            /* padding: 0%; */
            padding: 0px 4% 0 4%;

        }

        .grid-container .right-data-content p {
            font-size: 12px;
            text-align: center;
        }

        .grid-container .inputs-wrapper {
            padding: 0;
            margin-top: 0;
        }

        .grid-container .right-data-footer,
        .grid-container .right-data-content p,
        .grid-container .inputs-wrapper {
            padding: 0% 10% 0% 10%;
        }

        .grid-container .right-data-footer {
            margin-bottom: 12px;
        }
    }

    @media (min-width: 1020px) {
        .grid-container {
            position: fixed;
        }

        .grid-right {
            overflow: scroll;
        }
    }

    /* @media screen and (max-width: 1366) {
        .grid-container {
            grid-template-rows: 3fr 7fr;
        }
    } */

    @media (max-width:1366px){
        .container {
            display: flex;
            width: 80%;
        }
    }

    @media (max-width:800px){
        .container {
            width: 100% !important;
            display: block;
        }

        .lado1 {
            padding: 20px !important;
            width: 100% !important;
        }

        .lado2{
            display: none;
        }
    }

    .titulo {
        font-weight: initial;
        font-size: 35px;
        margin-top: 10px;
    }

    .descricao1, .descricao2 {
        font-size: 1rem;
        margin: 20px 0;
        font-weight: initial;
    }

    .lado1 {
        padding: 10px;
        width: 40%;
    }

    .elementor-video {
        width: 100%;
    }

    .descricao1{
        color: #9E8E75
    }

    .descricao2{
        color: #5D5D5D
    }

    .lado2 {
        width: 80%;
    }

    @media (max-height:600px){
        display: block;
        overflow: scroll;

        .container{
            margin-top:75px;
        }

        /* .input-container{
            margin-left: 10%;
        } */
    }

    @media (max-width:700px){
        .next-button{
            width: auto !important;
        }
    }

    .descricao p {
        color: #b80000;
        margin: 10px 0 -10px 0;
    }

    .titulo-1{
        font-size:20px;
        font-weight:500;
        margin-top: 10px;
    }

    .titulo-2{
        font-size:30px;
        font-weight:700;
    }

    .titulo-3{
        font-size:20px;
        color: #9E8E75
    }

    .descricao{
        text-align: center;
        font-size:14px;
        margin:20px 0 30px 0;
    }

    .input-container{
        width:100%;
        position: relative;
    }

    .input-container input, .content input, .input-container select{
        background-color: rgb(237, 237, 237);
        width: 100%;
        margin: 5px 0 5px 0;
        height: 38px !important;
        font-weight: 500;
        font-size: 15px;
        border: 0;
        padding: 0.5rem 1rem;
        border-radius: 0 !important;
        transition: 200ms;
    }

    .input-container.error > input, .input-container.error > select {
        outline: 1px solid #b21212ba !important;
        box-shadow: 0px 0px 8px 1px #b21212ba !important;
    }

    .input-container select {
        padding: 5px 14px;
        font-size: 16px;
        font-family: inherit;
        font-weight: 600;
        appearance: none;
        background-image: url(../../../assets/icons/arrowIcon.png);
        background-repeat: no-repeat;
        background-position: right 1.3rem center;
        background-size: 0.5rem;
    }

    .right-data-footer {
        display: flex;
        width: 100%;
        gap: 12px;
        padding: 0% 20% 0% 20%;
    }

    .right-data-footer button {
        width: 100%; 
        font-weight: 600;
        border: 0;
        background-color: #404040;
        color: white;
        padding: 10px;
        cursor: pointer;
        transition: 0.2s;
    }

    .right-data-footer .back-button {
        background-color: rgb(207, 207, 207);
    }

    .right-data-footer button:hover {
        background-color:#606060;
    }

    .right-data-footer .back-button:hover {
        background-color: #999999c9;
    }

    .input-number::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .grid-container {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 8fr 10fr;
    }

    .grid-left {
        background-image: url(https://collection.com.br/wp-content/uploads/2022/02/fff.jpg);
        background-position: center center;
        background-size: cover;
        position: relative;
    }

    .grid-left-background {
        background-color: #AAAAAA;
        opacity: 0.68;
        height: 100%;
        display: flex;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
    }

    .grid-left-content {
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        position: relative;
    }

    .grid-left-content img {
        width: 52px;
        height: 52px;
        height: auto;
        max-width: 100%;
        border: none;
        border-radius: 0;
        vertical-align: middle;
        display: inline-block;
        box-shadow: none;
        filter: brightness( 200% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );
    }

    .grid-right-data {
        height: 100%;
        padding: 80px;
        display: flex;
        gap: 4px;
        justify-content: center;
        flex-direction: column;
    }

    .right-data-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    .right-data-header-title {
        color: #2F2F2F;
        height: fit-content;
        line-height: 1.2em;
        text-align: center;
        font-weight: 700;
        font-size: 1.5rem;
    }

    .right-data-header-default {
        color: #2F2F2F;
        font-size: 20px;
        line-height: 1.2em;
        font-weight: 500;
        padding: 0% 10% 0% 10%;
    }

    .right-data-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 24px;
        height: 64%;
    }

    .input-icon {
        position: absolute;
        right: 8px;
        top: calc(50% - 0.5em);
    }

    .input-icon.password:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    .right-data-content p {
        color: #767676;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
    }

    .inputs-wrapper {
        width: 100%;
        justify-content: center;
        display: flex;
        flex: 1 1 auto;
        position: relative;
        flex-direction: column;
        padding: 0% 20% 0% 20%;
        align-items: center;
    }

    .input-container > input:focus, .input-container > select:focus {
        outline: 1px solid #2F2F2F;
        box-shadow: 0px 0px 3px 1px #2f2f2f;
    }

    .submit-button, .next-button{
        width: 15%;
        font-weight: 600;
        border:0;
        background-color:#404040;
        color: white;
        padding:10px;
        cursor:pointer;
    }

    .next-button{
        margin-bottom: 10px;
    }

    .submit-button{
        width: 100% !important;
    }

    .criarconta{
        width:15%;
    }

    input.back-button {
        background: transparent;
        border: 0;
        margin: 10px;
        cursor: pointer;
        text-align: center;
    }

    input.back-button:hover {
        color:#656565;
        transition: 0.2s;
    }

    .radio-container{
        background-color: white;
        margin: 10px;
        width: 15%;
        padding: 10px;
    }

    .radio-container .radio-title{
        margin-bottom:10px;
    }

    .radio-container .radio-title label{
        font-size: 15px;
        font-weight: 500;
    }

    @media only screen and (max-width: 1700px) {
        .submit-button{
            width: 20%;
        }

        .input-container{
            width:100%
        }
    }

    .submit-button:hover{
        transition:0.2s;
        background-color:#606060;
    }

    .esqueci{
        font-weight:bold;
        font-size:12px;
        margin-top:20px;
        cursor:pointer;
    }

    .cadastro{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(133, 142, 125, 0.5), rgba(133, 142, 125, 0.5)),url(https://i.ibb.co/98Vch40/064a8d4aba61357dec5b7914f3a3e4bd.jpg);
        width: 40%;
        background-size: 100% auto;
        padding: 30px 0;
        background-position-y: 65%;
        margin-top: 30px;
        transform: scaleX(-1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 1367px) {
        .cadastro{
            width: 50%;
            padding: 15px 0;
        }
    }

    .cadastro .content{
        transform: scaleX(-1);
        color: white
    }

    .cadastro .content .frase-1{
        font-size:18px;
    }

    .cadastro .content .frase-2{
        margin: 5px 0;
        opacity:0.9
    }

    .cadastro .content button{
        width:45%;
        padding: 10px;
        background-color: #858E7D;
        color:white;
        border:0;
        margin-top: 10px
    }

    .cadastro .content button:hover{
        background-color: #9ca396;
        transition:0.2s
    }

    .buttons-div{
        border-radius: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        background-color: #484848 !important;
    }
    
    .dMazrD div.content input:focus-within, .dMazrD div.content input:hover{
        border: 1px solid rgba(20,20,20,0.2) !important;
    }

    .dMazrD div.content h3{
        color: #525252;
    }

    .error{
        color: #c53434;
    }

    .spin{
        margin: 8px;
    }

    @media only screen and (max-width: 1124px) {
        .submit-button{
            width: 40%;
        }

        /* .input-container{
            width:40%
        } */

        .cadastro {
            width: 50%;
            padding: 15px 30px;
        }
    }

    @media only screen and (max-width: 600px) {
        .submit-button{
            width: 80%;
        }

        /* .input-container{
            width:80%
        } */

        .cadastro {
            width: 80%;
            padding: 15px 30px;
        }

        .cadastro .content button{
            width: 80%
        }

        .cadastro .content .frase-1{
            font-size: 16px
        }

        .creditos{
            font-size:10px
        }
    }

    .creditos{
        color: #6C6C6C;
        position: absolute;
        bottom:10px;
    }

    div.socialLogin-container {
        display: none;
    }

    a{
        cursor: pointer;
    }

    .radio-container input {
        cursor: pointer;
    }
`

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  height: 38px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.7);
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  img {
    height: 24px;
  }
`

export const TermsModal = styled.div`
position: absolute;
width: 88vw;
height: auto;
background-color: ${props => props.backgroundColor};
border-radius: 16px;
box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
flex-wrap: wrap;
flex: 1;
align-items: center;
align-self: center;
justify-content: start;
justify-self: center;
flex-direction: column;
opacity: 1;
z-index: 4;
padding: 16px;

@media only screen and (min-width: 768px) {
  width: 380px;
  z-index: 5;

  h3,
  p {
    width: 348px !important;
  }
}

h3 {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.brandColor};
  width: calc(88vw - 32px);
  flex: 1;
}

p {
  margin-bottom: 12px;
  font-size: 13px;
  color: ${props => props.fontColor};
  width: calc(88vw - 32px);
  height: 300px;
  overflow-y: auto;
}

i {
  position: absolute;
  right: 16px;
  font-size: 13px;
  color: ${props => props.fontColor};
}

i:hover {
  opacity: .8;
  cursor: pointer;
}
`