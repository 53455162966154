import { combineReducers } from 'redux'

import loginReducer from './login/reducer'
import contractReducer from './contract/reducer'
import userRoomsReducer from './userRooms/reducer'
import ordersReducer from './orders/reducer'
import userReducer from './user/reducer'
import roomsReducer from './rooms/reducer'
import materialsReducer from './materials/reducer'

export default combineReducers({
    loginReducer,
    contractReducer,
    userRoomsReducer,
    ordersReducer,
    userReducer,
    roomsReducer,
    materialsReducer
})