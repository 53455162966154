import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: .8;
  background-color: ${props => props.fontColor};
  z-index: 3;

  @media only screen and (min-width: 1024px) {
    left: 0;
    z-index: 4;
  }
`