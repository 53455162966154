import styled from 'styled-components'

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 12px;
  height: calc(${props => props.innerHeight}px - 78px);
  overflow-y: auto;
  align-content: start;

  @media only screen and (min-width: 1024px) {
    margin-top: 80px !important;
    height: auto !important;

    h3 {
      display: none;
    }

    div.room-options div.new-room {
      width: 22vw !important;
      margin: .5px .6rem 1.4rem .6rem !important;
      height: 16.5vw !important;
    }
  }

  h3 {
    font-size: 15px;
    margin: 16px;
    justify-self: start;
    text-align: start;
    width: 88vw;
    font-weight: 500;
    color: ${props => props.fontColor};
    margin-top: 60px;
  }

  div.room-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: calc(${props => props.innerHeight}px - 88px);
  }

  div.room-options div.new-room {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 88px;
    width: 90vw;
    border-radius: 8px;
    background-color: ${props => props.backgroundColor};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    margin: 1.4rem .2rem 1.4rem .2rem;
    cursor: pointer;
  }

  div.room-options div.new-room i {
    font-size: 22px;
    margin-right: 8px;
    opacity: .8;
    color: #479a73;
  }

  div.room-options div.new-room h2 {
    font-size: 16px;
    opacity: .5;
  }
 
  .active {
    z-index: 4;
  }
`