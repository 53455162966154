import { all } from 'redux-saga/effects'

import login from './login/sagas'
import contract from './contract/sagas'
import userRooms from './userRooms/sagas'
import orders from './orders/sagas'
import user from './user/sagas'
import materials from './materials/sagas'


// checar nome para não ficar confuso re: userRooms
import rooms from "./rooms/sagas"

export default function* rootSaga() {
    return yield all([login, contract, userRooms, orders, user, rooms, materials])
}