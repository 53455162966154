import styled from "styled-components";

export const NAvBar = styled.div`
  display: flex;
  height: 54px;

  gap: 50px;
  width: 100%;
  justify-content: center;
  .selecionado {
    color: #9e8e75;
    border-bottom: 2px solid #c4c4c4;
  }
  .backbutton {
    background: transparent;
    width: 89px;
    font-size: 14px;
    top: 22px;
    position: absolute;
    left: 1%;
    color: #9b9493d4;
    border: 0;
  }

  .backbutton: hover {
    opacity: .8;
  }
`;
