import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${props => props.backgroundColor};

  }
  body, input, button {
    font-family: 'Montserrat',sans-serif;
    font-size: 13px;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }

  .crisp-client #crisp-chatbox a {
    margin-right: 2.5rem!important;
    bottom: 1rem!important;    
  }

  .crisp-client {
    display: none;
  }

  /** SCROLL BAR **/

  @media only screen and (max-width: 1023px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #BABABA;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8a8a8a;
  }

  .MuiCircularProgress-colorPrimary {
    color: #999999;
  }
`

export default GlobalStyle