import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 67.5vw;
  width: 90vw;
  border-radius: 8px;
  background-color: transparent;
  margin: 0 .2rem 1.4rem .2rem;
  cursor: pointer;

  transition:0.1s;

  :hover{
    opacity:0.8 !important;
    transition:0.2s;
  }

  .block-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, 40%, 1px);
    z-index: 2;
    width: 100%;
    text-align: center;
    font-weight: 500;
    background-color: white;
    padding: 4px;
  }

  @media only screen and (min-width: 1024px) {
    width: 21.5vw;
    height: 16.125vw;
    margin: 0 .5vw 1.4rem .4vw;

    div.active {
      z-index: 5 !important;
    }

    img {
      width: 21.5vw !important;
      height: 16.125vw !important;
    }
  }

  img {
    position: relative;
    height: 67.5vw;
    width: 90vw;
    object-fit: cover;
  }

  h4 {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #e3e3e3;
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }

  .active {
    z-index: 4;
  }
`