import styled from "styled-components";

export const ListItemContainer = styled.div`
  max-height: auto;
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
  overflow-x: scroll;
  gap: 20px;
  margin-bottom: 4px;
  margin-top: 24px;
`;

export const ListContainer = styled.div`
  height: calc(100vh - 182px);
  overflow-y: auto;
  padding-left: 24px;
`;

export const ThumbList = styled.ul`
  display: flex;
  margin-right: 24px;
  gap: 20px;
`;
export const ImgContainer = styled.div`
  min-height: 300px;
  height: auto;
  width: 310px;
  background-color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
export const Img = styled.img`
  width: 310px;
  height: 235px;
  object-fit: cover;
`;

export const InfoContainer = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-between;
  padding: 0px 8px;
  align-items: center;
  .room-title {
    margin-top: auto;
    margin-left: 4px;
  }
  .room-inativo {
    color: red;
    margin-bottom: 8px;
  }
  .icon {
    margin-top: auto;
    margin-right: 4px;
  }
`;

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-left: 24px;
  font-size: 1.3rem;

  .checkbox-title {
    padding: 0;
  }
`;

export const Buttons = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 16px;
  max-height: 16px;
  .trash-cam {
    margin-right: 8px !important;
  }
  i {
    margin: 0 4px !important ;
  }
  i:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const SelectionBox = styled.div`
  position: relative;

  .checkbox {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;
