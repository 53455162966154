import React from "react";
import { useState, useEffect, useRef } from "react";

import TextField from "@material-ui/core/TextField";

import { DataGrid } from "@material-ui/data-grid";

import { Button, NavTable } from "./styles";

import FormCamera from "./FormsCamera/index";
import { nominalTypeHack } from "prop-types";

const DEAFAUL_CAMERA = {
  sequence: "",
  rotation: "",
  position: "",
  FOV: "",
  mobilePosition: "",
  mobileRotation: "",
  mobileFOV: "",
};
const Cameras = (props) => {
  const { setFormsCamera, setCameras, cameras, setRoom } = props;
  const [cadastro, setCadastro] = useState(false);
  const [camera, setCamera] = useState(DEAFAUL_CAMERA);
  const [rows, setRows] = useState([]);
  const columns = [
    { field: "sequence", headerName: "Sequência", width: 180 },
    { field: "position", headerName: "Posição", width: 130 },
    { field: "rotation", headerName: "Rotação", width: 130 },
    { field: "FOV", headerName: "FOV", width: 130 },
    { field: "mobilePosition", headerName: "Posição Mobile", width: 180 },
    { field: "mobileRotation", headerName: "Rotação Mobile", width: 180 },
    { field: "mobileFOV", headerName: "Mobile FOV", width: 160 },
    {
      field: "Editar",
      renderCell: (cellValues) => {
        return (
          <Button
            type="button"
            onClick={(event) => {
              setCamera(cellValues.row);
              setCadastro(true);
            }}
          >
            Editar
          </Button>
        );
      },
      width: 130,
    },
    {
      field: "Excluir",
      renderCell: (cellValues) => {
        return (
          <Button
            type="button"
            onClick={(event) => {
              setCameras(
                cameras.filter((item, index) => index != cellValues.row.id)
              );

              setRows(
                cameras.map((item, index) => {
                  return {
                    id: index,
                    sequence: item.sequence,
                    position: item.position,
                    rotation: item.rotation,
                    FOV: item.FOV,
                    mobilePosition: item.mobilePosition,
                    mobileRotation: item.mobileRotation,
                    mobileFOV: item.mobileFOV,
                  };
                })
              );
            }}
          >
            Excluir
          </Button>
        );
      },
      width: 130,
    },
  ];
  const getRows = () => {
    setRows(
      cameras.map((item, index) => {
        return {
          id: index,
          sequence: item.sequence,
          position: item.position,
          rotation: item.rotation,
          FOV: item.FOV,
          mobilePosition: item.mobilePosition,
          mobileRotation: item.mobileRotation,
          mobileFOV: item.mobileFOV,
        };
      })
    );
  };
  useEffect(getRows, [cameras]);

  return (
    <>
      {!cadastro && (
        <>
          <NavTable>
            <h2>Câmeras</h2>
            <Button
              onClick={() => {
                setCamera(DEAFAUL_CAMERA);
                setCadastro(true);
              }}
            >
              Nova Câmera
            </Button>
          </NavTable>
          <DataGrid rowCount={false} hideFooter hideFooterPagination rows={rows} columns={columns}></DataGrid>
        </>
      )}
      {cadastro && (
        <FormCamera
          setCamera={setCamera}
          setCadastro={setCadastro}
          setCameras={setCameras}
          setRoom={setRoom}
          value={camera}
          getRows={getRows}
          cameras={cameras}
        ></FormCamera>
      )}
    </>
  );
};

export default Cameras;
