import React, { useState, useEffect, useContext  } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { contractContext } from "../../../Contexts/contractContext";
import * as roomsActions from '../../../store/modules/rooms/actions'
import RoomThumb from './components/RoomThumb';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import GenericModal from "../../../components/GenericModal/"
import api from "../../../services/api"
import FormControl from '@material-ui/core/FormControl'
import SingleRoom from './components/SingleRoom';

// Styles
// atualmente os styles de tudo estão nesse styles principal - vou estudar um pouco mais sobre react-components para transferir tudo certinho para o styles do componente certo sem chance de causar erro. 
import {Container} from './styles'

const Rooms = (props) => {
 
  const rooms = useSelector(state => state.roomsReducer.rooms)

  const user = useSelector(state => state.loginReducer.user)

  const { currentContract, setCurrentContract } = useContext(contractContext)
  const [ data, setData ] = useState([])

  const [session, setSession] = useState('roomsList')
  const [selectedRoom, setSelectedRoom] = useState()

  const [contractModalState, setContractModalState] = useState(false)
  const [contractList, setContractList] = useState([])
  const [selectedContracts, setSelectedContracts] = useState([])
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const [selectAll, setSelectAll] = useState([]);

  const dispatch = useDispatch()

  const handleSessionClick = (session) => {
    setSession(session)
  } 

  const loadContracts = () => {
 
    api.get(`contract/reduced`).then(({ data }) => {

      if (selectedRoom) {
        const listHelper = data.contracts.map(c => {
          return {
            value: c._id,
            label: c.name,
            rooms: c.rooms,
            isChecked: c.rooms.includes(selectedRoom._id) 
          }
        }).sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
         //  a must be equal to b
          return 0;
        })
        
        setSelectedContracts(listHelper.filter((c) => c.isChecked))

        setContractList(listHelper)    
      } else {
        const listHelper = data.contracts.map(c => {
          return {
            value: c._id,
            label: c.name,
            rooms: c.rooms,
            isChecked: false 
          }
        }).sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
         //  a must be equal to b
          return 0;
        })

        setContractList(listHelper)
      }
        
    })    
  }

  useEffect(() => {
    loadContracts()    
  }, [selectedRoom])


  useEffect(() => {
    dispatch(roomsActions.getAllRooms(null))
  }, [])

  useEffect(() => {

    if (rooms.length > 0) {
      const roomsFachada = rooms.filter(room => room.type.description === "Fachada")
      const roomsBanheiro = rooms.filter(room => room.type.description === "Banheiro")
      const roomsQuarto = rooms.filter(room => room.type.description === "Quarto")
      const roomsCozinha = rooms.filter(room => room.type.description === "Cozinha")
      const roomsGourmet = rooms.filter(room => room.type.description === "Área Gourmet")
      const roomsLavanderia = rooms.filter(room => room.type.description === "Lavanderia")
      const roomsEscritorio = rooms.filter(room => room.type.description === "Escritório")
      const roomsSala = rooms.filter(room => room.type.description === "Sala")
      const roomsApartamento = rooms.filter(room => room.type.description === "Apartamento")
      const roomsCloset = rooms.filter(room => room.type.description === "Closet")      

      let newData = [{
        "description": "Fachada",
        "rooms": roomsFachada
      },
      {
        "description": "Banheiro",
        "rooms": roomsBanheiro
      },
      {
        "description": "Quarto",
        "rooms": roomsQuarto
      },
      {
        "description": "Cozinha",
        "rooms": roomsCozinha
      },
      {
        "description": "Área Gourmet",
        "rooms": roomsGourmet
      },
      {
        "description": "Lavanderia",
        "rooms": roomsLavanderia
      },
      {
        "description": "Escritório",
        "rooms": roomsEscritorio
      },
      {
        "description": "Sala",
        "rooms": roomsSala
      },
      {
        "description": "Apartamento",
        "rooms": roomsApartamento
      },
      {
        "description": "Closet",
        "rooms": roomsCloset
      },
    ]

    const selecteAllHelper = [{
    }]

    newData.map(c => {
      selecteAllHelper.push({
        category: c.description,
        select: false,
        modified: false
      })
    })

    setSelectAll(selecteAllHelper)
    setData(newData)
  }
  }, [rooms])

  const handleEditClick = (room) => {
    setSelectedRoom(room)
    setSession('singleRoom')   
  }

  const handleSelectedContracts = (contractId, checked) => {
    let cHelper = selectedContracts

    if (checked) {
      cHelper.push(contractId)
    } else {
      cHelper.splice(cHelper.indexOf(contractId), 1)
    }
    
    setSelectedContracts(cHelper)
  }


  const handleCheckContract = (target) => {
    let cHelper = [...new Set(contractList)]

    cHelper.forEach(contract => {
      if (contract.value == target.value) {
        handleSelectedContracts(contract.value, target.checked)
        return contract.isChecked = target.checked
      }
    })

    setContractList(cHelper)
  }

  const handleSelectAll = (checked, category) => {

    
    let selectAllHelper = [...new Set(selectAll)]

    let objIndex = selectAllHelper.findIndex(c => c.category == category)
    selectAllHelper[objIndex].select = checked
    selectAllHelper[objIndex].modified = true        

    setSelectAll(selectAllHelper)

  }

  return (
    <>
    
      <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>
        <div className="rooms-header">
        <h1>Ambientes</h1>

        {
        session === 'roomsList' && user.type === "admin" && <button className="btn-novo-ambiente" onClick={() => handleSessionClick('singleRoom')}>Novo Ambiente</button>
        ||
        session === 'singleRoom' && user.type === "admin" && <button className="btn-novo-ambiente" onClick={() => {
          setContractModalState(true)
        }}>Vincular a contrato</button>
        }

        </div>

        <div className="rooms">
          {
            session === 'singleRoom' ? <SingleRoom room={selectedRoom}/>
            :
            
            session === 'roomsList' && data && data.map((category) => (
              <>
              <div className="category-title">
                <h2>{category.description}</h2>
                <div className="selecionar-todos-checkbox">              
                  <input type="checkbox" onChange={e => handleSelectAll(e.target.checked, category.description)} />
                  Selecionar todos
                </div>
              </div>
              <div className="carousel-wrapper">
              <CarouselProvider
                naturalSlideWidth={window.innerWidth*0.18}
                naturalSlideHeight={300}
                totalSlides={category.rooms.length}
                visibleSlides={4}
                step={3}
                isIntrinsicHeight
              >
                <Slider className="thumb-slider" classNameAnimation="slider">
                {category.rooms && category.rooms.map((room, index) => (
                    <Slide key={index} index={index}>                                          
                      <RoomThumb 
                        roomId={room._id}
                        title={room.title}
                        imgSrc={room.thumbPath} 
                        changeSession={setSession} 
                        //changeRoom={setRoom} ??? 
                        category={category.description}
                        room={room}
                        editClick={() => handleEditClick(room) }
                        checkAll={selectAll}
                      />
                    </Slide>
                  ))}
                </Slider>
                <>
                  {/*
                  <ButtonBack className="button-back"><i className="fas fa-arrow-left"></i></ButtonBack>
                  <ButtonNext className="button-next"><i className="fas fa-arrow-right"></i></ButtonNext>
                  */}
                </>
  
              </CarouselProvider>
              </div>
              </>
            ))}
          
          
      <GenericModal
        display={contractModalState}
        onCloseClick={() => setContractModalState(false)}
        arrowYPosition={'28vh'}
        arrowXPosition={'6vw'}
        mainContent={
          <>
            {hasSubmitted ? 
              <>
                <h3>Vinculado com sucesso</h3>
                <FormControl className="contrato-selector">
                <div className="bind-contract-container">
                <p>Contratos vinculados com sucesso.</p>
                <button className="btn-vincular-contratos" onClick={() => {
                  setContractModalState(false)
                  setHasSubmitted(false)
                }}>Fechar</button>
                </div>
                </FormControl>
              </> : 
              <>
              <h3>Vincular a Contratos</h3>
                <FormControl className="contrato-selector">
                <label>Escolha os contratos a qual esse ambiente deve ser vinculado.</label>
                <div className="bind-contract-container">
                <div className="rooms">
                  {contractList.map((contract) => {

                    return <label>
                      <input type="checkbox" key={contract._id} value={contract.value} checked={contract.isChecked} onChange={t => handleCheckContract(t.target)}></input>
                      {contract.label}
                    </label>
                  })}
             
                </div>
                <button className="btn-vincular-contratos" onClick={() => {
                  api.put(`contract/room/${selectedRoom._id}`, selectedContracts).then((data) => {
                    setHasSubmitted(true)
                })
                  
                }}>Vincular a Contratos</button>
                </div>
                </FormControl>
              </>}
            
          </>
        }
        >

      </GenericModal>

      </div>

      </Container>
     
    </>
  )
}

export default Rooms
