import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from './styles'
import { contractContext } from '../../../../../Contexts/contractContext'
import * as roomsActions from "../../../../../store/modules/rooms/actions"


const RoomThumb = (props) => {

    const rooms = useSelector(state => state.roomsReducer.rooms)
    const user = useSelector(state => state.loginReducer.user)

    const { currentContract, setCurrentContract } = useContext(contractContext)
    const [inContract, setInContract] = useState(false)

    const handleInContractList = (add) => {
        let helper = currentContract.rooms        

        if (add){
            if (currentContract.rooms.indexOf(props.roomId) == -1)
                helper.push(props.roomId)
        }            
        else
            helper.splice(currentContract.rooms.indexOf(props.roomId),1)
            
        //setCurrentContract({rooms: helper})

        currentContract.rooms = helper
    }

    const handleClick = (event) => {
        setInContract(!inContract)
        handleInContractList(!inContract)
    }

    useEffect(() => {
        if (currentContract.rooms.includes(props.roomId)) {
            setInContract(true)
        } else {
            setInContract(false)
        }
    }, [rooms])

    useEffect(() => {
        
        props.checkAll.forEach(e => {
            if (e.category == props.category && e.modified){
                setInContract(e.select)
                handleInContractList(e.select)
            }
        })

    }, [props.checkAll])

    return (
        <Container>
            <label className="checkbox">
                <input type="checkbox" checked={inContract} onChange={handleClick}></input>
                <span className="checkmark"></span>
            </label>
            {user.type === "admin" ?
            <div className="editar" onClick={(e) => props.editClick()}>
                Editar
            </div>
            : ""}
            <h4>{props.title}</h4>
            <img data-id={props.roomId}  src={props.imgSrc} alt=""/>
            <div className="card-shadow"></div>
            
            
        </Container>
    )
}


export default RoomThumb