import styled from 'styled-components'

export const ContainerTop = styled.div`
  position: fixed;
  top: 0;
  height: 40px;
  width: 100vw;
  opacity: .8;
  background-color: ${props => props.fontColor};
  z-index: 3;

  @media only screen and (min-width: 1024px) {
    z-index: 4;
  }
`

export const ContainerBottom = styled.div`
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 100vw;
  opacity: .8;
  background-color: ${props => props.fontColor};
  z-index: 3;

  @media only screen and (min-width: 1024px) {
    z-index: 4;
    width: 300px;
    top: 40px;
    bottom: auto;
  }
`