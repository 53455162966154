import * as types from '../types'

export function socialLoginSubmit(payload) {
    return {
        type: types.SOCIAL_LOGIN_SUBMIT,
        payload,
    }
}

export function loginSubmit(payload) {
    return {
        type: types.LOGIN_SUBMIT,
        payload,
    }
}

export function loginSuccess(payload) {
    return {
        type: types.LOGIN_SUCCESS,
        payload,
    }
}

export function loginFailure(payload) {
    return {
        type: types.LOGIN_FAILURE,
        payload,
    }
}

export function setOnboarding(payload) {
    return {
        type: types.SET_ONBOARDING,
        payload,
    }
}

export function updateUserPhone(payload) {
    return {
        type: types.UPDATE_USER_PHONE,
        payload,
    }
}