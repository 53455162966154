import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};    
  z-index: 4;
  padding: 16px;
  margin: auto;
  border-radius: ${props => props.borderRadius};    
  
  div.loader{
    place-self: center;
    border: 5px solid ${props => props.brandContrastColor};
    border-top: 5px solid ${props => props.brandColor}; 
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  @media only screen and (min-width: 768px) {
    
  }    

`