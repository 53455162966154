import styled from 'styled-components'

export const Menu = styled.div`
  position: absolute;
  top: 40px;
  right: 15px;

  .icone {
    font-size: 22px;
    padding: 7px 17px;
    background: white;
    box-shadow: 2px 2px 5px 2px #0000001c;
    cursor:pointer;
    color: #8f8780;
  }

  .ajuste{
    padding: 7px 13px !important;
  }

  .icone:hover{
    box-shadow: 2px 2px 5px 2px #0000002b;
  }

  .content {
    position: absolute;
    right: 50px;
    cursor:pointer;
    animation-name: anim-open-menu !important;
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
  }

  @keyframes anim-open-menu{
    from {top: -50px}
    to {top: 0px}
  }

  .content button {
    box-shadow: 2px 2px 5px 1px #00000017;
    color: #858585;
    font-weight: 500;
    border: 0;
    font-size: 14px;
    width: 183px;
    background: white;
    padding: 13px 15px;
  }

  .content button:last-child{
    color: #be6464;
  }
`

export const Loading = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;


.titulo-1{
    font-size:20px;
    font-weight:500;
    margin-top: 10px;
}

.titulo-2{
    font-size:30px;
    font-weight:700;
}

.titulo-3{
    font-size:20px;
    color: #9E8E75;
    margin-bottom: 10px
}

.MuiCircularProgress-colorPrimary {
  color: #999999;
}
`

export const Creditos = styled.div`
    color: #6C6C6C;
    position: absolute;
    bottom:10px;
    width: 100%;
    text-align: center;

    @media (max-width:1024px){
      font-size:10px !important;
    }
`

export const OfflineDiv = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  background-color: ${props => props.backgroundColor};
  position: absolute;
  bottom: 50px;
  align-self: center;
  z-index: 8;
  font-size: 11px;
  font-weight: 500;
  padding-left: 16px;
  border-radius: 10px;
  opacity: .9;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  i.fa-wifi {
    position: relative;
    margin-left: 12px;
    font-size: 12px;
  }

  i.fa-slash {
    position: relative;
    right: 15px;
    z-index: 9;
    font-size: 12px;
  }
`
