import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const OrderByModal = (props) => {
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    return (
        <>
        {!props.fullScreen && (
        <Container display={props.display} fitContent={props.fitContent} backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>
            <div className="order-title-bar">
                <h3>{props.title}</h3>
                <i className="fas fa-times" onClick={props.onCloseClick}></i>
            </div>
            <div className="orderby-main-content">
                {props.mainContent}
            </div>
        </Container>
        )}
        </>
    )
}

export default OrderByModal