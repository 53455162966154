import styled from 'styled-components'

export const Loading = styled.div`
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-colorPrimary {
      color: #999999;
    }
`

export const Container = styled.div`
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 8px;
  overflow-x: clip;
  background: rgba(228, 225, 225, 0.75);

  @media (max-width: 1000px) {
    padding-bottom: 0px !important;
  }

  .MuiCircularProgress-colorPrimary {
    color: #999999;
  }

  .loadingClass {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }

  .rightSide {
    overflow-x: hidden !important;
  }

  .topClass {
    overflow-x: hidden !important;
  }

  .class-Porcelanato {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/yFW0Q34/ca1083282d925a38244a1de9d9a67896.png);
  }

  .class-Pedras {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/Rbhm2PB/3f19ca984ab3913cb4d3eae20a5cf222.png);
  }

  .class-MDF {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/Rpt8qh8/6d919a39f8f616d2078e4c0428802bac.png);
  }

  .class-Pastilha {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/yFW0Q34/ca1083282d925a38244a1de9d9a67896.png);
  }

  .class-LaminadoeVinilico {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/G59Zbf0/images.jpg);
  }

  .class-Tinta {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/YD6Wxg8/f0b764d728a018937cd2e3641ffecc93.png);
  }

  .class-Tijolo {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/yQ3C2sB/aaaa.png);
  }

  .class-Carpete {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/0mnpyn1/ssss.png);
  }

  .class-PapeldeParede {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://i.ibb.co/XF4VKTR/dsdsdsa.png);
  }

  .class-Generico {
    background: linear-gradient(
        180deg,
        rgba(174, 174, 174, 0.48) 0%,
        rgba(221, 221, 221, 0.8) 63.54%
      ),
      url(https://d3j9qmbv5hjp0y.cloudfront.net/moodBoard-studio/collection-assets/ambientes/%C3%81reas%20Sociais%20e%20%C3%8Dntimas/Sala%20de%20jantar%20com%20canto%20alem%C3%A3o%20e%20mesa%20redonda-1655915660911.png);
  }

  .less {
    height: calc(100vh - 273px);
  }

  @media (min-width: 1900px) {
    .less {
      height: calc(100vh - 550px) !important;
    }
  }

  @media (max-width: 1200px) {
    .bannerClass {
      display: none !important;
    }
  }

  @media (max-width: 1000px) {
    .closeMenu,
    .closeItem {
      display: none !important;
    }

    .rightSide {
      width: 100% !important;
    }

    .leftSide {
      width: 100% !important;
      padding: 10px 20px 0 0 !important;
    }

    .filtrosClass {
      margin-top: -10px !important;
      position: absolute !important;
    }

    .backButton {
      display: block !important;
    }

    .buttonGroup {
      width: 90% !important;
      height: 100px !important;
      border-left: 0 !important;
      color: black !important;
    }

    .pesquisaClass > div > div {
      width: 88%;
    }

    .buttonsPage font {
      display: none !important;
    }
    .buttonsPage i {
      display: block !important;
    }

    .buttonsPage .first font {
      display: block !important;
    }

    .buttonsPage .first i {
      display: none !important;
    }
  }

  .buttonsPage i {
    display: none;
  }

  .backButton {
    z-index: 999;
    display: none;
    padding: 5px 15px 5px 0px;
    background: transparent;
    border: 0;
    margin: -2px 0px 0 10px;
    right: 20px;
    color: rgba(95, 94, 94, 1);
  }

  .filtrosClass {
    margin-top: 5px;
    position: absolute;
    right: 15px;
  }

  .filtrosClass button {
    padding: 8px 25px;
    color: white;
    border-radius: 0;
    background: #787878;
    height: auto;
  }

  .filtrosClass button:last-child {
    padding-right: 0;
    text-align: right;
    background: transparent;
    color: black;
    font-size: 15px;
    box-shadow: 0 0 0 transparent;
    padding-left: 10px;
  }

  .filtrosClass .selected {
    color: #b8ddff !important;
  }

  .noRecente {
    height: 40px;
  }

  .noRecenteMsg {
    display: none;
    color: gray;
    padding: 5px 10px 20px 5px;
    text-align: center;
    width: 88%;
  }

  @media (max-width: 1000px) {
    .noRecente {
      display: none;
    }

    .noRecenteMsg {
      display: block !important;
    }
  }

  h2.recenteTitle {
    margin-bottom: 2px;
    margin-top: -8px;
  }

  .navbar {
    margin-bottom: 20px;
  }

  .navbar h1,
  .groupClass h2:first-child {
    color: #9e8e75 !important;
    margin-top: 15px !important;
    font-size: 20px !important;
    font-weight: 100;
  }

  .pesquisaClass input {
    width: 100%;
  }

  @media (max-width: 1024px) {
    .pesquisaClass input {
      height: 45px !important;
    }

    .navbar h1,
    .groupClass h2:first-child {
      color: rgba(64, 64, 64, 1) !important;
      font-weight: 500 !important;
    }

    .navbar h1 {
      display: contents;
    }

    .filtrosClass > div {
      margin-right: -11% !important;
    }
  }

  .pesquisaClass > div > div {
    height: 40px;
  }

  .pesquisaClass * {
    border-radius: 0;
  }

  .buttonsPage {
    text-align: center;
    right: 0;
    position: absolute;
    bottom: -25px;
    z-index: 99999999;
    display: block;
    left: 20%;
  }

  .buttonsPage button {
    background: #9e8e75;
    color: white;
    border: 0;
    padding: 5px 15px;
  }

  .buttonsPage span {
    margin: 0 20px;
  }

  @media (max-width: 1000px) {
    .buttonsPage {
      left: 0 !important;
      position: initial;
      margin-bottom: 13px;
      display: block;
      margin-top: -45px;
    }
  }

  .bannerClass {
    text-align: right;
    padding: 15px 20px;
    background-position-y: 57% !important;
    background-size: 100% !important;
    margin: 10px;
    background: url(https://i.ibb.co/jLCg2py/447366a….jpg), rgb(0 0 0 / 67%);
    background-blend-mode: overlay;
    color: white;
  }

  .bannerClass button {
    padding: 5px 32px;
    border: 0;
    font-size: 12px;
    border-radius: 15px;
    background-color: #fcb900;
  }

  .bannerClass h2,
  .bannerClass h3 {
    font-weight: 100 !important;
  }

  .rightPosition {
    float: right;
    width: 55%;
  }

  .leftPosition {
    float: left;
    width: 45%;
  }

  @media (max-width: 1024px) {
    .leftPosition {
      float: left;
      width: 110% !important;
    }
  }

  .topClass {
    overflow: auto;
  }

  @media (max-width: 1000px) {
    .topClass {
      background: rgba(228, 225, 225, 0.75) !important;
      padding-bottom: 10px;
    }

    .categoriasTitle {
      background: rgb(221 219 219) !important;
      margin-bottom: 10px !important;
      padding: 10px 15px 10px 15px !important;
    }
  }

  .modalOrdenar > div {
    right: 0 !important;
    position: absolute;
    bottom: 0px !important;
  }

  .categoriasTitle h5 {
    font-weight: 100;
    font-size: 11px;
  }

  .categoriasTitle h4 {
    font-weight: 100;
    font-size: 15px;
  }

  .categoriasTitle {
    padding: 10px 15px;
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }

  @media (max-width: 1024px) {
    .navbar {
      margin-top: -10px;
      background: rgba(228, 225, 225, 0.75);
      height: 50px !important;
    }

    .navbar h1 {
      display: flex !important;
      margin: -10px 0 0 0 !important;
      font-size: 18px !important;
    }
  }

  nav.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 38px;
    width: 100vw;
  }

  nav.navbar h1 {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.fontColor};
    margin: auto auto auto 12px;
  }

  nav.navbar i.fa-arrow-left {
    color: ${(props) => props.fontColor};
    font-size: 14px;
    margin-left: 16px;
    cursor: pointer;
  }

  nav.navbar i.fa-arrow-left:hover {
    opacity: 0.8;
  }

  .leftSide {
    width: 80%;
    padding: 0 25px;
    background: rgba(237, 237, 237, 1);
  }

  .rightSide {
    height: 690px;
    overflow: scroll;
    width: 20%;
    background: #dadada;
    padding-bottom: 20px;
  }
  @media (max-width: 1368px) {
    .rightSide {
      height: 390px;
    }
  }

  .leftSide * {
    border-radius: 0 !important;
  }

  .materialsContent {
    display: flex;
    height: 100%;
  }

  @media (max-width: 1000px) {
    .rightSide {
      height: 100%;
      padding: 0 0 200px 0;
    }
  }

  .buttonGroup {
    margin-top: 5px;
    width: 95%;
    border-left: 4px solid;
    margin-bottom: 5px;
    height: 70px;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    margin-left: 5%;
    transition: 0.2s;
    font-weight: 501;
    text-align: left;
    padding: 40px 0 0 10px;
    color: black !important;
    box-shadow: 3px 1px 7px #00000045;
  }

  .buttonGroup:hover {
    opacity: 0.5;
    transition: 0.2s;
  }

  .button-next {
    display: none !important;
  }
`;
export const ContainerLoading = styled.div`
margin-top: 50%;
margin-left: 38%;
border: 5px solid ${props => props.backgroundColor};
border-radius: 50%;
border-top: 5px solid ${props => props.brandColor};
width: 80px;
height: 80px;
-webkit-animation: spin 2s linear infinite;
animation: spin 2s linear infinite;

@media only screen and (min-width: 1024px) {
  margin-top: 80%;
  margin-left: 34%;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`

export const SelectFaceAlert = styled.div`
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
  height: 100%;
  background: ${props => props.backgroundColor};
  z-index: 10;
  position: absolute;
  opacity: .96;
  color: ${props => props.fontColor};
  font-size: 16px;
  text-align: center;
  padding: 16px;

  div.icon-div {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  div.icon-div img {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
    position: relative;
    height: 100%;
    overflow-y: hidden;
  }
`

export const LastAppliedList = styled.div`
  width: 94vw;
  margin: 12px 3vw auto 3vw;

  @media(max-width: 1024px){
  }

  @media only screen and (min-width: 1024px) {
    width: 100% !important;
    margin: 12px 0 auto 8px !important;
    

    img {
      margin: 0 4px 6px 4px !important;
    }

    div {
      width: 500px !important;
    }
  }

  h2 {
    color: ${props => props.fontColor};
    font-size: 14px;
    margin: 0 0 10px 4px;
    font-weight: 500;
    text-align: start;
    width: 100%;
  }

  img {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    margin: 0 .2rem .2rem .2rem;
    cursor: pointer;
  }

  img:hover {
    opacity: .8;
  }

  div {
    width: 94vw;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`