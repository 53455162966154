import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//import { Offline, Online } from "react-detect-offline";
import api from '../../services/api'
import Modal from '@material-ui/core/Modal'
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

import * as loginActions from './../../store/modules/login/actions'
import * as userRoomsActions from './../../store/modules/userRooms/actions'

// Styles
import { Container, Loading, Creditos, Geral } from './styles'

// Components
import SectionTopBar from '../../components/SectionTopBar'
import NavigationBar from '../../components/NavigationBar'
import GenericModal from '../../components/GenericModal'
import ModalBackground from '../../components/ModalBackground'

import Contracts from '../../pages/Contracts'

import * as userActions from '../../store/modules/user/actions'

const Profile = () => {

  const history = useHistory()
  const user = useSelector(state => state.loginReducer.user)

  const [isConnectionUnstable, setIsConnectionUnstable] = useState(false)
  const [displayDeleteAccountModal, setDisplayDeleteAccountModal] = useState(false);
  const [displayDeleteAccountConfirm, setDisplayDeleteAccountConfirm] = useState(false);
  const [login, setLogin] = useState(' ');
  const [name, setName] = useState(' ');
  const [email, setEmail] = useState(' ');
  const [errorMessage, setErrorMessage] = useState('')
  const [phone, setPhone] = useState(' ');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [managerAccess, setManagerAccess] = useState(false);
  const [ready, setReady] = useState(false);
  let [plan, setPlan] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const userId = useSelector(state => state.loginReducer.user.id)
  const isUserLoading = useSelector(state => state.userReducer.isLoading)
  const User = useSelector(state => state.userReducer.user)
  const userType = useSelector((state) => state.loginReducer.user.type);
  const contract = useSelector((state) => state.contractReducer.contract);

  const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const dispatch = useDispatch()

  const refButton = useRef(null)

  const [currentMenu, setCurrentMenu] = useState('Contratos');

  const [sessao, setSessao] = useState('registerData')

  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleToggle2 = () => {
    setOpen2(!open);
  };

  useEffect(() => {
    function onKeyup(e) {
      if (e.key === 'Enter') {
        if (refButton.current)
          refButton.current.click()
      }
    }
    window.addEventListener('keyup', onKeyup)

    return () => window.removeEventListener('keyup', onKeyup)
  }, [])

  useEffect(() => {
    if (navigator.connection) {
      const handleConnectionChange = () => navigator.connection.downlink < 0.45 ? setIsConnectionUnstable(true) : setIsConnectionUnstable(false)
      handleConnectionChange()
      navigator.connection.onchange = handleConnectionChange
    }
  }, []);

  useEffect(() => {

    (isUserLoading) && dispatch(userActions.getUser(userId))

    setLogin(User.login)
    setName(User.name)
    setEmail(User.email)
    setPhone(User.phone)
    setPassword(User.password)
    setManagerAccess(User.type == 'manager')
    setTimeout(() => {
      document.getElementById("password").type = 'password'
      document.getElementById("password2").type = 'password'
    }, 500)


    console.log(User);

    handGetPlans();

  }, [User, isUserLoading]);

  const handleLogoutClick = () => {
    dispatch(loginActions.loginFailure())
    dispatch(userRoomsActions.returnUserRoomsInitialState())
    setTimeout(() => {
      window.location.reload()
    }, 100);
  }

  const handGetPlans = () => {
    api
      .get('plan')
      .then((data) => {
        for (const i of data.data.plans) {
          if (contract.plan._id === i._id) {
            setPlan(i);
            plan = i;
          }
        }
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteAccount = () => {
    api
      .delete('user/' + userId)
      .then(() => {
        setDisplayDeleteAccountConfirm(true)
        setTimeout(() => {
          setDisplayDeleteAccountConfirm(false)
          setDisplayDeleteAccountModal(false)
          handleLogoutClick()
        }, 4000)
      })
      .catch((err) => console.log(err))
  }

  const apiUpdateEmail = () => {
    api
      .put(`user/${userId}`, {
        email: email,
        login: login,
        name: name,
        phone: phone,
        password: password,
      })
      .then(() => {
        setTimeout(() => {
          setShowLoading(false)
          handleClose()
          setTimeout(() => {
            window.location.reload()
          }, 500);
        }, 3000);
      })
      .catch((err) => console.log(err));
  }

  const apiUpdate = () => {
    api
      .put(`user/${userId}`, {
        name: name,
        phone: phone,
        password: password,
      })
      .then(() => {
        setTimeout(() => {
          setShowLoading(false)
          handleClose()
          setTimeout(() => {
            history.push("/");
          }, 500);
        }, 3000);
      })
      .catch((err) => console.log(err));
  }

  const apiUpdateLogin = () => {
    api
      .put(`user/${userId}`, {
        login: login,
        email: email,
        name: name,
        phone: phone,
        password: password,
      })
      .then(() => {
        setTimeout(() => {
          setShowLoading(false)
          handleClose()
          setTimeout(() => {
            window.location.reload()
          }, 500);
        }, 3000);
      })
      .catch((err) => console.log(err));
  }

  const handleSave = async () => {

    handleToggle2();

    if (password === undefined)
      setPassword("");

    if (User.email != email) {
      const isEmailValid = validateEmail(email)
      isEmailValid ? (
        api
          .get('user/email/' + email)
          .then(() => setErrorMessage("Email já cadastrado."))
          .catch(() => {
            setErrorMessage('')
            apiUpdateEmail()
            setShowLoading(true)
          })
      )
        : setErrorMessage("Email inválido. Tente novamente.")

    } else if (User.login != login) {
      if (login === email || login === User.email) {
        setErrorMessage("Login não pode ser um email.")
      } else {
        api
          .get('user/login/' + login)
          .then(() => setErrorMessage("Login já cadastrado."))
          .catch(() => {
            setErrorMessage('')
            apiUpdateLogin()
            setShowLoading(true)
          })
      }
    } else {
      setErrorMessage('')
      apiUpdate()
      setShowLoading(true)
    }

  }

  const handleEditContract = () => {
    //setOpen(true);
    setCurrentMenu('Contratos')
  };

  const handleEditContractSubmenu = (sessao) => {
    setCurrentMenu('Contratos')
    setSessao(sessao)
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validateEmail(inputText) {
    const mailformat = /^\w*(\.\w*)?@\w*\.[a-z]+(\.[a-z]+)?$/
    const isValid = inputText.match(mailformat)
    return isValid
  }


  return (
    <>
      <Geral>

        <Loading className={User.length === 0 ? "show" : "hide"}>
          <CircularProgress />
        </Loading>
      
        <Container className={User.length != 0 ? "show" : "hide alearyhide"}>

          <button className='backbutton' onClick={() => history.push("/")}><i class="fas fa-arrow-left"></i> VOLTAR</button>

          <h2 className='title'>Bem vindo(a) {User.name}!</h2>

          <div className="top">
            <div className='left'>
              <h3>Você está em: Minha Conta</h3>
            </div>

          </div>

          <form autocomplete="off" noValidate class="form">

            <Alert severity="success"><b>Seu plano: </b> {plan ? plan.description : ""} - <a target="_blank" href='https://cria.io/'>Veja mais</a></Alert>

            <TextField value={login} label="Login" disabled size="small" variant="filled" className='disable' />

            <TextField value={name} label="Nome" onChange={e => setName(e.target.value)} required size="small" variant="filled" />

            <TextField value={email} label="Email" disabled size="small" variant="filled" className='disable' />

            <TextField value={phone} label="Telefone" onChange={e => setPhone(e.target.value)} required size="small" variant="filled" />

            <div className='password'>
              <TextField value={password} label="Nova senha" onChange={e => setPassword(e.target.value)} autoComplete="current-password" id="password" required size="small" variant="filled" />

              <TextField error={(password === passwordConf || passwordConf === "") ? false : true} value={passwordConf} label="Confirmar senha" onChange={e => setPasswordConf(e.target.value)} autoComplete="current-password" id="password2" required size="small" variant="filled" />
            </div>
            <div className="content buttonSave" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {!showLoading && (

                <input
                  ref={refButton}
                  onClick={() => handleSave()}
                  type="submit"
                  className={(password === passwordConf || password === "" || password === undefined) ? "clickable" : "notClickable"}
                  value="Salvar"
                />

              )}
              {showLoading && (
                <button>
                  <div className="c-loader"></div>
                </button>
              )}
              <h5>{errorMessage}</h5>
            </div>
          </form>

          <Creditos>
            Desenvolvido por <b>CRIA Studio</b> LTDA - Todos os direitos reservados.
          </Creditos>

          <Backdrop className='backdrop' open={open2}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>

        </Geral>
    </>
  )
}

export default Profile