import styled from "styled-components";

export const NavCadastro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.button`
  background-color: #4b4a4acc;
  width: 104px;
  height: 32px;
  align-items: center;
  border-style: hidden;
  color: #ffffff;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const CameraTable = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CameraContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NavTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;
