import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  background-color: transparent;
  z-index: 4;
  padding: 16px;
  margin: auto;
  border-radius: 0px;

  div.loader {
    place-self: center;
    border: 5px solid white;
    border-top: 5px solid #9ca396;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
`;
export const InputText = styled.input`
  width: 200px;
  height: 15px;
`;

export const ThumbList = styled.ul`
  display: flex;
  overflow-x: scroll;
  gap: 20px;
`;
export const ImgContainer = styled.div`
  height: 300px;
  width: 310px;
  background-color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
export const Img = styled.img`
  width: 310px;
  height: 235px;
  object-fit: cover;
`;
export const PageContainer = styled.div`
  padding-top: 24px;
  .page-title {
    text-align: center;
    color: black;
  }
`;

export const ListContainer = styled.div`
  max-width: 100px;
  display: flex;
  gap: 8px;
`;

export const BrandsContainer = styled.div`
  max-height: 360px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 4px;
  margin-top: 24px;
  overflow-y: auto;
`;

export const HeaderMateriais = styled.div`
  padding-top: 24px;
  .text-header {
    text-align: center;
    color: black;
  }
`;

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
`;
export const SelectionBox = styled.div`
  position: relative;

  .checkbox {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const MateriaisFiltrados = styled.div`
  max-height: 360px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 4px;
  margin-top: 24px;
  overflow-y: auto;
`;

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-left: 24px;
  font-size: 1.3rem;

  .checkbox-title {
    padding: 0;
  }
`;
