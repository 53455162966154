import styled from 'styled-components'

export const Container = styled.div`
  padding: 24px;
  border: 1px #e4e4e4 solid;
  height: calc(100% - 48px);
  border-radius: 15px;
  overflow-y: scroll;

label {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 3px;
  margin-left: 4px;
  font-family: 'Montserrat',sans-serif;
  color: #222;
  font-weight: bold;
}

.error-validate {
  color: red;
  text-align: center;
  width: 100%;
  float: left;  
}

.save-msg-label {
  color: green;
  text-align: center;
  float: inline-start;
  left: 50%; 
  display: block;
  position: absolute;
}

.user-data-items-left {
  margin-left: 24px;
}

.cancel-user-btn {
  padding: 6px 18px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 20%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #2196F3;
  background-color: transparent;  
  float: right;
}

.save-user-btn {
  padding: 6px 28px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 20%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: #2196F3;  
  margin-left: 12px;
  float: right;
}

.save-user-btn :hover,
.cancel-user-btn :hover {
  opacity: .7;
}

`

export const ButtonGroup  = styled.div`

`

export const Button  = styled.div`

`