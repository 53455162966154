import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;  
  width: 100vw;
  height: 90vh;
  background-color: #E4E1E1 !important;    
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.20);
  display: ${props => props.display};
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  opacity: 1;
  z-index: 4;  
  margin: 0px;
  bottom: 0px;
  border-radius: 0 !important;
  padding: 0 25px 30px;

  @media (max-width: 1366px){
    .material-list{
      height:60% !important
    }
  }

  @media (min-width: 1180px){
    .material-list > ul {
      display: inline-flex !important;
    }
  }

  @media (max-width: 1180px){
    
    .material-list{
      display:grid !important
    }

    .material-list > ul {
      width: 100% !important;
    }
    
  }

  @media (max-width: 1024px){
    .title{
      font-size: 15px;
      margin-top: 40px !important;
      padding-bottom: 0;
    }

    i.close{
      top: 25px !important;
      right:15px !important;
    }

    .empty-list{
      color: gray !important;
      font-weight: 100 !important;
      font-size: 15px !important
    }

    .material-list {
      border-top: 1px solid white;
    }

    padding: 0 10px 30px;

    h1{
      border: 0 !important;
    }
  }

  @media only screen and (min-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);    
    width: 60vw;
    height: 72vh;
    border-radius: 20px;

    div.content h3,
    div.content p,
    div.content small.alert,
    div.content input[type=text] {
      width: 348px !important;
    }

    div.material-list {
      padding: 24px;
    }

  }
  
  h1 {
    margin: 50px 0 20px;
    padding-bottom: 20px;
    width: 93%;
    color: #525252;
    font-size: 25px;
    font-weight: 100;
    border-bottom: 1px solid #909090;
  }

  .empty-list {
    text-align: center;
    margin-top: 100px;
    color: #515151;
    font-size: 1.5em;
  }

  div.material-list {
    width: 100%;
    height: 75%;
    padding: 16px;
    overflow-y: auto;
  }

  .material-list * {
    border-radius: 0 !important;
  }

  i.close {
    position: absolute;
    right: 4px;
    top: 1px;
    font-size: 20px;
    margin: 8px;
    padding: 6px;
    color: #52525280;
    border-radius: 20px;
    :hover {
      opacity: .7;
      box-shadow: 3px 3px 3px 0px #666;
      cursor: pointer;
    }
  }

  button {
    display: flex;
    position: relative;
    width: 138px;
    height: 32px;
    border-radius: 0px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin: auto 4px auto 4px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    color: ${props => props.brandContrastColor};
    background-color: rgb(158, 142, 117);
    position: absolute;
    bottom: 20px;
  }

  button div.buttonload {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #0048ff;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`