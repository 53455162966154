import React from "react";
import { useState, useEffect, useRef } from "react";
import * as roomsActions from "../../../../../../store/modules/rooms/actions";
import * as contractsAction from "../../../../../../store/modules/contract/actions";
import { useDispatch, useSelector } from "react-redux";
import Cameras from "./Cameras";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Switch from "react-switch";
import { InputLabel } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  NavCadastro,
  Button,
  Forms,
  Img,
  Fields,
  Table,
  Field,
  ButtonBack,
  Group,
  Container,
  FormContainer,
  ImgContainer,
  DivButtons,
} from "./styles";
import { Chip } from "@material-ui/core";

import api from "../../../../../../services/api";
import { faRemoveFormat } from "@fortawesome/free-solid-svg-icons";

const DEFAULT_THUMB_TYPE = {
  Cozinha:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Cozinha.jpg",

  Fachada:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Fachada.jpg",

  Lavanderia:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Lavanderia.jpg",

  Quarto:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Quarto.jpg",

  Banheiro:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Banheiro.jpg",

  Sala: "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Sala.jpg",

  ÁreaGourmet:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Área Gourmet.jpg",

  Closet:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Closet.jpg",
  Escritório:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Escritório.jpg",
  Apartamento:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/desktop/Apartamento.jpg",
};

const DEFAULT_MOBILE_THUMB_PATH = {
  Cozinha:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Cozinha.jpg",

  Fachada:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Fachada.jpg",

  Lavanderia:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Lavanderia.jpg",

  Quarto:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Quarto.jpg",

  Banheiro:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Banheiro.jpg",

  Sala: "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Sala.jpg",

  ÁreaGourmet:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Área Gourmet.jpg",

  Closet:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Closet.jpg",
  Escritório:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Escritório.jpg",
  Apartamento:
    "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/mobile/Apartamento.jpg",
};

const DEAFAULT_ERROR = {
  title: "O titulo deve ser apenas letras",
};

const validaCanvasId = (text) => {
  return /^\d+$/g.test(text);
};

const Form = (props) => {
  const user = useSelector((state) => state.loginReducer.user);

  const dispatch = useDispatch();
  const { types, value, setFormVisibility, mode, contract } = props;
  const [tags, setTags] = useState(value.tags? value.tags : [] );
  const [tagToBeAdd, setTagToBeAdd] = useState({ description: "" });
  const [formsCamera, setFormsCamera] = useState(true);
  const [room, setRoom] = useState(value);
  const [cameras, setCameras] = useState(room.cameras);
  const [errors, setErros] = useState({
    playCanvas: false,
  });
  const [tempThumb, setTempThumbRoom] = useState(
    room.thumbPath ? room.thumbPath : ""
  );
  const [ThumbChanged, setThumbChanged] = useState(false);
  const [sceneChanged, setSceneChanged] = useState(false);
  const [tempRoom, setTempRoom] = useState(room.urlScene ? room.urlScene : "");
  const [isThumb, setIsThumb] = useState(room.thumbPath === "" ? false : true);
  const [ambienteFile, setAmbienteFile] = useState(
    room.urlScene ? room.urlScene.split("/").pop() : "Nehum Arquivo"
  );
  const [isLoading, setIsLoading] = useState(false);
  const formTitle = mode === 0 ? "Editar" : "Novo";
  const [tagRoom, setTagRoom] = useState([]);
  const inputFileThumb = useRef(null);
  const inputFileAmbiente = useRef(null);

  const onChangeHandlerTag = (e) => {
    const { value } = e.target;
    setTagToBeAdd({ description: value.replace("\n", "") });
  };

  const handlerSubmmitTag = (e) => {
    e.which = e.which || e.keyCode;
    if (e.which == 13) {
      setTags((prevTags) => [...prevTags, tagToBeAdd]);
      setTagToBeAdd({ description: "" });
    }
  };

  const handleDeleteTag = (id) => {
    const newTags = tags.filter((tag, index) => {
      if (index != id) {
        return tag;
      }
    });
    setTags(newTags);
  };
  const setNewRoom = async (mode) => {
    if (ThumbChanged) {
      const urlThumb = await saveThumbRoom();
      room.thumbPath = urlThumb;
      setTempThumbRoom(urlThumb);
    }
    if (sceneChanged) {
      const urlScene = await saveAmbiente();
      room.urlScene = urlScene;
    }
    let resp;
    if (mode === 0) {
      resp = await api.put(`room/${room._id}`, room).catch((err) => {
        console.log(err);
      });
    } else {
      resp = await api.post(`room`, room).catch((err) => {
        console.log(err);
      });
      if (resp.data.success) {
        const respContract = await api
          .put(`/contract/room/${resp.data.id}`, {
            contracts: [contract.initiatedOrders.data.contract._id],
          })
          .catch((err) => {
            console.log(err);
          });
        if (respContract.data.success) {
          dispatch(contractsAction.getContract(user.id));
        }
      }
    }

    if (resp.data.success) {
      dispatch(roomsActions.getAllRooms(null));
    }
  };

  const saveThumbRoom = async () => {
    let thumbHelper = await blobToBase64(tempThumb);
    thumbHelper = thumbHelper.slice(thumbHelper.indexOf(",") + 1);
    const thumb = await api.post("S3/uploadFile", {
      key: `collection-assets/ambientes/${room.type.description}/${`${
        room.title
      }-${Date.now()}`}.png`,
      data: thumbHelper,
      contentType: "image/webp",
    });

    return thumb.data.data.src;
  };

  const saveAmbiente = async () => {
    let ambienteHelper = await blobToBase64(tempRoom);
    ambienteHelper = ambienteHelper.slice(ambienteHelper.indexOf(",") + 1);
    const scene = await api.post("S3/uploadFile", {
      key: `collection-assets/ambientes/${room.type.description}/${`${
        room.title
      }-${Date.now()}`}.${ambienteFile.split(".").pop()}`,
      data: ambienteHelper,
      contentType: "model/gltf-binary",
    });

    return scene.data.data.src;
  };

  const onChangeCheckBox = (event) => {
    const value = room.status === "active" ? "inactive" : "active";
    setRoom((prevRoom) => ({
      ...prevRoom,
      status: value,
    }));
  };

  const onChangeHandlerRoom = async (event) => {
    const { name, value, files } = event.target;
    switch (name) {
      case "type":
        setRoom((prevRoom) => ({
          ...prevRoom,
          [name]: {
            description: value,
            thumPath: DEFAULT_THUMB_TYPE[value.replace(" ", "")],
            mobileThumPath: DEFAULT_MOBILE_THUMB_PATH[value.replace(" ", "")],
          },
        }));
        break;
      case "thumbPath":
        setTempThumbRoom(URL.createObjectURL(files[0]));
        setIsThumb(true);
        setThumbChanged(true);
        break;

      case "urlScene":
        setTempRoom(URL.createObjectURL(files[0]));
        setAmbienteFile(value.split(`\\`).pop());
        setSceneChanged(true);
        break;
      case "title":
        setRoom((prevRoom) => ({
          ...prevRoom,
          [name]: value,
        }));
        break;
      case "description":
        setRoom((prevRoom) => ({
          ...prevRoom,
          [name]: value,
        }));
        break;
      case "playCanvas":
        setRoom((prevRoom) => ({
          ...prevRoom,
          [name]: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleValidation = (event) => {
    const { name, value } = event.target;
    if (name === "playCanvas") {
      setErros((prevErr) => ({
        ...prevErr,
        [name]: !validaCanvasId(value),
      }));
    }
  };

  const blobToBase64 = (blobUrl) => {
    return new Promise((resolve, _) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";

      xhr.onload = function () {
        var recoveredBlob = xhr.response;

        var reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);

        reader.readAsDataURL(recoveredBlob);
      };

      xhr.open("GET", blobUrl);
      xhr.send();
    });
  };

  const onButtonFileClick = (event) => {
    const { name } = event.target;
    if (name === "thumb") {
      inputFileThumb.current.click();
    } else {
      inputFileAmbiente.current.click();
    }
  };

  useEffect(() => {
    setRoom((prevRoom) => ({ ...prevRoom, cameras: cameras }));
  }, [cameras]);

  useEffect(() => {
    setRoom((prevRoom) => ({ ...prevRoom, tags: tags }));
  }, [tags]);
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <Container>
            <div className="loader"></div>
          </Container>
        </div>
      ) : (
        <FormContainer>
          <NavCadastro>
            <h2>{formTitle} Ambiente</h2>
          </NavCadastro>
          <Forms
            onSubmit={async (event) => {
              event.preventDefault();
              setIsLoading(true);
              await setNewRoom(mode);
              setIsLoading(false);
              setFormVisibility(false);
            }}
          >
            <Fields>
              <Group>
                <ImgContainer>
                  <FormControl className="input-arq">
                    <label className="label-img">Thumb</label>
                    {isThumb && <Img src={tempThumb} />}
                    <Button
                      name="thumb"
                      className="button-file"
                      type="button"
                      onClick={onButtonFileClick}
                    >
                      Selecionar
                    </Button>
                    <input
                      type="file"
                      id="thumbPath"
                      ref={inputFileThumb}
                      accept="image/*"
                      onChange={onChangeHandlerRoom}
                      name="thumbPath"
                      style={{ display: "none" }}
                    />
                  </FormControl>
                </ImgContainer>
                <div>
                  <Field>
                    <FormControl className="input-text">
                      <TextField
                        required
                        label="Nome do Ambiente"
                        name="title"
                        onChange={onChangeHandlerRoom}
                        placeholder="Nome do Amnbiente"
                        value={room.title}
                      ></TextField>
                    </FormControl>
                    <FormControl className="input-select">
                      <InputLabel id="select-type">Tipo</InputLabel>
                      <Select
                        id="select-type"
                        label="Tipo Ambiente"
                        required={true}
                        value={room.type.description}
                        name="type"
                        onChange={onChangeHandlerRoom}
                      >
                        {types.map((type, index) => (
                          <MenuItem value={type} key={index}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <label>Ativo</label>
                      <Switch
                        onChange={onChangeCheckBox}
                        checked={room.status === "active" ? true : false}
                      />
                    </FormControl>
                  </Field>
                  <Field>
                    <FormControl className="input-text-description">
                      <TextField
                        label="Descrição do Ambiente"
                        required
                        name="description"
                        onChange={onChangeHandlerRoom}
                        value={room.description}
                        placeholder="Descrição do Ambiente"
                      ></TextField>
                    </FormControl>

                    <FormControl className="input-text-canvas-id">
                      <TextField
                        multiline
                        required
                        error={errors.playCanvas}
                        label="Canvas ID"
                        onBlur={handleValidation}
                        name="playCanvas"
                        onChange={onChangeHandlerRoom}
                        value={room.playCanvas}
                        placeholder="Id do Canvas"
                        helperText={
                          errors.playCanvas ? "Deve conter apenas números" : ""
                        }
                      ></TextField>
                    </FormControl>

                    <FormControl className="input-arq-ambiente">
                      <TextField
                        disabled
                        label="Ambiente"
                        value={ambienteFile}
                      ></TextField>

                      <Button
                        type="button"
                        name="Ambiente"
                        className="button-file"
                        onClick={onButtonFileClick}
                      >
                        Selecionar
                      </Button>

                      <input
                        type="file"
                        id="urlScene"
                        ref={inputFileAmbiente}
                        accept="model/gltf-binary"
                        onChange={onChangeHandlerRoom}
                        name="urlScene"
                        style={{ display: "none" }}
                      />
                    </FormControl>
                  </Field>
                  <Field>
                    <div className="div-tags">
                      <Autocomplete
                        sx={{ width: 500 }}
                        multiple
                        id="tags-filled"
                        options={tags.map((option) => option.description)}
                        defaultValue={value.tags.map(
                          (option) => option.description
                        )}
                        freeSolo
                        value={tags}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            return (
                              <Chip
                                variant="outlined"
                                label={option.description}
                                {...getTagProps({ index })}
                                onDelete={() => handleDeleteTag(index)}
                              />
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            multiline
                            error={errors.playCanvas}
                            label="Tags"
                            value={tagToBeAdd.description}
                            onKeyUp={handlerSubmmitTag}
                            name="tags"
                            onChange={onChangeHandlerTag}
                            placeholder="Tags"
                          />
                        )}
                      />
                    </div>
                    {/* <TextField
                      multiline
                      error={errors.playCanvas}
                      label="tags"
                      value={tagToBeAdd.description}
                      onKeyUp={handlerSubmmitTag}
                      name="tags"
                      onChange={onChangeHandlerTag}
                      placeholder="Tags"
                    ></TextField>
                    {tags.map((tag, index) => {
                      if (tag) {
                        return (
                          <Chip
                            id={index}
                            key={index}
                            label={tag.description}
                            onDelete={() => handleDeleteTag(index)}
                          />
                        );
                      }
                    })} */}
                  </Field>
                </div>
              </Group>
              <Table>
                <Cameras
                  setFormsCamera={setFormsCamera}
                  setCameras={setCameras}
                  cameras={cameras}
                  setRoom={setRoom}
                />
              </Table>
              <DivButtons>
                <Button
                  type="button"
                  onClick={() => {
                    setFormVisibility(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button type="submit">Salvar</Button>
              </DivButtons>
            </Fields>
          </Forms>
        </FormContainer>
      )}
    </>
  );
};

export default Form;
