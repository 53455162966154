import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    flex: 1;
    height: ${props => props.innerHeight}px;
    width: 100vw;
    justify-content: start;
    align-items: center;
    background: url(https://images.unsplash.com/photo-1519710164239-da123dc03ef4?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80), rgba(245, 245, 245, 0.7);
    background-blend-mode: overlay;
    background-size: 100% auto;
    font-family: 'Montserrat', sans-serif;
    min-height: 500px;
    overflow-y: auto;
    padding-right: 20px;
    padding-left: 20px;

    @media only screen and (min-width: 1024px) {
        background: url(https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1506&q=80), rgba(245, 245, 245, 0.9);
        padding-right: 100px;
        padding-left: 100px;
    }

    h2 {
        font-size: 14px;
        margin-top: 80px;
        padding-bottom: 10px;
        border-bottom: 3px solid ${props => props.brandColor};
        text-align: center;
        font-weight: 500;
    }

    p {
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 40px;
    }
`

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  height: 38px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.7);
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  img {
    height: 24px;
    cursor: pointer;
  }
`