import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const Loader = (props) => {
    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    return (
        <Container
            backgroundColor={backgroundColor}
            fontColor={fontColor}
            brandColor={brandColor}
            brandContrastColor={brandContrastColor}
            borderRadius={props.borderRadius ? props.borderRadius : "0px"}
        >
            <div className="loader">
                
            </div>

        </Container>
    )
}

export default Loader