import * as types from '../types'

const initialState = {
    rooms: [],
    isLoading: true,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_ROOMS_SUCCESS: {
            const newState = { ...state }
            newState.rooms = action.payload.data.rooms
            newState.isLoading = false
            return newState
        }
        case types.ADD_ROOM_SUCCESS: {
            // dispatch(roomsActions.getAllRooms(null))
        }
        // case types.ADD_ROOM_TO_CONTRACTS_SUCCESS: {
        //     
        // }
        default: {
            return state;
        }
    }
}