import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";

import * as loginActions from "./../../../../store/modules/login/actions";

import api from "../../../../services/api";

// Styles
import { Container } from "./styles";

// Components
import SectionTopBar from "../../../../components/SectionTopBar";
import TipsModal from "../../../../components/TipsModal";
import ModalBackground from "../../../../components/ModalBackground";
import RoomTypeThumb from "./components/RoomTypeThumb";
import RoomModelThumb from "./components/RoomModelThumb";
import CircularProgress from "@material-ui/core/CircularProgress";
import GenericModal from "../../../../components/GenericModal";

const RoomsList = (props) => {
  const [roomType, setRoomType] = useState(props.type);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [ready, setReady] = useState(false);

  const onboarding = useSelector((state) => state.loginReducer.onboarding);

  let [roomsNotAut, setRoomsNotAut] = useState([]);
  let [rooms, setRooms] = useState(
    useSelector((state) => state.contractReducer.contractRooms.data)
  );

  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );
  const roomsContract = useSelector(
    (state) => state.contractReducer.contract.rooms
  );
  let TestUser = useSelector((state) => state.loginReducer.user.test);

  const [readyRooms, setReadyRooms] = useState(false);
  const [planoDisplay, setPlanoDisplay] = useState(false);
  const [readyRoomsLoad, setReadyRoomsLoad] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => setInnerHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (onboarding) {
      dispatch(loginActions.setOnboarding(true));
    }
  }, []);

  const onTypeThumbClick = (id) => {
    setRoomType(id);
  };

  const onModelThumbClick = (room) => {
    const { _id, playCanvas, cameras, urlScene } = room;

    props.history.push({
      pathname: "/simulador",
      state: {
        roomType: roomType,
        roomModelId: _id,
        playcanvasId: playCanvas,
        roomCameras: cameras,
        showInspirationModal: true,
        urlScene: urlScene,
      },
    });
  };

  const sortByName = (pList) => {
    return pList.sort(function (a, b) {
      if (a.title < b.title) return 1;
      if (a.title > b.title) return -1;
      return 0;
    });
  }

  const renderRooms = () => {

    api
      .get(`room`)
      .then(({ data }) => {
        let roomsArray = [];

        const sortedData = sortByName(data.rooms)

        sortedData.map((a) => {
          let exist = false;
          for (const i of rooms) {
            if (a.type._id === i._id || a.type.description === i.title) {
              exist = true;
              let ambientesRooms = [];

              for (const j of data.rooms) {
                if (j.type.description === i.title) {
                  let au = false;

                  roomsContract.map((x) => {
                    if (x === j._id) {
                      au = true;
                      j.authorized = true;
                    }
                  });
                  if (j.status === "active") {
                    if (au) ambientesRooms.unshift(j);
                    else ambientesRooms.push(j);
                  }
                }
              }

              i.rooms = ambientesRooms;
              break;
            }
          }

          for (const i of roomsArray) {
            if (i.description === a.type.description) {
              exist = true;
              break;
            }
          }

          if (!exist) {
            a.type.authorized = false;
            a.type.title = a.type.description;
            roomsArray.push(a.type);
          } else {
          }
        });
        roomsNotAut = roomsArray;
        setRoomsNotAut(roomsArray);      

        let ajuste = [];
        rooms.map((a) => ajuste.push(a));

        roomsNotAut.map((a) => ajuste.push(a));
        
        setRooms(ajuste);
        rooms = ajuste;
        setReadyRoomsLoad(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (TestUser && !readyRooms && !ready) {
      renderRooms();
      setReady(true);
    }

    if (!readyRooms && roomsContract.length > 0) {
      renderRooms();
      setReadyRooms(true);
    }
  });

  const onClickLock = () => {
    window.open('https://collection.com.br/assinarmbstudio/', '_blank', 'noopener,noreferrer');
  };
/*
  const onClickLock = () => {
    setPlanoDisplay(true);
    setTimeout(() => {
      setPlanoDisplay(false);
    }, 10000);
  };
*/
  return (
    <Container
      innerHeight={innerHeight}
      backgroundColor={backgroundColor}
      brandColor={brandColor}
      fontColor={fontColor}
    >
      <div className="modalClasse">
        <GenericModal
          display={planoDisplay}
          onCloseClick={() => setPlanoDisplay(false)}
          arrowYPosition={"28vh"}
          arrowXPosition={"6vw"}
          mainContent={
            <div className="modalPlanoClass">
              <h4>{"Ops..."}</h4>
              <br />
              <p>{"Este ambiente está disponível apenas na versão completa."}</p>
              <br />
              <p>
                {
                  "Para saber mais sobre a versão completa, clique "
                }{" "}
                <a href="https://collection.com.br/assinarmbstudio" target="_blank">
                  aqui
                </a>.
              </p>
            </div>
          }
        ></GenericModal>
        <div
          className={planoDisplay ? "fundo" : ""}
          onClick={() => {
            setPlanoDisplay(false);
          }}
        ></div>
      </div>

      {roomType ? (
        <>
          <button
            className="backbutton btnDesk"
            onClick={() => {
              setRoomType(false);
            }}
          >
            <i class="fas fa-arrow-left"></i> VOLTAR
          </button>

          <div className="containerAjuste">
            <img className="logo-cria" src="https://tinyurl.com/2zjml9oh" />
            {/*<div className="titulo-2">CRIA</div>
            <div className="titulo-3">STUDIO</div>*/}

            <div className="descricao">
              <p>Muito bem! 🤗</p> Agora escolha qual o melhor modelo de layout
              para iniciar.
            </div>
          </div>

          <button
            className="backbutton btnMob"
            onClick={() => {
              setRoomType(false);
            }}
          >
            <i class="fas fa-arrow-left"></i>{parseInt(window.screen.width) <= 600? `` : "VOLTAR"}
          </button>

          <div className="environment-options opcoes-class">
            {rooms
              .filter((type) => roomType == type._id)[0]
              .rooms.map((room, index) => (
                <>
                  {(room.authorized || TestUser) && (
                    <div>
                      <RoomModelThumb
                        key={index}
                        roomModelIndex={index}
                        typeId={room._id}
                        playCanvasId={room.playCanvas}
                        imgSrc={room.thumbPath}
                        onThumbClick={() => onModelThumbClick(room)}
                      ></RoomModelThumb>
                      <div
                        className="descricaoModelo"
                        onClick={() => onModelThumbClick(room)}
                      >
                        {room.title}
                        <i class="far fa-pen"></i>
                      </div>
                    </div>
                  )}

                  {!room.authorized && !TestUser && (
                    <div className="notAuthClass">
                      <RoomModelThumb
                        key={index}
                        roomModelIndex={index}
                        typeId={room._id}
                        playCanvasId={room.playCanvas}
                        imgSrc={room.thumbPath}
                        onThumbClick={() => onClickLock()}
                        blocked={true}
                      ></RoomModelThumb>
                      {/* <div className="buttonLock2">
                                            <button onClick={onClickLock}>Ver mais</button>
                                        </div> */}
                      <div className="descricaoModelo">
                        {room.title}
                        <i class="far fa-lock"></i>                        
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>

          {onboarding ? (
            <>
              <ModalBackground></ModalBackground>

              <TipsModal
                title={"Selecione o modelo de ambiente"}
                description={
                  "Escolha o modelo de ambiente que você deseja reformar!"
                }
                arrowUp={true}
                arrowDown={false}
                arrowYPosition={window.innerWidth >= 1024 ? "58vh" : "60vh"}
                arrowXPosition={"0"}
                xPosition={
                  window.innerWidth < 1024
                    ? false
                    : rooms.filter((type) => roomType == type._id)[0].rooms
                        .length === 1
                    ? false
                    : rooms.filter((type) => roomType == type._id)[0].rooms
                        .length === 2
                    ? "calc(38vw - 140px)"
                    : rooms.filter((type) => roomType == type._id)[0].rooms
                        .length === 3
                    ? "calc(26.9vw - 140px)"
                    : "calc(15.7vw - 140px)"
                }
              ></TipsModal>
            </>
          ) : null}
        </>
      ) : (
        <>
          <div className="containerAjuste">
            {/* <img src="https://tinyurl.com/2zjml9oh" /> */}
            <img className="logo-cria" src="https://tinyurl.com/2zjml9oh" />
            {/*<div className="titulo-2">CRIA</div>
            <div className="titulo-3">STUDIO</div>*/}

            <div className="descricao">
              <p>Bem vindo(a). 👋 </p>Para iniciar, selecione em que tipo de
              ambiente você quer criar o moodboard
            </div>
          </div>

          {window.innerWidth >= 1024 ? (
            <>
              {!readyRoomsLoad && <div className="loadingClass"></div>}
              {readyRoomsLoad && (
                <div className="room-types-list environment-options">
                  <CarouselProvider
                    className={
                      onboarding
                        ? "environment-options active"
                        : "environment-options"
                    }
                    naturalSlideWidth={window.innerWidth * 0.086}
                    naturalSlideHeight={200}
                    totalSlides={rooms.length}
                    visibleSlides={rooms.length >= 9 ? 9 : rooms.length}
                    isIntrinsicHeight
                  >
                    <Slider>
                      {rooms.map((room, index) => (
                        <Slide
                          className={
                            room.authorized === false
                              ? "slide notAuthorizedClass"
                              : "slide"
                          }
                          key={index}
                          index={index}
                          onClick={
                            onboarding && index != 0
                              ? () =>
                                  room.authorized === false
                                    ? onClickLock()
                                    : onTypeThumbClick(room._id)
                              : () =>
                                  room.authorized === false
                                    ? onClickLock()
                                    : onTypeThumbClick(room._id)
                          }
                        >
                          <RoomTypeThumb
                            key={index}
                            roomTypeIndex={index}
                            typeId={room._id}
                            title={room.title}
                            MobileThumbSrc={room.mobileThumbPath}
                            DesktopThumbSrc={room.thumbPath}
                          ></RoomTypeThumb>
                          {/* {room.authorized === false &&
                                                        <div className="buttonLock">
                                                            <button onClick={onClickLock}>Ver mais</button>
                                                        </div>
                                                    } */}
                        </Slide>
                      ))}
                    </Slider>
                    {rooms.length > 9 && (
                      <>
                        <ButtonBack className="button-back">
                          <i className="fas fa-arrow-left"></i>
                        </ButtonBack>
                        <ButtonNext className="button-next">
                          <i className="fas fa-arrow-right"></i>
                        </ButtonNext>
                      </>
                    )}
                  </CarouselProvider>
                </div>
              )}
            </>
          ) : (
            <div className="environment-options">
              {rooms.map((room, index) => (
                <div
                  className={
                    room.authorized === false
                      ? "slide notAuthorizedClass"
                      : "slide"
                  }
                >
                  <RoomTypeThumb
                    key={index}
                    roomTypeIndex={index}
                    typeId={room._id}
                    title={room.title}
                    isNotAuth={room.authorized === false ? true : false}
                    MobileThumbSrc={room.mobileThumbPath}
                    DesktopThumbSrc={room.thumbPath}
                    onThumbClick={() => {
                      room.authorized === false
                        ? onClickLock()
                        : onTypeThumbClick(room._id);
                    }}
                  ></RoomTypeThumb>
                </div>
              ))}
            </div>
          )}

          {onboarding ? (
            <>
              <ModalBackground></ModalBackground>

              <TipsModal
                title={"Selecione o tipo de ambiente"}
                description={
                  "Escolha o tipo de ambiente que você deseja reformar!"
                }
                arrowUp={window.innerWidth >= 1024 ? false : true}
                arrowDown={false}
                arrowLeft={window.innerWidth >= 1024 ? true : false}
                arrowYPosition={"40vh"}
                arrowXPosition={"-25vw"}
                xPosition={window.innerWidth >= 1024 ? "20vw" : false}
              ></TipsModal>
            </>
          ) : null}
        </>
      )}
    </Container>
  );
};

export default RoomsList;
