import styled from 'styled-components'

export const Loading = styled.div`
margin-left:auto;
margin-right:auto;
position: absolute;
display: flex !important;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;


.MuiCircularProgress-colorPrimary {
  color: #999999;
}
`

export const Creditos = styled.div`
    color: #6C6C6C;
    position: absolute;
    bottom:10px;
    width: 100%;
    text-align: center;
    left: 0;
    z-index: -2;

    @media(max-width: 640px){
      position: relative !important;
      margin-top: 20px !important;
    }
`

export const Geral = styled.div`
  height: 100%;
  width: 100%;

  .hide{
    animation-name: hide;
    animation-duration: 1s;  
    animation-fill-mode: forwards;
  }

  .alearyhide{
    display: none !important;
  }

  .show{
    display: block;
    animation-name: show;
    animation-duration: 1s;  
    animation-fill-mode: forwards;
  }

  @keyframes hide {
    from {opacity: 1;}
    to {opacity:0; display:none !important;}
  }

  @keyframes show {
    from {opacity:0;}
    to {opacity: 1;}
  }
`

export const Container = styled.div`
width: 60%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;

.backdrop{
  z-index: 999999 !important;
}

.disable .MuiFilledInput-root{
  background-color: #d2d2d2;
}

@media(min-width:1201px) and (max-width:1919px){
  .form {
    width: 70% !important;
  }

  .top{
    margin: 2% 0px 3% !important;
  }
}

@media(max-width: 1200px){
  .form {
    width: 90% !important;
  }
}

@media(max-width: 640px){
  width: 100% !important;
  overflow: auto !important;
  height: inherit !important;

  .password > div {
    width: 100% !important;
    
  }

  .password > div:first-child {
    margin-bottom: 10px !important;
  }
}

h2.title {
  margin-top: 4%;
  text-align: center;
}

button.backbutton {
  background: transparent none repeat scroll 0% 0%;
  width: 90px;
  font-size: 14px;
  color: #726a63;
  border: 0px none;
  margin: 40px 0 0 0px;
}

button.backbutton:hover{
  opacity: .7;
  transition:0.1s;
}

.form .MuiFilledInput-root {
  background-color: white;
}

.form > div {
  margin-bottom: 10px;
}

.container {
  width: 60%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.password > div:first-child {
  margin-right: 2%;
}

.password > div {
  width: 49%;
}

.MuiAlert-root {
  margin-bottom: 20px;
}

.top {
  margin:2% 0 5% 0px;
  display: flex;
  border-bottom: 1px solid #bbb;
  padding-bottom: 4px;
  padding-right: 10px;
  padding-left: 10px;
}

.left h3 {
  font-size: 14px;
  font-weight: 500;
  margin-top: 3px;
}

.right {
  margin-right: 0;
  margin-left: auto;
}

.right button {
  border: 0;
  background: #b50000;
  padding: 5px 15px;
  color: white;
  box-shadow: 1px 1px 5px #0000005e;
}

.form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.buttonSave input {
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  border: 0;
  padding: 9px 30px;
  background: #626262;
  color: white;
}

.buttonSave input:hover {
  transition: 0.2s;
  background: #828282;
}

.notClickable{
  pointer-events: none !important;
  opacity: 0.8;
}
`
