import styled from "styled-components";

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 300px;
  padding: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .Title {
    margin: 30px 30px 0px 30px;
  }
`;

export const Fields = styled.div`
  .input-text {
    margin: 8px 30px;
  }
  .input-button {
    display: flex;
    justify-content: flex-end;
    gap: 100px;
  }
`;

export const Button = styled.button`
  background-color: #4b4a4acc;
  width: 92px;
  height: 36px;
  align-items: center;
  border-style: hidden;
  color: #ffffff;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
