import styled from 'styled-components'

export const CamerasList = styled.ul`
  overflow-y: auto;
  margin: 12px 8px 0 8px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100vw - 16px);
  height: calc(100vh - 168px);

  @media only screen and (min-width: 1024px) {
    width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 92px);
    padding: 8px;
    margin: 0;

    li {
      width: 272px;
    }

    li img {
      width: 272px !important;
    }
  }

  li {
    position: relative;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: 8px;
    height: auto;
    cursor: pointer;
  }

  li:hover {
    opacity: .9;
  }

  li img {
    width: 90vw;
    height: auto;
    border-radius: 8px;
  }

  div.card-shadow {
    height: 60%;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.9));
    bottom: 0;
  }

  li h2 {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.backgroundColor};
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }
`

export const Warn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 14px;
    color: #141414;
    margin-top: 32px;
    text-align: center;
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
  }
`