import * as React from "react";

import { Button } from "@material-ui/core";
import { NAvBar } from "./styles";
import { useHistory } from "react-router-dom";

const NavBar = (props) => {
  const history = useHistory();

  const { setAmbientes, setMateriais, ambientes, materiais } = props;

  function handlerAmbientes() {
    setAmbientes(true);
    setMateriais(false);
  }

  function handlerMateriais() {
    setAmbientes(false);
    setMateriais(true);
  }
  return (
    <NAvBar>
      <button
        className="backbutton"
        onClick={() => {
          history.push("/");
        }}
      >
        <i className="fas fa-arrow-left"></i> VOLTAR
      </button>

      <Button
        onClick={() => handlerAmbientes()}
        className={ambientes ? "selecionado" : ""}
      >
        Ambientes
      </Button>
      {false && (
        <Button
          onClick={() => handlerMateriais()}
          className={materiais ? "selecionado" : ""}
        >
          Materiais
        </Button>
      )}
    </NAvBar>
  );
};
export default NavBar;
