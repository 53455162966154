import styled from 'styled-components'
//alt
export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #E5E5E5;
  font-family: 'Montserrat', sans-serif;
  margin-top: 38px;
  margin-bottom: 40px;
  height: calc(${(props) => props.innerHeight}px - 78px);
  overflow-y: auto;
  overflow-x: hidden;
  align-content: start;

  .environment-options *{
    border-radius: 0 !important;
  }

  .btnMob{
    display: none;
    position: inherit !important;
    margin-bottom: 20px !important;
    margin-top: -10px !important;
  }

  .btnDesk{
    display: block;
  }

  .room-types-list {
    overflow: hidden;
  }

  @media (max-width:1024px){
    .btnMob{
      display: block !important;
    }

    .btnDesk{
      display: none !important;
    }
  }

  @media (max-width:1030px) {
    .btnMob{
    
    position: absolute !important;
  }
    
  }

  @media only screen and (min-width: 1024px) {
    h3 {
      font-size: 18px !important;
      margin-top: 32px !important;
      margin-bottom: 32px !important;
    }

    div.active {
      z-index: 5 !important;
    }

    .environment-options {
      margin: auto 4vw !important;
    }

    .logo-cria {  
      max-width: 8vw;
    }

  }

  @media only screen and (min-width: 1200px) {
    h3 {
      font-size: 24px !important;
      margin-top: 32px !important;
      margin-bottom: 32px !important;
    }
  }

  h3 {
    font-size: 13px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.fontColor};
    width: 100%;
  }

  div.environment-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    overflow-x: scroll;
  }
 
  .active {
    z-index: 4;
  }

  .slider {
    transition: transform 0.3s linear;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  button.button-back, 
  button.button-next {
    position: absolute;
    top: 60%;
    margin-top: -20px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.25);
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    color: rgb(133 133 133);
    background-color: ${(props) => props.backgroundColor};
  }

  button.button-back:disabled, 
  button.button-next:disabled {
    display: none;
  }

  button.button-back:hover, 
  button.button-next:hover {
    opacity: .8;  
  }

  button.button-back {
    left: 2vw;
  }
 
  button.button-next {
    right: 2vw;
  }

  .notAuthorizedClass div div:first-child:after {
    color: black;
    font-family: "Font Awesome 5 Free";
    content: '\f023';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    top: 0;
    width: 100%;
    font-weight: 600;
    border-radius: 0px;
    background: #a9a9a96e;
    font-size: 18px;
    color: #000000;
  }

  @media(max-width:1024px){
    
    .notAuthorizedClass div div div{
      position: absolute;
      right: 0;
      left: 0;
      top: 22px;
      margin: auto;
    }
  }

  .notAuthorizedClass {
    // pointer-events:none;
    opacity: 0.5;
  }

  .buttonLock {
    margin-top: 35px;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 9%;
  }

  .buttonLock button {
    font-weight: 500;
    color: white;
    background-color: #00000066;
    padding: 5px 15px;
    border: 0;
    border-radius: 20px;
    color: #ffffffd1;
    cursor: pointer
  }

  .buttonLock button:hover {
    background-color: black;
    transition: 0.2s
  }

  .loadingClass div {
    width: 100px !important;
    height: 100px !important;
    color: ${(props) => props.brandColor} !important;
  }

  .modalPlanoClass h4{
    color: rgb(72 72 72);
  }

  .modalPlanoClass a{
    color: black !important;
    text-decoration: underline;
  }

  .notAuthClass{
    opacity: .5;
  }

  .notAuthClass > div:first-child:after {
    display: flex;
    font-weight: 600;
    background: #ffffff6e;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0px;
    font-family: "Font Awesome 5 Free";
    content: '\f023';
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #000000;
  }

  .buttonLock2 {
    width: 100%;
    text-align: center;
    margin-top: -34%;
    position: relative;
  }

  .buttonLock2 button {
    font-weight: 500;
    color: white;
    background-color: #00000066;
    padding: 5px 15px;
    border: 0;
    border-radius: 20px;
    color: #ffffffd1;
    cursor: pointer
  }

  .buttonLock2 button:hover {
    background-color: black;
    transition: 0.2s
  }

  .modalClasse > div:first-child{
    left: 0;
    text-align: center;
    width: 30%;
    margin-left: 35%;
    z-index:4;
  }

  @media (max-width: 1024px){
    .modalClasse > div:first-child{
      width: 90%  !important;
      margin-left: 5% !important;
    }
  }

  .modalClasse a{
    color: ${(props) => props.brandColor} !important;
  }

  .modalClasse .fundo{
    z-index: 3;
    background: #00000040;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  .uwaEW img, .uwaEW *{
    border-radius: 0 !important;
  }

  .descricaoModelo {
    box-shadow: 0px 1px 5px 1px #0000000f;
    padding: 10px 50px 10px 10px;
    width: 95.7%;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: -22px;
    margin-bottom: 15px;
    height: 53px;
    max-height: 53px;
    color: #9E8E75;
    position: relative;
    cursor: pointer;
  }

  .descricaoModelo i {
    font-size: 15px;
    top: 0;
    height: 100%;
    right: 20px;
    position: absolute;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .titulo-1{
    font-size:20px;
    font-weight:500;
    margin-top: 10px;
}

.titulo-2{
    font-size:30px;
    font-weight:700;
}

.titulo-3{
    font-size:20px;
    color: #9E8E75
}

.descricao{
    text-align: center;
    font-size:14px;
    margin:16px;
}

.input-container{
    width:15%
}

.input-container input, .content input{
    width:100%;
    margin: 5px 0 5px 0;
    height:38px !important;
    border: 0;
    padding: 0 10px;
    border-radius: 0 !important;
}

.content input:hover{
    
}

.submit-button{
    width: 15%;
    margin-top:20px;
    border:0;
    background-color:#404040;
    color: white;
    padding:10px;
    cursor:pointer;
}

@media only screen and (max-width: 1700px) {
    .submit-button{
        width: 20%;
    }

    .input-container{
        width:20%
    }
}

.submit-button:hover{
    transition:0.2s;
    background-color:#606060;
}

.esqueci{
    font-weight:bold;
    font-size:12px;
    margin-top:20px;
    cursor:pointer;
}

.cadastro{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(133, 142, 125, 0.5), rgba(133, 142, 125, 0.5)),url(https://i.ibb.co/98Vch40/064a8d4aba61357dec5b7914f3a3e4bd.jpg);
    width: 40%;
    background-size: 100% auto;
    padding: 30px 0;
    background-position-y: 65%;
    margin-top: 30px;
    transform: scaleX(-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1367px) {
    .cadastro{
        width: 50%;
        padding: 15px 0;
    }
}

.cadastro .content{
    transform: scaleX(-1);
    color: white
}

.cadastro .content .frase-1{
    font-size:18px;
}

.cadastro .content .frase-2{
    margin: 5px 0;
    opacity:0.9
}

.cadastro .content button{
    width:45%;
    padding: 10px;
    background-color: #858E7D;
    color:white;
    border:0;
    margin-top: 10px
}

.cadastro .content button:hover{
    background-color: #9ca396;
    transition:0.2s
}

.buttons-div{
    border-radius: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    background-color: #484848 !important;
}

.dMazrD div.content input:focus-within, .dMazrD div.content input:hover{
    border: 1px solid rgba(20,20,20,0.2) !important;
}

.dMazrD div.content h3{
    color: #525252;
}

.error{
    color: #c53434;
}

.spin{
    margin: 8px;
}

@media only screen and (max-width: 1124px) {
    .submit-button{
        width: 40%;
    }

    .input-container{
        width:40%
    }

    .cadastro {
        width: 50%;
        padding: 15px 30px;
    }
}

@media only screen and (max-width: 600px) {
    .submit-button{
        width: 80%;
    }

    .input-container{
        width:80%
    }

    .cadastro {
        width: 80%;
        padding: 15px 30px;
    }

    .cadastro .content button{
        width: 80%
    }

    .cadastro .content .frase-1{
        font-size: 16px
    }

    .creditos{
        font-size:10px
    }
}

.creditos{
    color: #6C6C6C;
    position: absolute;
    bottom:10px;
}

.center{
    text-align: center
}

.containerAjuste{
    text-align:center;
    width: 100%
}

.logo-cria {  
  max-width: 16vw;
}

button.backbutton {
  background: transparent;
  width: 89px;
  font-size: 14px;
  position: absolute;
  left: 5%;
  color: #726a63;
  border: 0;
}

button.backbutton:hover{
  opacity: .7;
  transition:0.1s;
}
  

.descricao p{
  font-size: 16px
}

.opcoes-class > div > div, .opcoes-class img {
  height: 235px !important;
  width: 310px !important;
}
`