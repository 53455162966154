import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import store from './store'
import App from './App';

import PlaycanvasContext from './components/playcanvasContext'
import Playcanvas from './components/Playcanvas'

ReactDOM.render(
  <React.StrictMode>
    <PlaycanvasContext.Provider value={new Playcanvas()}>
      <Provider store={store}>
        <App />
      </Provider>
    </PlaycanvasContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);