import React from "react";
import { FormControlLabel } from "@material-ui/core";
import { FormControl, FormLabel } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { RadioDiv } from "./styles";
function Filtro(props) {
  const { filter, onChange } = props;
  return (
    <RadioDiv>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={filter}
          onChange={onChange}
        >
          <FormControlLabel
            value="active"
            control={<Radio className="radio-check" />}
            label="Ativos"
          />
          <FormControlLabel
            value="inactive"
            control={<Radio className="radio-check" />}
            label="Inativos"
          />
          <FormControlLabel
            value="all"
            control={<Radio className="radio-check" />}
            label="Todos"
          />
        </RadioGroup>
      </FormControl>
    </RadioDiv>
  );
}

export default Filtro;
