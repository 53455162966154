import * as types from '../types'

const initialState = {
    materials: [],
    isLoading: true,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_MATERIALS_REDUCED_SUCCESS: {
            const newState = { ...state }
            newState.materials = action.payload.data.materials
            newState.isLoading = false
            return newState
        }
        default: {
            return state;
        }
    }
}