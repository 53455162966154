import { call, put, all, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from '../types'
import api from '../../../services/api'

function* socialLoginSubmit({ payload }) {
    try {
        const response = yield call(api.post, 'signin/social/', payload)
        yield put(actions.loginSuccess(response))
    } catch (e) {
        yield put(actions.loginFailure(e))
    }
}

function* loginSubmit({ payload }) {

    try {
        const response = yield call(api.post, 'signin/', payload)
        yield put(actions.loginSuccess(response))
    } catch (e) {
        payload.setErrorMessage('Login ou senha incorretos')
        console.log(e)
        yield put(actions.loginFailure(e))
    }
}


export default all([
    takeLatest(types.LOGIN_SUBMIT, loginSubmit),
    takeLatest(types.SOCIAL_LOGIN_SUBMIT, socialLoginSubmit)
])