import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
  height: 40px;
  width: 100vw;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  z-index: 1;

  @media only screen and (min-width: 768px) {
    bottom: 16px;
    right: 54%;
    margin-right: -150px;
    width: 165px;
    border-radius: 20px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

    a {
      border-radius: 20px 0 0 20px;
    }

    a:hover {
      font-size: 24px;
    }

    a.selected {
      border-top: none !important;
      color: ${props => props.brandColor};
      font-size: 24px;
    }
  }

  a {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: ${props => props.fontColor};
    font-size: 21px;
    cursor: pointer;
  }

  a.selected {
    border-top: 2px solid ${props => props.brandColor};
    color: ${props => props.brandColor};
  }
`