import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  margin-bottom: 0;
  bottom: 0px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-height: ${props => props.display ? "calc(100vh - 120px)" : "0"};
  width: 100vw;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  z-index: 2;
  font-family: 'Montserrat', sans-serif;
  transition: max-height 0.5s ease;

  @media (max-width: 1024px){
    width:100% !important;
    height: 140px !important;
    display:block !important;
    z-index:999999999;

    div.order-title-bar {
      width: 100% !important;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 300px;
    max-height: ${props => props.display ? "calc(100vh - 80px)" : "0"};
    height: fit-content;
    transition: none;
    bottom: 0;

    div.orderby-main-content {
      width: 300px;
    }
  }

  div.order-title-bar {
    display: none;
    box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
    height: 38px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  div.order-title-bar h3 {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }

  div.order-title-bar i {
    font-size: 13px;
    color: ${props => props.fontColor};
  }

  div.order-title-bar i:hover {
    opacity: .8;
    cursor: pointer;
  }

  div.orderby-main-content {
    display: initial !important;
    height: ${props => props.fitContent ? "fit-content" : "calc(100vh - 78px)"};
    font-size: 12px;
    display: flex;
    flex: 1;
  }

  div.orderby-main-content div.option {
    font-size: 14px;
    margin-left: 16px;
    height: 32px;
    align-items: center;
    align-content: center;
    line-height: 32px;
    cursor: pointer;
  }

  div.orderby-main-content div.option:hover {
    opacity: .8;
  }

  div.orderby-main-content div.selected {
    color: ${props => props.brandColor}
  }
`