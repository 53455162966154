import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';

// Styles
import { Container, Text } from './styles'

const MaterialsTable = (props) => {
  const [materialsLoading, setMaterialsLoading] = useState(60)
  const [dataM, setDataM] = useState(props.data);
  const [loadM, setLoadM] = useState(false);
  const [lenghLimiter, setlenghLimiter] = useState(0);

  const onboarding = useSelector(state => state.loginReducer.onboarding)

  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const handleScroll = (element) => {

    if (element.scrollHeight - element.scrollTop < (element.clientHeight + 1)) {
      if (!props.isSearch) {
        if (lenghLimiter != dataM.length) {
          setlenghLimiter(dataM.length);
          setLoadM(true);
          let A = props.getMore(props.groupTitle, true, dataM, materialsLoading, setMaterialsLoading, setLoadM)
          A && setDataM(A);
        }
      }
    }
  }

  const menuOpen = (a) => {
    if (a) {
      document.getElementById("btn-menu-open").classList.add("btn-close");
      document.getElementById("btn-menu-open").classList.remove("btn-open");
      document.getElementById('menu-catalogo').classList.add("open");
      document.getElementById('menu-catalogo').classList.remove("close");
    }
    else {
      document.getElementById("btn-menu-open").classList.remove("btn-close");
      document.getElementById("btn-menu-open").classList.add("btn-open");
      document.getElementById('menu-catalogo').classList.add("close");
      document.getElementById('menu-catalogo').classList.remove("open");
    }

  }

  return (
    <>
      {dataM.length <= 0 ? (
        <Text fontColor={fontColor}>Nenhum material encontrado.</Text>
      ) : (
        <Container
          id="contentM"
          onScroll={(e) => handleScroll(e.target)}
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          className={dataM.slice(0, materialsLoading).length > 18 ? "" : "less"}
        >
          {dataM.map((item, index) => (
            <button
              key={index}
              className={
                onboarding === 6 && index === 0
                  ? "list__item active"
                  : "list__item"
              }
              onClick={() => {
                props.onMaterialClick(item);
                menuOpen(false);
              }}
            >
              <img
                className="list__item__img"
                loading="lazy"
                src={item.miniThumbPath ? item.miniThumbPath : item.thumbPath}
                alt={item.description}
              />
              <h2 className="list__item__title">{item.description}</h2>
              <p className="list__item__text">{item.brand}</p>
              <p className="list__item__text">
                {item.format === "Sem Formato" ? "" : `${item.format} `}
                {item.surface.abbreviation}
              </p>
            </button>
          ))}
          {loadM && (
            <button className="list__item itemLoad">
              <CircularProgress />
            </button>
          )}
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>

          {/* {window.innerWidth <= 1024 &&
            <div className="buttonsPage">
              {props.page != 1 &&
                <>
                  <button onClick={() => props.selectGroup(props.groupTitle, false)}><font>Voltar página</font> <i className="fas fa-angle-left"></i></button>
                  <span>Página {props.page}</span>
                </>
              }
              {props.lenghFilt === 60 &&
                <button onClick={() => props.selectGroup(props.groupTitle, true)} className={props.page === 1 ? "first" : ""}><font>Proxima página</font> <i className="fas fa-angle-right"></i></button>
              }

            </div>
          } */}
        </Container>
      )}
    </>
  );
}

export default MaterialsTable