import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import { useSelector } from 'react-redux'

import Routes from './routes/index'
import GlobalStyle from './styles/global'
import { persistor } from './store'

import { useState } from 'react';

const App = () => {
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const [ready, setReady] = useState(false);

  // useEffect(() => {

  //   // Include the Crisp code here, without the <script></script> tags
  //   window.$crisp = [];
  //   window.CRISP_WEBSITE_ID = "f9419b17-31e4-4e58-bb56-49284d9909a8";

  //   (function () {
  //     var d = document;
  //     var s = d.createElement("script");

  //     s.src = "https://client.crisp.chat/l.js";
  //     s.async = 1;
  //     d.getElementsByTagName("head")[0].appendChild(s);
  //   })();

  //   ReactGA.initialize('UA-126220624-4')

  // }, [])

  // if (!ready) {
  //   setTimeout(function () {
  //     if (document.getElementsByClassName('crisp-client')[0])
  //       document.getElementsByClassName('crisp-client')[0].style.display = "block";
  //   }, 5000);
  //   setReady(true);
  // }

  return (
    <>
      <PersistGate persistor={persistor}>
        <Routes />
        <GlobalStyle backgroundColor={backgroundColor} brandColor={brandColor} />
      </PersistGate>
    </>
  )
}

export default App;
