import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

import NavBar from "./components/NavBar";
import Ambientes from "./components/Ambientes";
import Materias from "./components/Materiais";

import { PageContainer } from "./components/Ambientes/styles";
function Admin() {
  const [ambientes, setAmbientes] = useState(true);
  const [materiais, setMateriais] = useState(false);

  return (
    <PageContainer>
      <NavBar
        setAmbientes={setAmbientes}
        setMateriais={setMateriais}
        ambientes={ambientes}
        materiais={materiais}
      ></NavBar>
      {ambientes && <Ambientes visible={ambientes}></Ambientes>}
      {materiais && <Materias visible={materiais}></Materias>}
    </PageContainer>
  );
}

export default Admin;
