import React, { useContext, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

// Styles
import { Container } from './styles'
import InputMask from 'react-input-mask';
import FormControl from '@material-ui/core/FormControl'
import Switch from "react-switch";
import api from "../../../../services/api";

import { contractContext } from  "../../../../Contexts/contractContext";

import { DebounceInput } from "react-debounce-input";

import Dropdown from 'react-dropdown'

const UserData = (props) => {

  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const [selectedUser, setSelectedUser] = useState(props.selectedUser)
  const { currentContract, setCurrentContract } = useContext(contractContext)

  const [name, setName] = useState('')
  const [status, setStatus] = useState('active')
  const [editing, setEditing] = useState('create')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [login, setLogin] = useState('')
  const [type, setType] = useState('')
  const [password, setPassword] = useState('')
  const [passwordCheck, setPasswordCheck] = useState('')

  const [errorValidateMessage, setErrorValidateMessage] = useState('')

  const [validLogin, setValidLogin] = useState(true)
  const [validEmail, setValidEmail] = useState(true)
  const [validForm, setValidForm] = useState(false)

  const [displaySaveMsg, setDisplaySaveMsg] = useState(false)
  
  const clearUserData = () => {
    setName('')
    setStatus('active')
    setEmail('')
    setPhone('')
    setType('professional')
    setLogin('')
    setPassword('')
    setEditing('create')
    setPasswordCheck('')
  }

  useEffect(() => {
  
    clearUserData()

    if (selectedUser){
      setName(selectedUser.name)
      setStatus(selectedUser.status)
      setEmail(selectedUser.email)
      setPhone(selectedUser.phone)
      setLogin(selectedUser.login)
      setType(selectedUser.type)
      //setPassword(selectedUser.password)
      setEditing(selectedUser.editing)
    }

  }, [selectedUser])

  useEffect(() => {
    
    setSelectedUser(props.selectedUser)

  }, [props.selectedUser])

/*
  useEffect(() => {
        
    if (selectedUser)
      validateForm()

  }, [name, status, editing, email, phone, login, password, passwordCheck])
*/

  const validateLogin = async() => {

    if (!login){
      setValidLogin(true)      
      validForm && validEmail && setErrorValidateMessage('')
      return true
    }      

    if (!selectedUser || (selectedUser && login != selectedUser.login)){
      
      try {

        const respLogin = await api.get('user/login/' + login)
        
        if (respLogin.data.success){
          setValidForm(false)    
          setErrorValidateMessage("Login já existe. Tente outro.")
          return false
        }

      } catch(error){
        setValidLogin(true)
        validForm && validEmail && setErrorValidateMessage('')
        return true
      }      
      
    }
  }

  useEffect(() => {

    validateLogin()

  }, [login])

  const validateEmailSintaxe = () => {
    const mailformat = /^\w*(\.\w*)?@\w*\.[a-z]+(\.[a-z]+)?$/
    const isValid = email.match(mailformat)
    return isValid
  }

  const validateEmail = async () => {

    if (email){

      if (!validateEmailSintaxe()){
        setValidEmail(false)
        setValidForm(false)
        setErrorValidateMessage('E-mail inválido')
        return false
      }

      if (!selectedUser || (selectedUser && selectedUser.email != email))
      {
        /*
        api.get('user/email/' + email)
        .then(() => {
          setValidForm(false)
          setErrorValidateMessage('E-mail já cadastrado')
        })
        .catch(() => {
          setValidEmail(true)
          setErrorValidateMessage('')
        })
        */

        try{
          
          const respEmail = await api.get('user/email/' + email)

          if (respEmail.data.success){
            setValidForm(false)
            setErrorValidateMessage('E-mail já cadastrado')          
            return false
          }

        } catch(error){
          setValidEmail(true)
          validForm && validLogin && setErrorValidateMessage('')
          return true
        }        

      }
      else
      {
        setValidEmail(true)
        validForm && validLogin && setErrorValidateMessage('')
        return true
      }
    }
    else
    {
      setValidEmail(true)
      
      validForm && validLogin && setErrorValidateMessage('')
        
      return true
    }
    
  }

  useEffect(() => {

    validateEmail()

  }, [email])

  const handleStatusChange = (value) => {
    setStatus(value ? "active" : "inactive")
  }

  const validateForm = async() => {
    
    if (!name){
      setErrorValidateMessage('Nome deve ser preenchido')
      setValidForm(false)
      return false
    }

    /*
    if (!email){
      setErrorValidateMessage('E-mail deve ser preenchido')
      setValidForm(false)
      return false
    }
    */
  
    if (!type){
      setErrorValidateMessage('Tipo do usuário deve ser preenchido')
      setValidForm(false)
      return false
    }

    if (phone.length < 13){
      setErrorValidateMessage('Fone deve ser preenchido corretamente')
      setValidForm(false)
      return false
    }

    if (!login){
      setErrorValidateMessage('Login deve ser preenchido')
      setValidForm(false)
      return false
    }

    if (login.includes('@')){
      setErrorValidateMessage('Login não pode ser um e-mail')
      setValidForm(false)
      return false
    }

    if (password != passwordCheck){
      setErrorValidateMessage('Senha e Confirmação de Senha devem ser iguais')
      setValidForm(false)
      return false
    }

    if (password.length < 3 && editing == 'create'){
      setErrorValidateMessage('Senha deve ao menos 3 caracteres')
      setValidForm(false)
      return false
    }

    if (await validLogin &&  await validEmail){
      setErrorValidateMessage('')    
      setValidForm(true)
      return true
    }

    setValidForm(false)
    return false
    
  }

  const handleSaveUserButton = async(value) => {
    
    //debugger

    await validateEmail()
    await validateLogin()

    const validHelper = await validateForm()

    if (!validHelper)
      return  

    let user = {
      name: name,
      status: status,
      email: email,
      phone: phone,
      type: type,
      login: login
    }

    if (password)
      user.password = password

    let resp = ''

    if (editing == 'update'){
      resp = await api.put(`user/${selectedUser.id}`, user)
    }
    else
    {
      user.type = "professional"

      resp = await api.post('signup/', user)

      if (resp && resp.data.success) {
          const respAddUser = await api.post(`contract/${currentContract._id}/user`, {user: resp.data.id})
      }
    }

    if (resp.data.success){

      setTimeout(() => {
        setDisplaySaveMsg(false)  
      }, 2000);

      api.get(`contract/${currentContract._id}`).then(({ data }) => {setCurrentContract(data.contract)})

      setDisplaySaveMsg(true)
      setValidLogin(true)
      setValidEmail(true)
      setValidForm(false)
      clearUserData()
      props.onCloseClick()
    }            
 
  }

  const typeOptions = [
    { value: 'professional', label: 'Vendedor' },
    { value: 'lead', label: 'Lead' },
    { value: 'manager', label: 'Gerente' },    
  ]

  return (
      <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>

        <FormControl className="nome-item">
            <label>Nome</label>
            <input type="text" value={name} onChange={e => setName(e.target.value)} required />
          </FormControl>

          <FormControl className="user-data-items-left">
            <label>E-mail</label>
            {/* <input type="text" value={email} onChange={e => setEmail(e.target.value)} /> */}
            <DebounceInput minLength={2} debounceTimeout={300} onChange={(e) => setEmail(e.target.value)} value={email} />
          </FormControl>

          <FormControl className="status user-data-items-left">
            <label>Ativo</label>
            <Switch onChange={handleStatusChange} checked={status == 'active'} />
          </FormControl>

          <br/>
          <br/>

          <FormControl className="">
            <label>Fone</label>
            <InputMask mask="5599999999999" type="tel" value={phone} placeholder="(xx) 00000-0000" onChange={e => setPhone(e.target.value)} />
          </FormControl>

          <FormControl className="user-data-items-left">
            <label>Tipo</label>
            <Dropdown className="dropTipos" options={typeOptions} onChange={e => setType(e.value)} value={type} placeholder="Tipo" />
          </FormControl>          

          <br/>
          <br/>

          <FormControl className="">
            <label>Login</label>
            {/* <input type="text" value={login} onChange={e => setLogin(e.target.value)} /> */}
            <DebounceInput minLength={2} debounceTimeout={300} onChange={(e) => setLogin(e.target.value)} value={login} />
          </FormControl>

          <FormControl className="user-data-items-left">
            <label>Senha</label>
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />          
          </FormControl>

          <FormControl className="user-data-items-left">
            <label>Confirmação Senha</label>
            <input type="password" value={passwordCheck} onChange={e => setPasswordCheck(e.target.value)} />          
          </FormControl>

          <div>
            <label className="error-validate">{errorValidateMessage}</label>
            <label className="save-msg-label" style={{display: displaySaveMsg ? 'block' : 'none' }}>Usuário salvo</label>
            <button className="save-user-btn" onClick={handleSaveUserButton}>Salvar</button>
            <button className="cancel-user-btn" onClick={() => props.onCloseClick()}>Cancelar</button>
          </div>
          
      </Container>    
  )
}

export default UserData