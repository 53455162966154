import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import api from "../../../../../../services/api";
// Styles
import { Container, Cell, Loading, LoadingContainer } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const MaterialsFilter = (props) => {
  const [colors, setColors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [formats, setFormats] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);
  const [loadingBrand, setloadingBrands] = useState(true);
  const [loadingColors, setloadingColors] = useState(true);
  const [loadingFormats, setloadingFormats] = useState(true);
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  useEffect(() => {
    api
      .get(
        `material/contract/${props.contractId}/${props.currentCategory}/color`
      )
      .then((data) => {
        setloadingColors(false);
        setColors(data.data.colors);
      });
    api
      .get(
        `material/contract/${props.contractId}/${props.currentCategory}/brand`
      )
      .then((data) => {
        setloadingBrands(false);
        setBrands(data.data.brands);
      });
    api
      .get(
        `material/contract/${props.contractId}/${props.currentCategory}/format`
      )
      .then((data) => {
        setloadingFormats(false);
        setFormats(data.data.format);
      });
  }, []);

useEffect(() => {
 console.log(brands)
}, [brands]);

  /*  useEffect(() => {
    if (props.filters && props.materials) {
      const filtered = props.materials.materials.filter(
        (m) =>
          (props.filters.color.length > 0 && currentTab !== 1
            ? props.filters.color.includes(m.color)
            : true) &&
          (props.filters.format.length > 0 && currentTab !== 3
            ? props.filters.format.includes(m.format)
            : true) &&
          (props.filters.brand.length > 0 && currentTab !== 2
            ? props.filters.brand.includes(m.brand) 
            : true)
      );
      
      const brands = filtered.map(item => item.brand)
      const formats = filtered.map(item => item.format)
      const colors = filtered.map(item => item.color)

      const filteredBrands = brands.filter((v, i) => brands.indexOf(v) === i)
      const filteredFormats = formats.filter((v, i) => formats.indexOf(v) === i)
      const filteredColors = colors.filter((v, i) => colors.indexOf(v) === i)

      setBrands(filteredBrands)
      setFormats(filteredFormats)
      setColors(filteredColors)

    } else {
      
    }
  }, [props.filters, currentTab]); */

  const handleSelectColor = (color) => {
    const alreadySelected = props.filters.color.findIndex(
      (item) => item === color
    );

    if (alreadySelected >= 0) {
      const filteredItems = props.filters.color.filter(
        (item) => item !== color
      );
      props.setFilters({
        ...props.filters,
        color: filteredItems,
      });
    } else {
      props.setFilters({
        ...props.filters,
        color: [...props.filters.color, color],
      });
    }
  };

  const handleSelectBrand = (brand) => {
    const alreadySelected = props.filters.brand.findIndex(
      (item) => item === brand
    );

    if (alreadySelected >= 0) {
      const filteredItems = props.filters.brand.filter(
        (item) => item !== brand
      );
      props.setFilters({
        ...props.filters,
        brand: filteredItems,
      });
    } else {
      props.setFilters({
        ...props.filters,
        brand: [...props.filters.brand, brand],
      });
    }
  };

  const handleSelectFormat = (format) => {
    const alreadySelected = props.filters.format.findIndex(
      (item) => item === format
    );

    if (alreadySelected >= 0) {
      const filteredItems = props.filters.format.filter(
        (item) => item !== format
      );
      props.setFilters({
        ...props.filters,
        format: filteredItems,
      });
    } else {
      props.setFilters({
        ...props.filters,
        format: [...props.filters.format, format],
      });
    }
  };

  return (
    <>
      {loadingBrand || loadingColors || loadingFormats ? (
        <LoadingContainer>
          <Loading>
            <CircularProgress />
          </Loading>
        </LoadingContainer>
      ) : (
        <>
          <Container
            modal={props.modal}
            backgroundColor={backgroundColor}
            brandColor={brandColor}
            fontColor={fontColor}
          >
            <nav className="navbar">
              <i
                className="fas fa-arrow-left"
                onClick={() => props.setModal(false)}
              ></i>
              <h1>Filtros</h1>
            </nav>

            <div className="filter-nav-bar">
              <div
                onClick={() => setCurrentTab(1)}
                className={currentTab == 1 ? "selected" : ""}
              >
                <h3>COR</h3>
              </div>
              <div
                onClick={() => setCurrentTab(2)}
                className={currentTab == 2 ? "selected" : ""}
              >
                <h3>MARCA</h3>
              </div>
              <div
                onClick={() => setCurrentTab(3)}
                className={currentTab == 3 ? "selected" : ""}
              >
                <h3>FORMATO</h3>
              </div>
            </div>

            {currentTab == 1 && (
              <div className="general-filter">
                <h1 className="section__title">Cor</h1>
                <ul className="section__list">
                  {colors.map((item) => (
                    <li key={item}>
                      <button
                        className={
                          props.filters.color.includes(item)
                            ? "filter-tag selected"
                            : "filter-tag"
                        }
                        onClick={(e) => handleSelectColor(item)}
                      >
                        {item}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {currentTab == 2 && (
              <div className="brands-filter">
                <h1 className="section__title">Marca</h1>
                <ul className="section__list">
                  {brands.map((item) => (
                    <li key={item}>
                      <button
                        className={
                          props.filters.brand.includes(item)
                            ? "filter-tag selected"
                            : "filter-tag"
                        }
                        onClick={(e) => handleSelectBrand(item)}
                      >
                        {item}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {currentTab == 3 && (
              <div className="size-filter">
                <h1 className="section__title">Formato</h1>
                <ul className="section__list">
                  {formats.map((item) => (
                    <li key={item}>
                      <button
                        className={
                          props.filters.format.includes(item)
                            ? "filter-tag selected"
                            : "filter-tag"
                        }
                        onClick={(e) => handleSelectFormat(item)}
                      >
                        {item}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <Cell
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              brandColor={brandColor}
            >
              {props.filters.color.length > 0 ||
              props.filters.brand.length > 0 ||
              props.filters.format.length > 0 ? (
                <button
                  className="replace-button"
                  onClick={() =>
                    props.setFilters({
                      color: [],
                      brand: [],
                      format: [],
                    })
                  }
                >
                  Limpar filtros (
                  {props.filters.color.length +
                    props.filters.brand.length +
                    props.filters.format.length}
                  )
                </button>
              ) : (
                ""
              )}
              <button
                className="new-environment-button"
                onClick={() => {
                  props.setModal(false);
                  props.apply();
                }}
              >
                Aplicar filtros
              </button>
            </Cell>
          </Container>
        </>
      )}
    </>
  );
};

export default MaterialsFilter;
