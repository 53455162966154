import * as types from '../types'

const initialState = {
    orders: [],
    isLoading: true,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_ORDERS_SUCCESS: {
            const newState = { ...state }
            newState.orders = action.payload.data.orders
            newState.isLoading = false
            return newState
        }
        default: {
            return state;
        }
    }
}