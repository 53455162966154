import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 182px);
  background-color: transparent;
  z-index: 4;
  padding: 16px;
  margin: auto;
  border-radius: 0px;

  div.loader {
    place-self: center;
    border: 5px solid white;
    border-top: 5px solid #9ca396;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export const InputText = styled.input`
  width: 200px;
  height: 15px;
`;

export const PageContainer = styled.div`
  .page-title {
    text-align: center;
    color: black;
    margin: 24px;
  }
`;

export const HeaderAmbientes = styled.div`
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
  height: 54px;
  position: relative;
  text-align: right;
  margin: 0px 24px;
  .icone-cadastro {
    font-weight: bold;
    cursor: pointer;
    color: #228b22;
    font-size: 1.5rem;
    margin: auto;
  }
  .text-header {
    margin: auto;
    color: black;
    font-weight: 550;
    font-size: 0.9rem;
  }
  .radio-check {
    &.Mui-checked {
      color: #404040;
    }
  }
`;

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: auto;
  background-color: #ededed;
  border: 1px solid #000;
`;

export const ModalHeader = styled.div`
  margin: 24px 12px 12px 12px;
  .modal-title {
    text-align: center;
    font-size: 1.5rem;
  }
`;

export const ModalButoons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 24px;
  gap: 12px;
  .button-modal {
    background-color: #4b4a4acc;
    width: 104px;
    height: 32px;

    align-items: center;
    border-style: hidden;
    color: #ffffff;
  }
  .button-modal:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
