import styled from "styled-components";

export const NavCadastro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  .button-cadastro {
    border: 0px;
  }
`;

export const Button = styled.button`
  background-color: #4b4a4acc;
  width: 104px;
  height: 32px;

  align-items: center;
  border-style: hidden;
  color: #ffffff;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const ButtonBack = styled.button`
  background-color: transparent;
  border-style: hidden;
`;

export const Forms = styled.form`
  padding: 16px;
  height: calc(100vh - 220px);
  overflow-y: auto;
  .button-file {
    border-style: hidden;
    margin: 12px auto;
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  gap: 100px;
  margin-bottom: 24px;
  width: 70vw;
  .div-tags{
    width: 500px;
  }
  .input-text {
    width: 280px;
  }
  .input-text-canvas-id {
    width: 100px;
  }
  .input-text-description {
    width: 380px;
  }
  .input-select {
    width: 280px;
  }
  .input-arq-ambiente {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: 320px;
  }
`;
export const Input = styled.input`
  border-radius: 0px;
`;

export const ImgContainer = styled.div`
  height: 220px;
  width: 198px;
  display: flex;
  text-align: center;
  gap: 20px;
  flex-direction: column;
  margin-right: 20px;
  .label-img {
    margin-bottom: 12px;
  }
`;
export const Img = styled.img`
  width: 198px;
  height: 150px;
  object-fit: cover;
`;

export const Table = styled.div`
  margin: 36px 0px;
  height: 300px;
  max-height: 350px;
`;
export const Group = styled.div`
  display: grid;
  grid-template-columns: 0.85fr 6fr;
`;

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 220px);
  background-color: transparent;
  z-index: 4;
  padding: 16px;
  margin: auto;
  border-radius: 0px;
  div.loader {
    place-self: center;
    border: 5px solid white;
    border-top: 5px solid #9ca396;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 4s linear infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export const FormContainer = styled.div`
  padding: 24px;
`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 8px;
`;
