import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import InputMask from "react-input-mask";

import { Container } from "./styles";

import { saveAs } from "file-saver";

import api from "../../../../services/api";

import MaterialCard from "./components/MaterialCard";

import { customerContext } from "../../../../Contexts/customerContext";

import ModalBackground from "../../../../components/ModalBackground";

import GenericModal from "../../../../components/GenericModal";

const SceneMaterialList = (props) => {
  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const [showLoading, setShowLoading] = useState(false);

  const [materialList, setMaterialList] = useState([])

  useEffect(() => {

    setMaterialList([])

    props.data.forEach(m => {
      api.get(`/material/${m.material}`).
        then(m => setMaterialList(materialList => [...materialList, m.data.material]))
    })

  }, [props.data])

  const print = () => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const pri = iframe.contentWindow;
    let conteudo = document.getElementById('material-list').innerHTML;

    pri.document.open();
    pri.document.write('<html><header><style>');
    pri.document.write('body{font-family:"Montserrat",sans-serif; display: block; color: #707070} .title{width:100%; text-align:center; font-size:20px} img{width: 136px; padding: 10px; height: 136px;} ul { width: 40%; display: inline-flex; } h4{ font-weight: 100; font-size: 13px} h3{font-size:16px} ')
    pri.document.write('</style></header>');
    pri.document.write('<body><div class="title">Lista de materiais utilizados</div>');
    pri.document.write(conteudo);
    pri.document.write('</body></html>');
    pri.document.close();
    pri.focus();
    pri.print();
    pri.onafterprint = () => { document.body.removeChild(iframe); }
  }

  return (
    <Container
      display={props.display ? "flex" : "none"}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
      brandContrastColor={brandContrastColor}
    >
      <i
        onClick={() => props.onCloseClick()}
        className="fas fa-times close"
      ></i>
      <h1 className="title">
        Sua lista de materiais utilizados
      </h1>
      <div className="material-list" id="material-list">
        {materialList.length > 0 ?
          materialList.map((mat) => (
            <MaterialCard
              material={mat}
            >
            </MaterialCard>
          ))
          :
          <h3 className="empty-list">
            Nenhum material utilizado no ambiente
          </h3>
        }
      </div>
      <button onClick={() => print()}>Imprimir</button>
    </Container>
  );
};

export default SceneMaterialList;
