import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { ButtonGroup, FormControl } from "@material-ui/core";
import { Fields, Form, Button } from "./styles.js";

const isCordenada = (text) => {
  return /^\(-?\d+(.\d+)?,-?\d+(.\d+)?,-?\d+(.\d+)?\)$/g.test(text);
};

const isNum = (text) => {
  return /^\d+$/g.test(text);
};

const HELPER_TEXT = {
  num: "Deve ser escrito apenas números",
  cord: "Deve ser escrito no seguindo o exemplo (11.5 , 19.3, -150.3 )",
};
const FormCamera = (props) => {
  const { value, setCamera, setCameras, setCadastro, cameras, getRows } = props;
  const [newCamera, setNewCamera] = useState(value);
  const [errors, setErrors] = useState({
    sequence: false,
    rotation: false,
    position: false,
    FOV: false,
    mobilePosition: false,
    mobileRotation: false,
    mobileFOV: false,
  });

  const validationHandler = (event) => {
    const { name, value } = event.target;

    if (name.includes("FOV") || name === "sequence") {
      setErrors((prevErr) => ({ ...prevErr, [name]: !isNum(value) }));
    } else {
      setErrors((prevErr) => ({ ...prevErr, [name]: !isCordenada(value) }));
    }
  };
  const handlerOnChange = (event) => {
    const { name, value } = event.target;
    setNewCamera((prevCamera) => ({
      ...prevCamera,
      [name]: value,
    }));
  };

  const handlerSaveCamera = () => {
    if (value.sequence === "") {
      setCameras((prevCameras) => [...prevCameras, newCamera]);
    } else {
      cameras[newCamera.id] = {
        sequence: newCamera.sequence,
        rotation: newCamera.rotation,
        position: newCamera.position,
        FOV: newCamera.FOV,
        mobilePosition: newCamera.mobilePosition,
        mobileRotation: newCamera.mobileRotation,
        mobileFOV: newCamera.mobileFOV,
      };
      setCameras(cameras);
      getRows();
    }
  };
  const isAllSave = () => {
    return !Object.keys(newCamera)
      .map((key) => newCamera[key])
      .includes("");
  };

  return (
    <>
      <Form>
        <h2 className="Title">Nova câmera</h2>
        <Fields>
          <FormControl className="input-text">
            <TextField
              required
              error={errors.sequence}
              onBlur={validationHandler}
              label="Sequência"
              name="sequence"
              helperText={errors.sequence ? HELPER_TEXT.num : ""}
              onChange={handlerOnChange}
              value={newCamera.sequence}
            ></TextField>
          </FormControl>
          <FormControl className="input-text">
            <TextField
              required
              error={errors.position}
              onBlur={validationHandler}
              label="Posição"
              name="position"
              onChange={handlerOnChange}
              helperText={errors.position ? HELPER_TEXT.cord : ""}
              value={newCamera.position}
            ></TextField>
          </FormControl>
          <FormControl className="input-text">
            <TextField
              required
              error={errors.rotation}
              onBlur={validationHandler}
              label="Rotação"
              name="rotation"
              onChange={handlerOnChange}
              helperText={errors.rotation ? HELPER_TEXT.cord : ""}
              value={newCamera.rotation}
            ></TextField>
          </FormControl>
        </Fields>
        <Fields>
          <FormControl className="input-text">
            <TextField
              required
              error={errors.FOV}
              onBlur={validationHandler}
              label="FOV"
              name="FOV"
              helperText={errors.FOV ? HELPER_TEXT.num : ""}
              onChange={handlerOnChange}
              value={newCamera.FOV}
            ></TextField>
          </FormControl>
          <FormControl className="input-text">
            <TextField
              required
              error={errors.mobilePosition}
              onBlur={validationHandler}
              label="Posição Mobile"
              name="mobilePosition"
              onChange={handlerOnChange}
              helperText={errors.mobilePosition ? HELPER_TEXT.cord : ""}
              value={newCamera.mobilePosition}
            ></TextField>
          </FormControl>
          <FormControl className="input-text">
            <TextField
              required
              error={errors.mobileRotation}
              onBlur={validationHandler}
              label="Rotação Mobile"
              name="mobileRotation"
              onChange={handlerOnChange}
              helperText={errors.mobileRotation ? HELPER_TEXT.cord : ""}
              value={newCamera.mobileRotation}
            ></TextField>
          </FormControl>
          <FormControl className="input-text">
            <TextField
              required
              error={errors.mobileFOV}
              onBlur={validationHandler}
              label="mobile FOV"
              name="mobileFOV"
              onChange={handlerOnChange}
              helperText={errors.mobileFOV ? HELPER_TEXT.num : ""}
              value={newCamera.mobileFOV}
            ></TextField>
          </FormControl>
        </Fields>
        <Fields>
          <div className="input-button">
            <Button
              type="button"
              onClick={() => {
                setCadastro(false);
              }}
            >
              Voltar
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (
                  !Object.keys(errors)
                    .map((key) => errors[key])
                    .includes(true) &&
                  isAllSave()
                ) {
                  handlerSaveCamera();
                  setCadastro(false);
                }
              }}
            >
              Salvar
            </Button>
          </div>
        </Fields>
      </Form>
    </>
  );
};

export default FormCamera;
