import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'


const FilterBar = (props) => {
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    if (!props.mainContent) return null

    return (
        <Container brandColor={brandColor} fontColor={fontColor}>
            {props.mainContent}
        </Container>
    )
    
}

export default FilterBar