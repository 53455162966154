import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Styles
import { Container, Button, ButtonGroup } from './styles'
import InputMask from 'react-input-mask';
import FormControl from '@material-ui/core/FormControl'

import Dropdown from 'react-dropdown'
import GenericModal from "../../components/GenericModal";
import ModalBackground from "../../components/ModalBackground";
import 'react-dropdown/style.css'
import RegisterData from './RegisterData';
import Users from './Users';
import Rooms from './Rooms'
import Materials from './Materials'

import * as contractActions from '../../store/modules/contract/actions'
import api from "../../services/api";

import { contractContext } from "../../Contexts/contractContext";

import Loader from '../../components/Loader';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Contracts = (props) => {

  const user = useSelector(state => state.loginReducer.user)

  const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const User = useSelector((state) => state.loginReducer.user);

  const dispatch = useDispatch()

  const contracts = useSelector(state => state.contractReducer.contracts.data)
  const isContractsLoading = useSelector(state => state.contractReducer.contracts.isLoading)

  const loggedContract = useSelector(state => state.contractReducer.contract)
  const isLoggedContractLoading = useSelector(state => state.contractReducer.contract.isLoading)

  const [sessaoPrincipal, setSessaoPrincipal] = useState(props.sessao)
  const [contractList, setContractList] = useState([])

  //const [currentContract, setCurrentContract] = useState(useSelector(state => state.contractReducer.contract))
  const [currentContract, setCurrentContract] = useState()

  const [initicialContractId, setIniticialContractId] = useState(useSelector(state => state.contractReducer.contract.contractId));

  const [logochanged, setLogochanged] = useState(false)

  const [contractRooms, setContractRooms] = useState([])

  const [isEditing, setIsEditing] = useState(true)

  const [displaySaveMsg, setDisplaySaveMsg] = useState(false)
  const [saveMsg, setSaveMsg] = useState('')

  const [newMaterialList, setNewMaterialList] = useState([])

  const [displayMsgSaveBeforeContinue, setDisplayMsgSaveBeforeContinue] = useState(false)

  const [isLoadingContractData, setIsLoadingContractData] = useState(true);

  let [inputValue, setInputValue] = useState();

  useEffect(() => {

    loadContractData(initicialContractId)

  }, [initicialContractId])

  useEffect(() => {

    if (contracts.length > 0) {
      const listHelper = contracts.map(c => {
        return {
          value: c._id,
          label: c.name
        }
      }).sort(function (a, b) {

        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }

        return 0;
      })

      setContractList(listHelper)
    }

  }, [contracts])

  useEffect(() => {

    isContractsLoading &&
      dispatch(contractActions.getAllContracts(null))

  }, [isContractsLoading])

  /*
  useEffect(() => {
      
    currentContract.contractId && loadContractData(currentContract.contractId)

  }, [currentContract])
*/

  const handleMainSessionClick = (session) => {

    if (!isEditing && currentContract) {
      setDisplayMsgSaveBeforeContinue(true)
      return
    }

    setSessaoPrincipal(session)
  }

  const handleContractChange = (contract) => {
    setIsEditing(true)
    console.log(contract.value)
    loadContractData(contract.value)
  }

  const loadContractData = (contractId) => {

    setIsLoadingContractData(true)
    api.get(`contract/${contractId}`).then(({ data }) => {
      setCurrentContract(data.contract)
      //setContractRooms(data.contract.rooms)      
    })
      .finally(() => setIsLoadingContractData(false))

  }

  const blobToBase64 = (blobUrl) => {
    return new Promise((resolve, _) => {

      var xhr = new XMLHttpRequest;
      xhr.responseType = 'blob';

      xhr.onload = function () {
        var recoveredBlob = xhr.response;

        var reader = new FileReader;

        reader.onloadend = () => resolve(reader.result)

        reader.readAsDataURL(recoveredBlob);
      }

      xhr.open('GET', blobUrl);
      xhr.send();

    })
  }

  const uploadLogo = async (contractId, logoPath) => {

    let logoHelper = await blobToBase64(logoPath)

    logoHelper = logoHelper.replace("data:image/webpbase64,", "")
      .replace("data:image/webp;base64,", "")
      .replace("data:image/pngbase64,", "")
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpgbase64,", "")
      .replace("data:image/jpg;base64,", "")

    return api.post("order/uploadFile", {
      key: `assets/img/logos/${contractId}-${Date.now()}.png`,
      data: logoHelper,
    })

  }

  const handleNewMaterialList = () => {

    const contractMaterials = currentContract.materials.map(m => m.material)

    const added = newMaterialList.filter(m => !contractMaterials.includes(m))
    //console.log('added ', added)

    const removed = contractMaterials.filter(m => !newMaterialList.includes(m))

    return {
      added: added,
      removed: removed
    }

  }

  const saveContract = (pContract) => {

    if (isEditing)
      return api.put(`contract/${pContract._id}`, pContract)

    return api.post('contract/', pContract)

  }

  const handleSaveButton = async () => {

    if (!currentContract.name) {
      setSaveMsg('O Nome/Razão Social deve ser preenchido')
      setDisplaySaveMsg(true)
      return
    }

    if (logochanged) {
      const uploadResp = await uploadLogo(`${currentContract.name}-logo`, currentContract.whiteLabel.logoPath)

      setLogochanged(false)

      //não consegui via setState
      currentContract.whiteLabel.logoPath = uploadResp.data.data.imageSrc

      if (!uploadResp.data.success) {
        setSaveMsg(`Erro ao fazer upload da logo. Mensagem de erro: ${uploadResp.data.status}`)
        setDisplaySaveMsg(true)
        return
      }
    }

    const saveResp = await saveContract(currentContract)

    if (!saveResp.data.success) {
      setSaveMsg(`Erro ao salvar o contrato. Mensagem de erro: ${saveResp.data.status}`)
      return
    }

    setCurrentContract({ contractId: saveResp.data.id })
    setIsEditing(true)

    if (loggedContract.contractId === currentContract.contractId)
      dispatch(contractActions.getContract(User.id))

    if (newMaterialList.length > 0) {
      const materialHelpers = handleNewMaterialList()

      if (materialHelpers.added.length > 0) {

        let addList = []

        const price = "0,00"
        const inStock = 1.0

        materialHelpers.added.forEach((mat, index) => {
          addList.push({
            material: mat, price: price, inStock: inStock
          })
        })

        const respAdd = await api.post(`/contract/${currentContract._id}/addMaterials`, addList)

        addList = []

      }

      if (materialHelpers.removed.length > 0) {

        let removeList = []

        const respRemove = await api.post(`/contract/${currentContract._id}/removeMaterials`, materialHelpers.removed)

      }

    }

    dispatch(contractActions.getAllContracts(null))
    setNewMaterialList([])

    setSaveMsg("Contrato salvo com sucesso!")

    setTimeout(() => {
      setDisplaySaveMsg(false)
    }, 2500)

    setDisplaySaveMsg(true)

  }

  const handleNewContract = () => {
    setIsEditing(false)
    //setContract({})
    setCurrentContract({
      status: 'active'
    })
    setSessaoPrincipal('registerData')
  }

  return (
    <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor} display={!isLoadingContractData}>
      <h1>Dados do Contrato</h1>

      {/* remove manager here */}
      {user.type === "admin" ?
        <FormControl className="contrato-selector">
          <label>Contratos</label>
          <div className="dropAndButton">
            <Autocomplete
              id="combo-box-demo"
              options={contractList}
              onChange={(event, newValue) => {
                if(newValue){
                setIsEditing(true)
                loadContractData(newValue.value)
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              placeholder="Selecione o Contrato"
              getOptionLabel={(option) => option.label}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
            {/* <Dropdown className="dropContratos" options={contractList} onChange={handleContractChange} value={currentContract ? currentContract.name : ""} /> */}
            <button className="btn-novo-contrato" onClick={handleNewContract}>Novo Contrato</button>
          </div>
        </FormControl>
        : ""}

      <div className="botoes-sessao-princial" >
        <button className={`botao-sessao-princial ${sessaoPrincipal === 'registerData' ? "sessao-principal-selecionada" : ""}`} onClick={() => handleMainSessionClick('registerData')}>Dados Cadastrais</button>
        <button className={`botao-sessao-princial ${sessaoPrincipal === 'users' ? "sessao-principal-selecionada" : ""}`} onClick={() => handleMainSessionClick('users')}>Usuários</button>
        <button className={`botao-sessao-princial ${sessaoPrincipal === 'rooms' ? "sessao-principal-selecionada" : ""}`} onClick={() => handleMainSessionClick('rooms')}>Ambientes</button>
        <button className={`botao-sessao-princial ${sessaoPrincipal === 'materials' ? "sessao-principal-selecionada" : ""}`} onClick={() => handleMainSessionClick('materials')}>Materiais</button>
      </div>

      <div className="contrato" >

        {isLoadingContractData ?
          <Loader />
          :
          <>
            <contractContext.Provider value={{ currentContract, setCurrentContract, logochanged, setLogochanged, contractRooms, setContractRooms, setNewMaterialList }}>
              {
                sessaoPrincipal === 'registerData' && <RegisterData />
                ||
                sessaoPrincipal === 'users' && <Users />
                ||
                sessaoPrincipal === 'rooms' && <Rooms />
                ||
                sessaoPrincipal === 'materials' && <Materials />
              }
            </contractContext.Provider>
          </>
        }

      </div>

      {displaySaveMsg && (
        <ModalBackground
          onClick={() => setDisplaySaveMsg(false)}
        ></ModalBackground>
      )}
      <GenericModal
        display={displaySaveMsg}
        onCloseClick={() => setDisplaySaveMsg(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        fontColor={fontColor}
        mainContent={
          <>
            <h3>{"Salvo"}</h3>
            <p></p>
            <p>{"Seu contrato foi salvo com sucesso!"}</p>

          </>
        }
      ></GenericModal>

      {displayMsgSaveBeforeContinue && (
        <ModalBackground
          onClick={() => setDisplayMsgSaveBeforeContinue(false)}
        ></ModalBackground>
      )}
      <GenericModal
        display={displayMsgSaveBeforeContinue}
        onCloseClick={() => setDisplayMsgSaveBeforeContinue(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        fontColor={fontColor}
        mainContent={
          <>
            <h3>{"Novo Contrato"}</h3>
            <p></p>
            <p>{"Salve o novo contrato para continuar o cadastro!"}</p>

          </>
        }
      ></GenericModal>

      <button className="save-btn" onClick={handleSaveButton}>SALVAR CONTRATO</button>
    </Container>
  )
}

export default Contracts