import styled from 'styled-components'

export const Container = styled.div`
/*display: flex;
flow-flex: row wrap;
justify-content: space-around;
align-items: flex-start;
align-content: flex-start;*/
gap: 12px;
width: 100%;
height: 100%;
background-color: ${props => props.backgroundColor};

h1 {
  margin: 24px;
  width: 100%;
  text-align: center;
  color: ${props => props.fontColor};
}

label {
  margin-top: 10px;  
  margin-bottom: 4px;
  margin-left: 4px;
  font-family: 'Montserrat', sans-serif;
  color: ${props => props.fontColor};
  font-weight: bold;
}

input {
  padding: 4px 8px;
  border: lightgrey 1px solid;
  border-radius: 15px;
}

.whiteLabel {
  margin-top: 12px;
}

.whiteLabel-items {
  margin-left: 24px;
}

.logo-img {
  max-width: 120px;
  margin: 8px;
}

.logo-item {
  margin-left: 0px;
}

.logo-btn {
  padding: 0px 12px;
  border-radius: 15px;
  border: none;
  font-size: 12px;
  box-shadow: 0 0.2px 4px rgb(0 0 0 / 10%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: ${props => props.brandColor};
}

.popover {
  position: absolute;
  z-index: 2
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;  
}

.url-item {
  margin-left: 0px;
}

#square-color {
  position: absolute;
    right: 0%;
    transform: translate3d(-80%, 60%, 1px);
    top: 50%;
    box-shadow: lightgrey 1px 1px 3px 1px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.formControl {
  margin: spacing(1);
  minWidth: 120;
}

.select {
  marginTop: spacing(2);
}
`