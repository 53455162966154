export const LOGIN_SUBMIT = 'LOGIN_SUBMIT'
export const SOCIAL_LOGIN_SUBMIT = 'SOCIAL_LOGIN_SUBMIT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const SET_ONBOARDING = 'SET_ONBOARDING'
export const UPDATE_USER_PHONE = 'UPDATE_USER_PHONE'

export const GET_CONTRACT = 'GET_CONTRACT'
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS'
export const GET_ALL_CONTRACTS = 'GET_ALL_CONTRACTS'
export const GET_ALL_CONTRACTS_SUCCESS = 'GET_ALL_CONTRACTS_SUCCESS'
export const GET_CONTRACT_ROOMS = 'GET_CONTRACT_ROOMS'
export const GET_CONTRACT_ROOMS_SUCCESS = 'GET_CONTRACT_ROOMS_SUCCESS'
export const GET_CONTRACT_MATERIALS = 'GET_CONTRACT_MATERIALS'
export const GET_CONTRACT_MATERIALS_SUCCESS = 'GET_CONTRACT_MATERIALS_SUCCESS'
export const GET_CONTRACT_TEMPLATES = 'GET_CONTRACT_TEMPLATES'
export const GET_CONTRACT_TEMPLATES_SUCCESS = 'GET_CONTRACT_TEMPLATES_SUCCESS'

export const GET_CONTRACT_USERS = 'GET_CONTRACT_USERS'
export const GET_CONTRACT_USERS_SUCCESS = 'GET_CONTRACT_USERS_SUCCESS'

export const GET_ALL_MATERIALS_REDUCED = 'GET_ALL_MATERIALS_REDUCED'
export const GET_ALL_MATERIALS_REDUCED_SUCCESS = 'GET_ALL_MATERIALS_REDUCED_SUCCESS'

export const GET_USER_ROOMS = 'GET_USER_ROOMS'
export const GET_USER_ROOMS_SUCCESS = 'GET_USER_ROOMS_SUCCESS'
export const RETURN_USER_ROOMS_INITIAL_STATE = 'RETURN_USER_ROOMS_INITIAL_STATE'

export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'

export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'

export const CHECK_NETWORK_SPEED = 'CHECK_NETWORK_SPEED'
export const HANDLE_NETWORK_SPEED = 'HANDLE_NETWORK_SPEED'

export const GET_ALL_ROOMS = 'GET_ALL_ROOMS'
export const GET_ALL_ROOMS_SUCCESS = 'GET_ALL_ROOMS_SUCCESS'
export const ADD_ROOM = 'ADD_ROOM'
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS'
export const ADD_ROOM_TO_CONTRACTS = 'ADD_ROOM_TO_CONTRACTS'
export const ADD_ROOM_TO_CONTRACTS_SUCCESS = 'ADD_ROOM_TO_CONTRACTS_SUCCESS'
export const EDIT_ROOM = 'EDIT_ROOM'
export const EDIT_ROOM_SUCCESS = 'EDIT_ROOM_SUCCESS'