import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "./components/Form/index";
import Lista from "./components/Lista";
import Filtro from "./components/Filtro";
import { Modal } from "@material-ui/core";
import api from "../../../../services/api";

import * as roomsActions from "../../../../store/modules/rooms/actions";
import {
  PageContainer,
  HeaderAmbientes,
  Button,
  Container,
  ModalContainer,
  ModalHeader,
  ModalButoons,
} from "./styles";

const TYPE_ROOM = {
  description: "",
  thumPath: "",
  mobileThumPath: "",
};
const DEFAULT_ROOM = {
  playCanvas: "1368737",
  title: "",
  description: "",
  thumbPath: "",
  thumbPlanPath: "",
  status: "active",
  tags:[],
  type: TYPE_ROOM,
  urlScene: "",
  cameras: [],
};

const Ambientes = (props) => {
  const dispatch = useDispatch();
  let rooms = useSelector((state) => state.roomsReducer.rooms);
  const loggedContract = useSelector((state) => state.contractReducer.contract);
  useEffect(() => {
    dispatch(roomsActions.getAllRooms(null));
  }, []);

  const [selecinado, setSelecionado] = useState([
    { selecionado: false, room: null },
  ]);
  const [filter, setFilter] = useState("active");
  const [editId, setEditID] = useState(-1);
  const [cadastro, setCadastro] = useState(false);
  const [edit, setEdit] = useState(false);
  const [copy, setCopy] = useState(false);
  const [roomToEdit, setRoomToEdit] = useState(DEFAULT_ROOM);
  const [roomToDelete, setRoomToDelete] = useState(DEFAULT_ROOM);
  const [roomToCopy, setRoomToCopy] = useState(DEFAULT_ROOM);
  const [confirmModalOpen, setconfirmModalOpen] = useState(false);
  const [isRoomOnDelete, setIsRoomOnDelete] = useState(false);
  let isRoomsLoading = useSelector((state) => state.roomsReducer.isLoading);
  const AllTypes = Array.from(
    new Set(
      rooms.map((room) => {
        return room.type.description;
      })
    )
  );

  let types = Array.from(
    new Set(
      rooms.map((room) => {
        if (filter === "all") {
          return room.type.description;
        } else if (room.status === filter) {
          return room.type.description;
        }
      })
    )
  );

  const handlerClose = () => {
    setconfirmModalOpen(false);
  };

  const handlerCopy = (room, ID) => {
    const roomToCopy = {
      playCanvas: "1368737",
      title: room.title,
      description: room.description,
      thumbPath: room.thumbPath,
      thumbPlanPath: room.thumbPlanPath,
      status: "active",
      type: room.type,
      urlScene: room.urlScene,
      cameras: room.cameras,
      tags: room.tags ? room.tags : [],
    };
    setRoomToCopy(roomToCopy);
    setCopy(true);
  };

  const handlerEdit = (room, ID) => {
    setEditID(ID);
    setRoomToEdit(room);
    setEdit(true);
  };

  const handlerCadastro = () => {
    setCadastro(true);
  };

  const handlerOnChangeRadio = (event) => {
    setFilter(event.target.value);
  };

  const handlerDelete = (room) => {
    setRoomToDelete(room);
    setconfirmModalOpen(true);
  };

  const deleteRoom = async () => {
    setIsRoomOnDelete(true);
    const resp = await api
      .delete(`room/delete/${roomToDelete._id}`)
      .catch((err) => {
        console.log(err);
      });

    if (resp.data.success) {
      dispatch(roomsActions.getAllRooms(null));
    }
    setIsRoomOnDelete(false);
    handlerClose();
  };

  return (
    <PageContainer>
      <h1 className="page-title">Cadastro de Ambientes</h1>
      {isRoomsLoading ? (
        <div className="loader">
          <Container>
            <div className="loader"></div>
          </Container>
        </div>
      ) : (
        <>
          {!cadastro && !edit && !copy && (
            <>
              <HeaderAmbientes>
                <Filtro filter={filter} onChange={handlerOnChangeRadio} />

                <Button onClick={() => handlerCadastro()}>
                  <i className="far fa-plus icone-cadastro"></i>
                  <p className="text-header">Novo ambiente</p>
                </Button>
              </HeaderAmbientes>
              <Lista
                types={types}
                rooms={rooms}
                handlerEdit={handlerEdit}
                handlerCopy={handlerCopy}
                handlerDelete={handlerDelete}
                filter={filter}
              />
            </>
          )}
        </>
      )}
      {cadastro && (
        <Form
          types={AllTypes}
          contract={loggedContract}
          value={DEFAULT_ROOM}
          mode={1}
          setFormVisibility={setCadastro}
        ></Form>
      )}
      {edit && (
        <Form
          types={AllTypes}
          contract={loggedContract}
          mode={0}
          value={roomToEdit}
          setFormVisibility={setEdit}
        />
      )}
      {copy && (
        <Form
          types={AllTypes}
          contract={loggedContract}
          mode={1}
          value={roomToCopy}
          setFormVisibility={setCopy}
        />
      )}
      <Modal open={confirmModalOpen} onClose={handlerClose}>
        {isRoomOnDelete ? (
          <div className="loader">
            <Container>
              <div className="loader"></div>
            </Container>
          </div>
        ) : (
          <ModalContainer>
            <ModalHeader>
              <h1 className="modal-title">
                Deseja realmente excluir o ambiente "{roomToDelete.title}"?
              </h1>
            </ModalHeader>
            <ModalButoons>
              <button className="button-modal" onClick={handlerClose}>
                Não
              </button>
              <button className="button-modal" onClick={deleteRoom}>
                Sim
              </button>
            </ModalButoons>
          </ModalContainer>
        )}
      </Modal>
    </PageContainer>
  );
};

export default Ambientes;
