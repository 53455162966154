import React, { useContext, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Styles
import { Container, Text } from './styles'
import 'react-dropdown/style.css'

import * as contractActions from '../../../store/modules/contract/actions'
import * as materialsActions from '../../../store/modules/materials/actions'

import { contractContext } from "../../../Contexts/contractContext";

import Loader from "../../../components/Loader";

const Materials = (props) => {

  const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const { currentContract, setNewMaterialList } = useContext(contractContext)

  const dispatch = useDispatch()

  const isContractMaterialsLoading = useSelector(state => state.contractReducer.contractMaterials.isLoading)

  const materials = useSelector(state => state.materialsReducer.materials)
  const isMaterialsLoading = useSelector(state => state.materialsReducer.isLoading)

  const [brands, setBrands] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])

  const [currentMaterials, setCurrentMaterials] = useState([])
  let [currentMaterialsFiltered, setCurrentMaterialsFiltered] = useState([])

  const [selectAllChecked, setSelectAllChecked] = useState(false)

  const [selectedMaterials, setSelectedMaterials] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {

    if (currentContract.materials) {
      const initialList = currentContract.materials.map(m => m.material)
      setSelectedMaterials(initialList)
    }

  }, [currentContract]);

  useEffect(() => {

    isMaterialsLoading &&
      dispatch(materialsActions.getAllMaterialsReduced(null))

    setIsLoading(isMaterialsLoading)
  }, [isMaterialsLoading])

  useEffect(() => {

    if (materials.length > 0) {

      setBrands([...new Set(
        materials.map(m => m.brand)
          .sort(function (a, b) {
            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          })

      )])
    }
  }, [materials])

  const handleSelectedMaterials = (materialId, checked) => {

    let mHelper = selectedMaterials

    if (checked) {
      if (!mHelper.includes(materialId))
        mHelper.push(materialId)
    }
    else {
      mHelper.splice(mHelper.indexOf(materialId), 1)
    }

    setSelectedMaterials(mHelper)
    setNewMaterialList(mHelper)
  }

  const handleBrandCheck = (target) => {

    const sbHelper = selectedBrands

    target.checked ? sbHelper.push(target.value) && setSelectAllChecked(false)
      : sbHelper.splice(sbHelper.indexOf(target.value), 1)

    const mHelper = materials.filter(m => sbHelper.includes(m.brand)).map(
      m => {
        return {
          id: m._id,
          miniThumbPath: m.miniThumbPath,
          description: m.description,
          brand: m.brand,
          format: m.format,
          isChecked: selectedMaterials.includes(m._id)
        }
      })

   
    mHelper.sort(function (a, b) {
      if (a.description < b.description) {
        return -1;
      } else {
        return true;
      }
    });

    setCurrentMaterials([...new Set(mHelper)])
    setCurrentMaterialsFiltered([...new Set(mHelper)])
  }

  const handleSelectAllMaterials = (target) => {
    let mHelper = [...new Set(currentMaterials)]

    setSelectAllChecked(target.checked)

    mHelper.forEach(m => {
      m.isChecked = target.checked
      handleSelectedMaterials(m.id, target.checked)
    })

    setCurrentMaterials(mHelper)
    setCurrentMaterialsFiltered(mHelper)

  }

  const handleCheckChildElement = (target) => {

    let mHelper = [...new Set(currentMaterials)]

    mHelper.forEach(m => {
      if (m.id == target.value) {
        handleSelectedMaterials(m.id, target.checked)
        return m.isChecked = target.checked
      }
    })

    setCurrentMaterials(mHelper)
    setCurrentMaterialsFiltered(mHelper)

  }

  const testList = () => {

    const contractMaterials = currentContract.materials.map(m => m.material)

    const added = selectedMaterials.filter(m => !contractMaterials.includes(m))
    console.log('added ', added)

    const removed = contractMaterials.filter(m => !selectedMaterials.includes(m))
    console.log('removed ', removed)

  }

  const filterRow = (a) => {
    var list = new Array;

    for (const i of currentMaterials) {
      if (i.description.toLowerCase().includes(a.toLowerCase()) || i.format.toLowerCase().includes(a.toLowerCase())) {
        list.push(i)
      }
    }

    setCurrentMaterialsFiltered(list);
    currentMaterialsFiltered = list;

  }

  return (
    <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>

      <h1>Materiais</h1>
      <div className="lists">

        {isLoading ?
          <Loader
            borderRadius={"15px"}
          />
          : <>
            <div className="brands">
              <h3>Marcas</h3>
              <div className="brand-list">
                {
                  brands.map((b, index) => (
                    <button key={index}>
                      <input type="checkbox" value={b} onChange={e => handleBrandCheck(e.target)} />
                      {b}
                    </button>
                  ))
                }
              </div>
            </div>

            <div className="material-list-header">
              <div className="selecionar-todos-checkbox">

                <form className="form">
                  <label>Pesquisar </label>
                  <input type="text" placeholder="Pesquisar" onChange={event => {
                    filterRow(event.target.value)
                  }} />
                </form>

                <div className="space"></div>

                <div className="form">
                  <input type="checkbox" checked={selectAllChecked} onClick={e => handleSelectAllMaterials(e.target)} />
                  Selecionar todos
                  { /* <button onClick={() => testList()}>TESTE</button> */}
                </div>
              </div>

              {currentMaterialsFiltered.length <= 0 ?
                <Text>Selecione as marcas que deseja incluir no contrato</Text>
                :
                <div className="material-list">
                  {currentMaterialsFiltered.map(m => (
                    <button key={m.id} className="list__item">
                      <img className="list__item__img" loading="lazy" quality={50} src={m.miniThumbPath} alt={m.description} />                      
                      <h2 className="list__item__title">{m.description}</h2>
                      <p className="list__item__text">{m.brand}</p>
                      <p className="list__item__text">{m.format}</p>
                      <input type="checkbox" value={m.id} className="checkbox" onClick={t => handleCheckChildElement(t.target)} checked={m.isChecked} ></input>
                    </button>
                  ))}
                </div>
              }
            </div>
          </>}
      </div>

    </Container>
  )
}

export default Materials