import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: ${props => props.arrowYPosition};
  left: ${props => props.arrowXPosition};
  width: 90%;
  height: auto;
  background-color: ${props => props.backgroundColor};
  border-radius: 0px;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
  display: ${props => props.display};
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  opacity: 1;
  z-index: 4;
  padding: 16px;

  label{
    color: ${props => props.fontColor};
  }

  @media only screen and (min-width: 768px) {
    width: 380px;
    top: 28vh;
    left: 50%;
    margin-left: -190px;
    z-index: 5;

    div.content h3,
    div.content p,
    div.content small.alert,
    div.content input[type=text] {
      width: 348px !important;
    }
  }
  
  div.content h3 {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.brandColor};
    width: calc(88vw - 32px);
    flex: 1;
  }

  div.content p {
    margin-bottom: 12px;
    font-size: 13px;
    color: ${props => props.fontColor};
    width: calc(88vw - 32px);
  }

  div.content small.alert {
    margin: 0 0 12px 8px;
    font-size: 10px;
    font-weight: 500;
    color: red;
    width: calc(88vw - 32px);
    flex: 1;
  }

  div.content input[type=text] {
    margin-top: 4px;
    margin-bottom: 12px;
    border-radius: 12px;
    height: 24px;
    width: 95%;
    background: #fff;
    color: #000;
    font-size: 12px;
    border: 1px solid rgba(20, 20, 20, 0.2);
    padding: 0 12px 0 12px;
    line-height: 24px;
  }

  div.content input:focus-within,
  div.content input:hover {
    border: 1px solid ${props => props.brandColor};
  }

  div.content input[type=radio] {
    margin: 0 0 16px 4px;
    width: 14px;
    height: 14px;
    opacity: .8;
  }

  div.content input.alert {
    border: 1px solid red;
  }

  div.content label {
    margin: 0 8px 16px 4px;
    font-size: 12px;
  }

  div.content button {
    position: relative;
    padding: 0 8px 0 8px;
    width: 110px;
    height: 32px;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    color: ${props => props.backgroundColor};
    background-color: ${props => props.brandColor};
    margin-left: 8px;
  }

  div.content button img {
    width: 16px;
  }

  div.content div.buttons-div {
    display: flex;
    float: right;
  }

  div.content button.alternative-button {
    color: ${props => props.fontColor}!important;
    background-color: ${props => props.backgroundColor}!important;
  }

  i {
    position: absolute;
    right: 16px;
    font-size: 13px;
    color: ${props => props.fontColor};
  }

  div.content button.alternative-button i.whatsapp-icon {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: #2bdd1c;
  }
  
  i:hover,
  button: hover {
    opacity: .8;
    cursor: pointer;
  }
`