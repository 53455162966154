import styled from 'styled-components'

export const Input = styled.div`
  border-radius: 12px;
  height: 24px;
  width: calc(100vw - 24px);
  align-self: center;
  background: #fff;
  color: rgba(20, 20, 20, 0.6);
  border: 1px solid rgba(20, 20, 20, 0.2);
  padding: 0 16px 0 16px;
  margin: 12px 4px;

  @media(max-width: 1024px){
    margin-left: -10% !important;
  }

  div i {
    font-size: 12px;
    color: #222;
    opacity: .5;
  }

  :focus-within,
  :hover {
    border: 1px solid ${props => props.brandColor};
  }

  .input__container {
    align-items:center;
    justify-content: start;
    display: flex;
    height: 100%;
    margin: 0 auto;
    width: 100%;
  }

  input { 
    background:none;
    border: 0;
    height: 100%;
    margin-left: 12px;
    font-family: 'Montserrat',sans-serif;
    font-size: 13px;
    color: #222;
    width: ${props => props.width};
  }

  button {
    background: none;
    border: 0;
    padding: 8px;
  }
`