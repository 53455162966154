import { call, put, all, takeEvery } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from '../types'
import api from '../../../services/api'

function* getUser({ payload }) {
    try {
        const response = yield call(api.get, `/user/${payload}`)
        yield put(actions.getUserSuccess(response))
    } catch (e) {
        console.log(e)
    }
}

export default all([
    takeEvery(types.GET_USER, getUser)
])