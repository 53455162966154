import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: ${props => props.arrowYPosition};
  ${props => props.xPosition ? `left: ${props.xPosition};` : ""}
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    div.modal-card h3 {
      margin-top: 0 !important;
      margin-bottom: 8px !important;
    }
 
    div.modal-card {
      width: 280px !important;
      margin: auto 10px auto 10px !important;
      z-index: 5 !important;
    }

    i.fa-arrow-left,
    i.fa-arrow-up,
    i.fa-arrow-down {
      z-index: 5 !important;
    }
  }

  div.modal-card {
    position: relative;
    box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
    border-radius: 16px;
    width: 88vw;
    margin: auto 6vw auto 6vw;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    justify-content: start;
    padding: 16px;
    height: auto;
    background-color: ${props => props.backgroundColor};
    opacity: 1;
    z-index: 4;
  }

  i.fa-arrow-left {
    position: absolute;
    left: -40px;
    font-size: 32px;
    color: ${props => props.backgroundColor};
    z-index: 4;
    ${props => props.arrowLeft ? "" : "display: none;"}
  }

  i.fa-arrow-up {
    position: relative;
    margin-bottom: 16px;
    left: ${props => props.arrowXPosition};
    font-size: 32px;
    color: ${props => props.backgroundColor};
    z-index: 4;
    ${props => props.arrowUp ? "" : "display: none;"}
  }

  i.fa-arrow-down {
    position: relative;
    margin-top: 16px;
    left: ${props => props.arrowXPosition};
    font-size: 32px;
    color: ${props => props.backgroundColor};
    z-index: 4;
    ${props => props.arrowDown ? "" : "display: none;"}
  }

  div.modal-card div {
    display: flex;
    flex-wrap: no-wrap;
    margin-bottom: 8px !important;
  }

  div.modal-card img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  div.modal-card h3 {
    text-align: start;
    align-self: center;
    margin-top: 0 !important;
    font-size: 14px !important;
    font-weight: 700;
    color: ${props => props.brandColor};
    width: 80vw;
    flex: 1;
  }

  div.modal-card p {
    font-size: 13px;
    color: ${props => props.fontColor};
  }
`