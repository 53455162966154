import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'

// Styles
import { Container } from './styles'

import 'react-dropdown/style.css'
import MainData from './MainData';
import Address from './Address';
import WhiteLabel from './WhiteLabel';

import { contractContext } from "../../../Contexts/contractContext";

const RegisterData = (props) => {

  const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const [sessao, setSessao] = useState('mainData')
  
  const { currentContract, setCurrentContract } = useContext(contractContext)
                           
  const handleSessionClick = (session) => {
    setSessao(session)
  }   

  return (
      <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>

        <div className="dados-contrato" >
            <div className="botoes-sessao-dados-cadastrais" >
              <button className={`botao-sessao botao-dados-cadastrais ${sessao === 'mainData' ? "sessao-selecionada-dados-cadastrais" : "" }`}  onClick={() => handleSessionClick('mainData')}>Principal</button>
              <button className={`botao-sessao botao-endereco ${sessao === 'address' ? "sessao-selecionada-dados-cadastrais" : "" }`} onClick={() => handleSessionClick('address')}>Endereço</button>
              <button className={`botao-sessao botao-cores ${sessao === 'whiteLabel' ? "sessao-selecionada-dados-cadastrais" : "" }`} onClick={() => handleSessionClick('whiteLabel')}>Cores</button>
            </div>
            {
                sessao === 'mainData' && <MainData />
                || 
                sessao === 'address' && <Address />
                ||
                <WhiteLabel />
            }
        </div>
      </Container>    
  )
}

export default RegisterData