import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
  height: auto;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  z-index: 3;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 1024px){
    .scene-materials-css{
      height: 28px !important;
      width: 107px !important;
      font-size: 10px !important;
      padding-left: 0 !important;
      margin-top: 15px !important;
    }
    
    .arrow-left-button i {
      margin-top: 15px !important;
    }
  }

  @media only screen and (min-width: 1024px) {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    z-index: 4;
    justify-content: center;

    div#TopBar {
      display: flex;
      flex: 1;
      flex-wrap: no-wrap;
      height: 55px;
      width: 100vw;
      align-items: start;
      background-color: ${props => props.backgroundColor};
      box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
      justify-content: center !important;
      animation-name: example2;
      animation-duration: 2s;  
      animation-fill-mode: forwards;
      padding: 0px;

      @keyframes example2 {
        from {margin-top: -40px;}
          to {margin-top: 40px;}
      }
    }

    div#TopBar div.expand-button {
      display: none !important;
    }
    
    div#TopBar div.arrow-left-button {
      position: absolute;
      left: 0;
      border-radius: 20px;
    }

    div#TopBar div.text {
      position: absolute;
      right: 0;
    }

    div#TopBar div.scene-materials {
      position: absolute;
      right: 0px;
    }

    div#TopBar div.scene-list {
      display: none;
    }

    div#TopBar div.undo-button {
      position: absolute;
      left: 40px;
      border-radius: 20px;
    }

    div#TopBar div i {
      justify-self: start;
    }


    img {
      display: block !important;
      align-self: center;
    }
  }

  img {
    height: 24px;
    display: block;
  }

  div#TopBar {
    position: absolute;
    display: flex;
    //margin-top: 40px;
    flex: 1;
    flex-wrap: no-wrap;
    height: 55px;
    width: 100vw;
    align-items: start;
    justify-content: space-between;
    background-color: ${props => props.backgroundColor};
    box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
  }

  div#TopBar div {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    font-weight: 700;
  }

  div#TopBar div.text {
    margin-right: 16px;
    font-size: 12px;
    height: auto;
    padding: 8px 12px;
    border-radius: 16px;
    cursor: pointer;
    align-self: center;
    background-color: ${props => props.brandColor};
    color: ${props => props.backgroundColor} !important;
  }

  div#TopBar div i {
    font-size: 18px;
    padding: 8px;
    color: ${props => props.fontColor};
    cursor: pointer;
  }

  div#TopBar div i:hover {
    opacity: .8;
    border-radius: 20px;
  }

  div#TopBar div.selected {
    border-top: 2px solid ${props => props.brandColor};
    color: ${props => props.brandColor};
  }

  div#TopBar div.scene-materials {
    margin-right: 32px;
      font-size: 12px;
      height: auto;
      padding: 8px 12px;
      border-radius: 16px;
      cursor: pointer;
      align-self: center;
  }

  @media only screen and (max-width: 1023px){
    div#TopBar div.scene-materials {
    display: none !important;
  }
  }






  @media (min-width: 1024px){
    .scene-materials-css {
      height: 35px !important;
    }
  }

  .scene-materials-css {
    margin-top: 6px;
    margin-right: 10px;
    font-size: 12px;
    height: auto;
    padding: 8px 12px;
    border-radius: 0px;
    cursor: pointer;
    align-self: center;
    background-color: #9E8E75;
    color: white !important;
    font-weight: 100 !important;
  } 
  

  .scene-materials-css i{
    color: white !important;
    font-size: 13px !important;
  }

  .scene-materials-css {
    position: absolute;
    right: 0px;
  }

  @media (max-width: 1024px){
    .logo-class {
      position: absolute;
      height: 30px !important;
      margin-top: 21px !important;
      left: 0;
      right: 0;
      margin: auto;
    }

    .mobile{
      display:block !important;
    }

    .desktop{
      display:none !important;
    }

    .scene-materials-css img{
      width: 21px !important;
      margin: 0 3px 0 5px !important;
    }
  }

  .scene-materials-css img{
    width: 23px;
    height: initial;
    margin: 0 5px 0 0;
  }

  .mobile{
    display:none
  }

  .desktop{
    display:block
  }
  

  .logo-class {
    height: 30px;
    margin-top: 10px;
  } 

  .arrow-left-button i,
  .undo-button i {
    margin-top: 5px;
  }

`
