import React, { useState, useEffect } from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import api from '../../services/api'
import { useSelector } from 'react-redux'

import { Container, Graficos, Gerar, Label, ModalFilter, OptionsList, TableMaterials, TableAcess } from './styles'

// Components
import SectionTopBar from '../../components/SectionTopBar'
import NavigationBar from '../../components/NavigationBar'
import { CardBody, Table } from 'reactstrap'


const Dashboard = () => {

  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)

  // Variáveis que vão armazenar o estado da página (Se o gráfico deverá ser mostrado ou não)
  const [displayAccess, setDisplayAccess] = useState(false);
  const [displayOrders, setDisplayOrders] = useState(true);
  const [displayMaterials, setDisplayMaterials] = useState(false);

  // Variáveis que vão armazenar informação se a API carregou ou não (Loading)
  const [access, setAccess] = useState();
  const [orders, setOrders] = useState();
  const [usedMaterials, setUsedMaterials] = useState();
  let [listUsedMaterials, setListUsedMaterials] = useState();
  let [listAccess, setListAccess] = useState();
  let [recent, setRecent] = useState([]);

  // Variáveis que vão armazenar o total das somar para o campo de quantidade
  const [countOrders2, setCountOrders2] = useState(0);
  const [countOrdersYear, setCountOrdersYear] = useState(0);
  const [countAccessYear, setCountAccessYear] = useState(0);
  let [countInitiatedOrder, setCountInitiatedOrder] = useState(0);
  let [countWhatsApp, setCountWhatsApp] = useState(0);

  // Variáveis que vão armazenar informação se a API carregou ou não (Loading)
  const [loadingAccess, setLoadingAccess] = useState(true)
  const [loadingOrders, setLoadingOrders] = useState(true)
  const [loadingOrdersYear, setLoadingOrdersYear] = useState(true)
  const [loadingMaterilas, setLoadingMaterials] = useState(true)
  const [loadingAccessYear, setLoadingAccessYear] = useState(true)
  const [loadRecent, setLoadRecent] = useState(true);

  // Variáveis que vão armazenar os arrays dos contracts
  const [dateInitial, setateInitial] = useState();
  const [dateFinal, setDateEnd] = useState();
  const [yearPast, setYearPast] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().substr(0, 10));
  const [yearPresent, setYearPresent] = useState(new Date().toISOString().substr(0, 10));
  const [ready, setReady] = useState(false);

  let [pieDataOrder, setpieDataOrder] = useState([]);
  let [listMore, setListMore] = useState([]);
  let [openFiltro, setOpenFiltro] = useState(false);
  let [dataPerMonth, setDataPerMonth] = useState([]);
  let [dataPerMonthAccess, setDataPerMonthAccess] = useState([]);
  let [dateYearInitialF, setDateYearInitialF] = useState(new Date().getFullYear() - 1);
  let [dateYearFinalF, setDateYearFinalF] = useState(new Date().getFullYear());
  let [dateInitalF, setDateInitalF] = useState();
  let [dateFinalF, setDateFinalF] = useState();

  let [labelYear, setLabelYear] = useState([]);

  let [date1, setdate1] = useState();
  let [date2, setdate2] = useState();
  let [date3, setdate3] = useState();
  let [date4, setdate4] = useState();

  let [animationVar, setanimationVar] = useState(1000);
  let [animationPVar, setanimationPVar] = useState(1000);


  const User = useSelector((state) => state.loginReducer.user);

  const contractId = useSelector(state => state.contractReducer.contract.contractId);

  const sodja = useSelector(state => state.contractReducer.contract.initiatedOrders);

  console.log(sodja)

  const createDefaultdateInitial = () => {
    let curr = new Date();
    curr.setDate(curr.getMonth() <= 12 ? "1" : "");
    setdate1(convertDateFormat(curr, true));
    let date = curr.toISOString().substr(0, 10);
    setdate3(date);
    setateInitial(date)

    return date
  }

  const createDefaultDateFinal = () => {
    let curr = new Date();
    curr.setDate(curr.getDate());
    setdate2(convertDateFormat(curr, true));
    let date = curr.toISOString().substr(0, 10);
    setdate4(date);
    setDateEnd(date)

    return date
  }

  const toDate = (dateStr) => {
    if (!dateStr)
      return

    var parts = dateStr.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`
  }

  const getApiData = (contractId, url, initialDate, finalDate) => {
    return api
      .get(`contract/${contractId}/${url}?start=${toDate(initialDate)}&end=${toDate(finalDate)}`)
  }

  const renderAccessYear = (dateInitial, dateFinal) => {
    if (dataPerMonthAccess.length === 0) {
      setLoadingAccessYear(false)
      api
        .get(`contract/${contractId}/access?start=${toDate(dateInitial)}&end=${toDate(dateFinal)}`)
        .then(({ data }) => {
          setAccess(data)
          setLoadingAccessYear(true)

          var qtd = 0;

          for (const i of data.access) {
            for (const j of i.accessDate) {
              var dataVar = new Date(j.accessDate);
              var now = new Date();
              var month = dataVar.getMonth() + (11 - now.getMonth());
              if (dataPerMonthAccess[month] > 0) {
                dataPerMonthAccess[month] = dataPerMonthAccess[month] + 1;
              } else {
                dataPerMonthAccess[month] = 1;
              }
              qtd = qtd + 1;
            }
          }
          setCountAccessYear(qtd)
          for (var i = 0; i < dataPerMonthAccess.length; i++) {
            if (dataPerMonthAccess[i]) {
            } else {
              dataPerMonthAccess[i] = 0;
            }
          }

        })
        .catch((err) => console.log(err))
    }
  }

  const renderAccess = (dateInitial, dateFinal) => {
    setLoadingAccess(false)
    api
      .get(`contract/${contractId}/access?start=${toDate(dateInitial)}&end=${toDate(dateFinal)}`)
      .then(({ data }) => {
        setAccess(data)
        setLoadingAccess(true)

        for (const i of data.access) {
          var a = i.accessDate[i.accessDate.length - 1]
          if (a)
            i.lastAccess = convertDateFormat(a.accessDate);
        }

        setListAccess(data.access)
        listAccess = data.access

        ordernarAccess();

      })
      .catch((err) => console.log(err))
  }

  const ordernarAccess = () => {
    listAccess.sort(function (a, b) {
      if (a.accessDate.length < b.accessDate.length) return 1;
      if (a.accessDate.length > b.accessDate.length) return -1;
      return 0;
    });
  }

  const renderOrdersYear = (dateInitial, dateFinal) => {
    if (dataPerMonth.length === 0) {
      setLoadingOrdersYear(false)
      api
        .get(`contract/${contractId}/orders?start=${toDate(dateInitial)}&end=${toDate(dateFinal)}`)
        .then(({ data }) => {
          setOrders(data)
          setLoadingOrdersYear(true)
          setTimeout(function () { setanimationVar(0) }, 1000);

          for (const j of data.orders) {
            for (const i of j.order) {
              var dataVar = new Date(i.createdAt);
              var now = new Date();
              var month = dataVar.getMonth() + (11 - now.getMonth());
              if (dataPerMonth[month] > 0) {
                dataPerMonth[month] = dataPerMonth[month] + 1;
              } else {
                dataPerMonth[month] = 1;
              }
            }
          }

          for (var i = 0; i < dataPerMonth.length; i++) {
            if (dataPerMonth[i]) {
            } else {
              dataPerMonth[i] = 0;
            }
          }
        })
        .catch((err) => console.log(err))
    }
  }

  const getLabelYear = () => {
    var now = new Date().getMonth();
    var count = now;
    var stop = null;
    var where = count + 1;
    for (let i = 11; i >= 0; i--) {
      switch (count) {
        case 0:
          labelYear[i] = "JAN";
          break;
        case 1:
          labelYear[i] = "FEV";
          break;
        case 2:
          labelYear[i] = "MAR";
          break;
        case 3:
          labelYear[i] = "ABR";
          break;
        case 4:
          labelYear[i] = "MAI";
          break;
        case 5:
          labelYear[i] = "JUN";
          break;
        case 6:
          labelYear[i] = "JUL";
          break;
        case 7:
          labelYear[i] = "AGO";
          break;
        case 8:
          labelYear[i] = "SET";
          break;
        case 9:
          labelYear[i] = "OUT";
          break;
        case 10:
          labelYear[i] = "NOV";
          break;
        case 11:
          labelYear[i] = "DEZ";
          break;

        default:
      }
      count--;

      if (count < 1 && !stop) {
        stop = i;
      }
    }

    for (var i = 0; i <= stop; i++) {
      switch (where) {
        case 0:
          labelYear[i] = "JAN";
          break;
        case 1:
          labelYear[i] = "FEV";
          break;
        case 2:
          labelYear[i] = "MAR";
          break;
        case 3:
          labelYear[i] = "ABR";
          break;
        case 4:
          labelYear[i] = "MAI";
          break;
        case 5:
          labelYear[i] = "JUN";
          break;
        case 6:
          labelYear[i] = "JUL";
          break;
        case 7:
          labelYear[i] = "AGO";
          break;
        case 8:
          labelYear[i] = "SET";
          break;
        case 9:
          labelYear[i] = "OUT";
          break;
        case 10:
          labelYear[i] = "NOV";
          break;
        case 11:
          labelYear[i] = "DEZ";
          break;

        default:
      }
      where++;
    }
  }

  const renderOrders = (dateInitial, dateFinal) => {
    setLoadingOrders(false)
    setLoadRecent(false)
    setanimationPVar(1000)
    api
      .get(`contract/${contractId}/orders?start=${toDate(dateInitial)}&end=${toDate(dateFinal)}`)
      .then(({ data }) => {
        setOrders(data)
        setLoadingOrders(true)
        setListMore(data.orders);
        listMore = data.orders;
        setTimeout(function () { setanimationPVar(0) }, 1000);

        console.log(data);

        setCountInitiatedOrder(data.qtdInitiaded);
        countInitiatedOrder = data.qtdInitiaded;

        setCountWhatsApp(data.qtdWhatsapp);
        countWhatsApp = data.qtdWhatsapp;

        var list = [];
        var qtd = 0;

        for (var i = 0; i < 4; i++)
          pieDataOrder[i] = 0;

        for (const j of data.orders) {
          for (const i of j.order) {
            switch (i.status) {
              case "open":
                if (pieDataOrder[0]) {
                  pieDataOrder[0] = pieDataOrder[0] + 1;
                } else {
                  pieDataOrder[0] = 1;
                }
                break;

              case "closed":
                if (pieDataOrder[1]) {
                  pieDataOrder[1] = pieDataOrder[1] + 1;
                } else {
                  pieDataOrder[1] = 1;
                }
                break;

              case "cancelled":
                if (pieDataOrder[2]) {
                  pieDataOrder[2] = pieDataOrder[2] + 1;
                } else {
                  pieDataOrder[2] = 1;
                }
                break;
              default:
                break;
            }
            i.data = convertDateFormat(i.createdAt);
            list.push(i);
            qtd = qtd + 1;
          }

        }
        list.sort(function (a, b) {
          var dt1 = new Date(a.createdAt);
          var dt2 = new Date(b.createdAt);
          if (dt1.getTime() < dt2.getTime()) return 1;
          if (dt1.getTime() > dt2.getTime()) return -1;
          return 0;
        });
        setRecent(list);
        setLoadRecent(true);
        setCountOrders2(qtd);
      })
      .catch((err) => console.log(err))
  }

  const ordernar = () => {
    listMore.sort(function (a, b) {
      if (a.orderCount < b.orderCount) return 1;
      if (a.orderCount > b.orderCount) return -1;
      return 0;
    });
  }

  const convertDateFormat = (date, notajust, str) => {
    var dateNew = new Date(date);

    if (notajust) {
      var day = '' + (dateNew.getDate());
      var month = '' + (dateNew.getMonth() + 1);
    } else {
      var day = '' + (dateNew.getDate());
      var month = '' + (dateNew.getMonth() + 1);
    }
    var year = dateNew.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    dateNew = day + "/" + month + "/" + year;
    return dateNew;
  }

  const renderMaterials = (dateInitial, dateFinal) => {
    setLoadingMaterials(false)
    api
      .get(`contract/${contractId}/usedmaterials?start=${toDate(dateInitial)}&end=${toDate(dateFinal)}`)
      .then(({ data }) => {
        setUsedMaterials(data)
        setLoadingMaterials(true)


        var lista = data.materials;

        let counts = lista.reduce((acc, curr) => {
          const str = JSON.stringify(curr);
          acc[str] = (acc[str] || 0) + 1;
          return acc;
        }, {});

        let set = new Set;
        let res = lista.filter(x => {
          const str = JSON.stringify(x);
          return !set.has(str) && set.add(str);
        });

        for (const i of res) {
          i.materialCount = counts[JSON.stringify(i)];
        }

        setListUsedMaterials(res);
        listUsedMaterials = res;

        ordenarLista();

      })
      .catch((err) => console.log(err))
  }

  const ordenarLista = () => {
    listUsedMaterials.sort(function (a, b) {
      if (a.materialCount < b.materialCount) return 1;
      if (a.materialCount > b.materialCount) return -1;
      return 0;
    });
  }

  useEffect(() => {
    const dInicial = createDefaultdateInitial()
    const dFinal = createDefaultDateFinal()

    setateInitial(dInicial)
    setDateEnd(dFinal)
  }, [])

  useEffect(() => {
    renderDashboard()
  }, [dateInitial, dateFinal])

  const renderDashboard = () => {
    if (dateInitial && dateFinal && contractId) {
      if (displayAccess) {
        renderAccess(dateInitial, dateFinal);
        renderAccessYear(yearPast, yearPresent);
      } else if (displayOrders) {
        renderOrders(dateInitial, dateFinal);
        renderOrdersYear(yearPast, yearPresent);
      } else {
        renderMaterials(dateInitial, dateFinal)
      }
    }

    setDateInitalF(convertDateFormat(dateInitial));
    setDateFinalF(convertDateFormat(dateFinal));
    getLabelYear();
  }

  // Função para mostrar o gráfico ao clicar no botão
  const showAccess = (dateInitial, dateFinal) => {
    setDisplayOrders(false)
    setDisplayMaterials(false)
    setDisplayAccess(true)
    renderAccess(dateInitial, dateFinal)
    renderAccessYear(yearPast, yearPresent)
  }

  const showOrders = (dateInitial, dateFinal) => {
    setDisplayAccess(false)
    setDisplayMaterials(false)
    setDisplayOrders(true)
    renderOrders(dateInitial, dateFinal)
  }

  const showMaterials = (dateInitial, dateFinal) => {
    setDisplayAccess(false)
    setDisplayOrders(false)
    setDisplayMaterials(true)
    renderMaterials(dateInitial, dateFinal)
  }

  const graphicAccess = () => {
    let data = ""
    if (access) {
      data = {
        labels: labelYear,
        datasets: [
          {
            label: "Acessos",
            data: dataPerMonthAccess,
            backgroundColor: "rgba(6, 196, 131, 0.4)",
            borderColor: "rgba(6, 196, 131, 1)",
            borderWidth: 1,
          },
        ]
      }
    }
    return data
  };

  const graphicOrders = () => {
    let data = "";


    if (orders) {
      data = {
        labels: labelYear,
        datasets: [
          {
            data: dataPerMonth,
            label: "Atendimentos",
            fill: true,
            backgroundColor: "#0067ff30",
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4
          },
        ]
      }
    }
    return data
  };

  const graphicMaterials = () => {
    let data = ""
    if (usedMaterials) {
      data = {
        labels: usedMaterials.materials.map(a => a.description),
        datasets: [
          {
            label: "Materiais",
            data: usedMaterials.materials.map(a => a.materialCount),
            backgroundColor: 'rgba(2, 12, 234, 0.4)',
            borderColor: 'rgba(2, 12, 234, 1)',
            borderWidth: 1,
          },
        ]
      }
    }
    return data
  };

  const pizzaOrders = () => {
    let data = ""
    data = {
      labels: ["Em aberto", "Venda concluida", "Venda perdida"],
      datasets: [
        {
          data: pieDataOrder,
          backgroundColor: ["#ffc04b", "#45a145", "#e15858"]
        },
      ]
    }
    return data
  };

  let chart1_2_options = {
    maintainAspectRatio: false,
    animation: {
      duration: animationVar
    },
    plugins: {
      legend: {
        display: false
      }
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  const FilterDate = () => {
    setateInitial(date3);
    setDateEnd(date4);

    var d1 = new Date(new Date(date3));
    d1.setDate(d1.getDate() + 1);
    var d2 = new Date(new Date(date4));
    d2.setDate(d2.getDate() + 1);

    setdate1(convertDateFormat(d1));
    setdate2(convertDateFormat(d2));

    setReady(false);
  }

  var x = window.matchMedia("(max-width: 1366px)");

  return (
    <> 
      <SectionTopBar title={"relatorios"}></SectionTopBar>
      <Container fontColor={fontColor}>
        <Graficos>

          <OptionsList backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>
            <div onClick={() => showOrders(dateInitial, dateFinal)} className="option">
              <i className="fas fa-list-ul"></i>
              <h3>Atendimentos</h3>
            </div>
            <div onClick={() => showAccess(dateInitial, dateFinal)} className="option">
              <i className="fas fa-sign-in-alt"></i>
              <h3>Acessos</h3>
            </div>
            <div onClick={() => showMaterials(dateInitial, dateFinal)} className="option">
              <i className="far fa-clone"></i>
              <h3>Materiais</h3>
            </div>
          </OptionsList>

          <div className="grafico-container">
            <Label className="label" fontColor={fontColor}>{displayAccess ? 'Acessos' : displayOrders ? 'Atendimentos' : displayMaterials ? 'Materiais' : ''}</Label>

            {displayOrders &&
              <>
                {/* <div className="header">
                  <h5>Atendimentos</h5>
                </div> */}
                <div className="painel">
                  <h6 className="painel-h6">Total de Atendimentos: <font> {dateYearInitialF} - {dateYearFinalF}</font></h6>

                  <CardBody>

                    {loadingOrdersYear ? displayOrders &&
                      <Line
                        data={graphicOrders}
                        options={chart1_2_options}
                      />
                      :
                      <div className="loading">
                        <div className="loader"></div>
                      </div>
                    }
                  </CardBody>

                  <br />

                  <h6 className="dataGerais">
                    <font className="dataTitle">
                      Atendimentos do Período:
                      <p className={openFiltro ? "data-hidden" : "data-visible"}>{date1} - {date2}</p>
                    </font>
                    <button onClick={() => setOpenFiltro(!openFiltro)}>{!openFiltro ? <i class="fas fa-filter"></i> : <i class="fas fa-times"></i>}</button>
                    <div className={openFiltro ? "filtros-class filtros-true" : "filtros-class filtros-false"}>
                      <label>Data Inicial</label>
                      <form className="form">
                        <input type="date" value={date3} onChange={event => setdate3(event.target.value)} />
                      </form>
                      <label>Data Final</label>
                      <form className="form">
                        <input type="date" value={date4} onChange={event => setdate4(event.target.value)} />
                      </form>
                      <div className="form">
                        <button onClick={FilterDate}>Filtrar</button>
                      </div>
                    </div>
                  </h6>

                  <div className="more-painels">
                    <div className="painel-class recentes">
                      {loadRecent ? displayOrders &&
                        <>
                          <h6>Atendimentos</h6>
                          <div className="totalizadores">
                            <div className="totalizadores-item type-2"><p><i class="fas fa-clock"></i> {countInitiatedOrder}</p> Atendimentos iniciados</div>
                            <div className="totalizadores-item type-2"><p><i class="fas fa-poll"></i> {countOrders2}</p> Atendimentos concluídos</div>
                            <div className="totalizadores-item type-1"><p><i class="fab fa-whatsapp"></i> {countWhatsApp}</p> Mensagem por whatsapp</div>
                          </div>
                        </>
                        :
                        <div className="loading">
                          <div className="loader"></div>
                        </div>
                      }
                    </div>
                    <div className="painel-class pizza">
                      {loadingOrders ? displayOrders &&
                        <>
                          <h6>Situação dos Atendimentos</h6>
                          <div className="pie">
                            <Pie
                              height={x.matches ? 180 : 280}
                              width={x.matches ? 180 : 280}
                              options={{
                                animation: {
                                  duration: animationPVar
                                },
                                plugins: {
                                  legend: {
                                    position: "bottom",
                                    labels: {
                                      fontSize: 10,
                                    },
                                  }
                                }

                              }}
                              data={pizzaOrders}
                            />
                          </div>
                        </>
                        :
                        <div className="loading">
                          <div className="loader"></div>
                        </div>
                      }
                    </div>

                    <div className="painel-class mais">
                      {loadingOrders ? displayOrders &&
                        <>
                          <h6>Atendimentos Concluídos</h6>
                          <div className="table-class">
                            <Table hover borderless>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Nome</th>
                                  <th className="Quantidade">Quantidade</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ordernar()}
                                {listMore.map((a, b) => (
                                  <>
                                    <tr>
                                      <th className="iconAjust">{b === 0 ? <i class="fas fa-medal gold"></i> : b === 1 ? <i class="fas fa-medal silver"></i> : b === 2 ? <i class="fas fa-medal bronze"></i> : <div>{b + 1}º</div>}</th>
                                      <th>{a.name}</th>
                                      <th className="Quantidade">{a.orderCount}</th>
                                    </tr>
                                  </>
                                ))}


                              </tbody>
                            </Table>
                          </div>
                        </>
                        :
                        <div className="loading">
                          <div className="loader"></div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </>
            }


            {displayAccess &&
              <>
                <div className="painel">
                  <h6 className="painel-h6">Total de Acessos: <font>{dateYearInitialF} - {dateYearFinalF}</font></h6>
                  <CardBody>

                    {loadingAccessYear ? displayAccess &&
                      <Line
                        data={graphicAccess}
                        options={chart1_2_options}
                      />
                      :
                      <div className="loading">
                        <div className="loader"></div>
                      </div>
                    }
                  </CardBody>




                  <h6 className="dataGerais margin-class-top-10">
                    <font className="dataTitle">
                      Acessos do Período:
                      <p className={openFiltro ? "data-hidden" : "data-visible"}>{date1} - {date2}</p>
                    </font>
                    <button onClick={() => setOpenFiltro(!openFiltro)}>{!openFiltro ? <i class="fas fa-filter"></i> : <i class="fas fa-times"></i>}</button>
                    <div className={openFiltro ? "filtros-class filtros-true" : "filtros-class filtros-false"}>
                      <label>Data Inicial</label>
                      <form className="form">
                        <input type="date" value={date3} onChange={event => setdate3(event.target.value)} />
                      </form>
                      <label>Data Final</label>
                      <form className="form">
                        <input type="date" value={date4} onChange={event => setdate4(event.target.value)} />
                      </form>
                      <div className="form">
                        <button onClick={FilterDate}>Filtrar</button>
                      </div>
                    </div>
                  </h6>

                  <TableAcess>

                    <div className="painel-class mais">
                      {loadingAccess ? displayAccess &&
                        <>
                          <h6>Contas Mais Acessadas</h6>
                          <div className="table-class">

                            <Table hover borderless>
                              <thead>
                                <tr>
                                  <td>#</td>
                                  <td>Descrição</td>
                                  <td>Ultimo Acesso</td>
                                  <td className="Quantidade">Quantidade</td>
                                </tr>
                              </thead>
                              <tbody>
                                {listAccess &&
                                  <>
                                    {ordernarAccess()}
                                    {listAccess.map((a, b) => (
                                      <tr>
                                        <td>{b === 0 ? <i class="fas fa-medal gold"></i> : b === 1 ? <i class="fas fa-medal silver"></i> : b === 2 ? <i class="fas fa-medal bronze"></i> : <div>{b + 1}º</div>}</td>
                                        <td>{a.name}</td>
                                        <td>{a.lastAccess}</td>
                                        <td className="Quantidade">{a.accessDate.length}</td>
                                      </tr>
                                    ))}
                                  </>
                                }
                              </tbody>
                            </Table>

                          </div>
                        </>
                        :
                        <div className="loading">
                          <div className="loader"></div>
                        </div>
                      }
                    </div>

                  </TableAcess>
                </div>

              </>
            }



            {displayMaterials &&
              <>
                <div className="painel painel-materials">
                  <h6 className="dataGerais">
                    <font className="dataTitle">
                      Materiais do Período:
                      <p className={openFiltro ? "data-hidden" : "data-visible"}>{date1} - {date2}</p>
                    </font>
                    <button onClick={() => setOpenFiltro(!openFiltro)}>{!openFiltro ? <i class="fas fa-filter"></i> : <i class="fas fa-times"></i>}</button>
                    <div className={openFiltro ? "filtros-class filtros-true" : "filtros-class filtros-false"}>
                      <label>Data Inicial</label>
                      <form className="form">
                        <input type="date" value={date3} onChange={event => setdate3(event.target.value)} />
                      </form>
                      <label>Data Final</label>
                      <form className="form">
                        <input type="date" value={date4} onChange={event => setdate4(event.target.value)} />
                      </form>
                      <div className="form">
                        <button onClick={FilterDate}>Filtrar</button>
                      </div>
                    </div>
                  </h6>
                  {/* <CardBody>
                    <Bar data={graphicMaterials} options={chart1_2_options} />
                  </CardBody> */}
                  <TableMaterials>
                    <div className="painel-class mais">
                      {loadingMaterilas ? displayMaterials &&
                        <>
                          <h6>Materiais Mais Usados</h6>
                          <div className="table-class">

                            <Table hover borderless>
                              <thead>
                                <tr>
                                  <td>#</td>
                                  <td>Descrição</td>
                                  <td className="Quantidade">Quantidade</td>
                                </tr>
                              </thead>
                              <tbody>
                                {listUsedMaterials &&
                                  <>
                                    {ordenarLista()}
                                    {listUsedMaterials.map((a, b) => (
                                      <>
                                        <tr>
                                          <td>{b === 0 ? <i class="fas fa-medal gold"></i> : b === 1 ? <i class="fas fa-medal silver"></i> : b === 2 ? <i class="fas fa-medal bronze"></i> : <div>{b + 1}º</div>}</td>
                                          <td>{a.brand} - {a.description} {a.format} {a.surface.description}</td>
                                          <td className="Quantidade">{a.materialCount}</td>
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                }
                              </tbody>
                            </Table>

                          </div>
                        </>
                        :
                        <div className="loading">
                          <div className="loader"></div>
                        </div>
                      }
                    </div>
                  </TableMaterials>
                </div>
              </>
            }

            {(!displayAccess && !displayMaterials && !displayOrders) &&
              <>
                <div className="img-loading">
                  <img src="https://cria-demo.s3.sa-east-1.amazonaws.com/cria-decor/assets/img/logo-cria.png" />
                </div>
              </>
            }
          </div>
        </Graficos>
      </Container>
      <NavigationBar viewIndex={4}></NavigationBar>
    </>
  );
}

export default Dashboard