import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 27vw;
  width: 42vw;
  border-radius: 8px;
  background-color: transparent;
  margin: 0 2vw 1.4rem 2vw;
  cursor: pointer;
  transition:0.1s;

  :hover{
    opacity:0.8 !important;
    transition:0.2s;
  }

  @media only screen and (min-width: 1024px) {
    width: 8.6vw;
    height: 30.13vw !important;
    margin: 0 .4vw 1.4rem .4vw;

    div.active {
      z-index: 5 !important;
    }

    img {
      height: 30.13vw !important;
      width: 8.6vw !important;
    }
  
  }

  img {
    position: relative;
    border-radius: 8px;
    height: 27vw;
    width: 42vw;
    object-fit: cover;
  }

  div.card-shadow {
    height: 70%;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.9));
    bottom: 0;
  }

  div.inactive {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 7px;
    opacity: .7;
    background-color: ${props => props.backgroundColor};
    bottom: 0;
    cursor: default;
  }

  h4 {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #e3e3e3;
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }

  .active {
    z-index: 5;
  }
`