import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const AllThumb = (props) => {
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)

    return (
        <Container backgroundColor={backgroundColor}>
            <img src={props.imgSrc ? props.imgSrc : 'https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/template/banheiro-1.jpg'} alt="" />
            <div onClick={props.onThumbClick} className="card-shadow"></div>
        </Container>
    )
}

export default AllThumb