import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as loginActions from "./../../../../store/modules/login/actions";
import PlaycanvasContext from "../../../../components/playcanvasContext";
import api from "../../../../services/api";

// Styles
import { Container, SelectFaceAlert, LastAppliedList, ContainerLoading, Loading } from "./styles";

import selectFaceIcon from "./../../../../assets/pincel-preto.png";

// Components
import MaterialsTable from "./components/MaterialsTable";
import SearchInput from "./components/SearchInput";
import TypesTable from "./components/TypesTable";
import MaterialsFilter from "./components/MaterialsFilter";
import OrderByModal from "./components/OrderByModal";
import FilterBar from "./../../components/FilterBar";
import CircularProgress from '@material-ui/core/CircularProgress';
import { set } from "core-js/core/dict";

const Materials = (props) => {
  let [allMaterials, setAllMaterials] = useState([]);
  let [loadType, setLoadType] = useState(false);
  let [type, setType] = useState([]);
  let [page, setPage] = useState(1);
  const [selectedTypeMaterials, setSelectedTypeMaterials] = useState(null);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [filters, setFilters] = useState({
    color: [],
    brand: [],
    format: [],
  });
  const [haveFilter, sethaveFilter] = useState(false);
  const [orderBy, setOrderBy] = useState("Classificar por");
  const [displayFiltersModal, setDisplayFiltersModal] = useState(false);
  const [displayOrderBy, setDisplayOrderBy] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [loadingSearch, setloadingSearch] = useState(false);
  const [menu, setMenu] = useState(true);
  const [groupTitle, setGroupTitle] = useState("");
  const [currentCategory, setCurrentCategory] = useState("Porcelanato");
  const [searchBarVisibility, setSearchBarVisibility] = useState(true);

  const onboarding = useSelector((state) => state.loginReducer.onboarding);
  const fontColor = useSelector((state) => state.contractReducer.contract.config.fontColor);
  const backgroundColor = useSelector((state) => state.contractReducer.contract.config.backgroundColor);
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const contractId = useSelector((state) => state.contractReducer.contract.contractId);
  const user = useSelector((state) => state.loginReducer.user);
  //const allMaterials = useSelector(state => state.contractMaterials.data)

  const dispatch = useDispatch();

  const playcanvas = useContext(PlaycanvasContext);

  const removerAcentos = (newStringComAcento) => {
    return newStringComAcento.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }

  const groupMaterialsByTypeAndReproduction = (materials) => {

    let arr2 = [];
    materials.forEach((material, index) => {
      const helper = arr2.findIndex(
        (item) => item.description === material.type.description
      );
      if (helper != -1) {
        const helper2 = arr2[helper].reproduction.findIndex(
          (item) => item.description === material.reproduction.description
        );
        helper2 != -1
          ? arr2[helper].reproduction[helper2].materials.push(material)
          : arr2[helper].reproduction.push({
            description: material.reproduction.description,
            thumb: material.reproduction.thumbPath,
            materials: [material],
          });
      } else {
        arr2.push({
          description: material.type.description,
          reproduction: [
            {
              description: material.reproduction.description,
              thumb: material.reproduction.thumbPath,
              materials: [material],
            },
          ],
        });
      }
    });

    return arr2
  }

  useEffect(() => {

    selectGroup("Porcelanato");

    type.materials = [];

    if (contractId) {

      setloading(true);

      // api
      //   .get(`material/contract/${contractId}/reduced?active=true`)
      //   .then((data) => {

      //     const materials = groupMaterialsByTypeAndReproduction(data.data.materials)

      //     //props.types.flatMap((e) => e.reproduction).flatMap((e2) => e2.materials)

      //     setAllMaterials(materials.flatMap((e) => e.reproduction).flatMap((e2) => e2.materials))

      //     setloading(true);
      //   })
      //   .catch((error) => console.log(error));

    }
  }, []);



  useEffect(() => {
    

    if (selectedTypeMaterials) {
      const filtered2 = orderArray(filteredMaterials, orderBy);

      setFilteredMaterials(filtered2);
    } else {
      setFilteredMaterials([]);
    }

  }, [orderBy]);



  useEffect(() => {
    

    if (selectedTypeMaterials) {
      const filtered = selectedTypeMaterials.materials.filter(
        (m) => {
          filters.color.includes(m.color)
          filters.format.includes(m.format)
          filters.brand.includes(m.brand)
        }
          
      );


      const filtered2 = orderArray(filtered, orderBy);

      setFilteredMaterials(selectedTypeMaterials.materials);
    } else {
      setFilteredMaterials([]);
    }

  }, [filters, selectedTypeMaterials]);

  const applyFilters = () => {

    setloading2(false);

    let filtrosJson = JSON.stringify(filters);

    if(filters.color.length === 0 && filters.brand.length === 0 && filters.format.length === 0){
      selectGroup(groupTitle);
      sethaveFilter(false);
    }else{
      api.get(`material/contract/${contractId}/reducedFilter?active=true&group=${groupTitle}&filtros=${filtrosJson}`).then((data) => {
  
        setFilteredMaterials(data.data.materials);
        
        sethaveFilter(true);
        setloading2(true);
  
      }).catch((error) => console.log(error))
    }
      

    
  }

  useEffect(() => {
    if (searchText.length > 0) {
      setloadingSearch(false);

      setPage(1);
      setLoadType(false);

      if (contractId) {

        api.get(`material/contract/${contractId}/reducedFilter?active=true&searchString=${searchText}`).then((data) => {
          console.log(data.data);
          const materialsFilt = groupMaterialsByTypeAndReproduction(data.data.materials);

          setFilteredMaterials(data.data.materials);

          setloadingSearch(true);

        }).catch((error) => console.log(error))

      }

      // setFilteredMaterials();
    }

  }, [searchText]);

  const propsAsString = (obj) => {
    return Object.keys(obj)
      .map(function (k) {
        return obj[k];
      })
      .join(" ");
  }

  useEffect(() => {
    playcanvas.connectToIframe();
  }, []);

  const searchFilterItem = (m, searchTerm) => {
    let mat = {
      description: m.description,
      brand: m.brand,
      type: m.type.description,
      surface: m.surface.description,
      reproduction: m.reproduction.description,
      format: m.format,
      color: m.color,
    }


    // if (contractId) {
    //   api.get(`material/contract/${contractId}/reduced?active=true&searchString=${searchText}`).then((data) => {

    //     const materials = groupMaterialsByTypeAndReproduction(data.data.materials)

    //   }).catch((error) => console.log(error))

    // }

    const matString = removerAcentos(propsAsString(mat))

    const term = removerAcentos(searchText.replace('  ', ' ').split(' ').join('|'))
    const re = new RegExp(term, "gi")

    const result = [...new Set(matString.match(re))]

    return result.length >= searchText.split(" ").length
  }

  const searchFilter = (list, searchTerm) => {

    let filteredList = list.filter(
      (m) => {

        return searchFilterItem(m, searchTerm)

      })

    return orderArray(filteredList, orderBy);
  }

  const menuOpen = (a) => {
    if (a) {
      document.getElementById("btn-menu-open").classList.add("btn-close");
      document.getElementById("btn-menu-open").classList.remove("btn-open");
      document.getElementById('menu-catalogo').classList.add("open");
      document.getElementById('menu-catalogo').classList.remove("close");
    }
    else {
      document.getElementById("btn-menu-open").classList.remove("btn-close");
      document.getElementById("btn-menu-open").classList.add("btn-open");
      document.getElementById('menu-catalogo').classList.add("close");
      document.getElementById('menu-catalogo').classList.remove("open");
    }

  }


  const orderArray = (array, orderBy) => {
    switch (orderBy) {
      case 'Ordem alfabética':
        array.sort(function (a, b) {
          var textA = a.description.toUpperCase();
          var textB = b.description.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        return array;
      case 'Cor':
        array.sort(function (a, b) {
          var textA = a.color.toUpperCase();
          var textB = b.color.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        return array;
      case 'Mais aplicados':
        array.sort(function (a, b) {
          var nUsesA = a._id;
          var nUsesB = b._id;
          return nUsesA > nUsesB ? -1 : nUsesA < nUsesB ? 1 : 0;
        });
        return array;
      default:
        return array;
    }
  };
  /*
    useEffect(() => {
  
      setAllMaterials(
        props.types
          .flatMap(e => e.reproduction).flatMap(e2 => e2.materials)
      );
    }, [props.types]);;
  */
  useEffect(() => {
    setDisplayOrderBy(false);
  }, [orderBy, props.modal]);

  const handleClickBack = () => {
    setFilters({
      color: [],
      brand: [],
      format: [],
    });
    setSelectedTypeMaterials(null);
    setSearchText("");
  };

  const handleTypesThumbClick = (type) => {
    setSelectedTypeMaterials(type);
    setSearchText("");
    onboarding === 5 && dispatch(loginActions.setOnboarding(6));
  };

  const callM = (a) => {

    if (contractId) {
      api.get(`material/contract/${contractId}/reduced?active=true&category=${a}&page=${page}`).then((data) => {

        const materials = groupMaterialsByTypeAndReproduction(data.data.materials)

        setAllMaterials(materials.flatMap((e) => e.reproduction).flatMap((e2) => e2.materials))
        allMaterials = materials.flatMap((e) => e.reproduction).flatMap((e2) => e2.materials);

        type.materials = allMaterials;
        setSelectedTypeMaterials(type);
        setloading2(true);
      }).catch((error) => console.log(error))

    }

    setGroupTitle(a);

  }

  // console.log(localStorage)

  const selectGroupMore = (group, isChangePage, dataM, materialsLoading, setMaterialsLoading, setLoadM) =>{

    if (isChangePage != undefined) {
      if (isChangePage) {
        setPage(page + 1);
        page = page + 1;
      } else {
        setPage(page - 1);
        page = page - 1;
      }
    } else {
      setPage(1);
      page = 1;
    }


    if (contractId && !haveFilter) {
      api.get(`material/contract/${contractId}/reduced?active=true&category=${group}&page=${page}`).then((data) => {

        const materials = groupMaterialsByTypeAndReproduction(
          data.data.materials
        );

        let result = materials.flatMap((e) => e.reproduction).flatMap((e2) => e2.materials)
        result.map((i) => dataM.push(i))

        setTimeout(() => {
          setMaterialsLoading(materialsLoading + 60);
          setLoadM(false);
        },500)
        

        return dataM;

      }).catch((error) => console.log(error))

    }else{
      setLoadM(false);
    }
  }

  const selectGroup = (group, isChangePage) => {
    setMenu(false);
    setSearchBarVisibility(true);
    handleClickBack();
    setCurrentCategory(group);

    if (isChangePage != undefined) {
      if (isChangePage) {
        setPage(page + 1);
        page = page + 1;
      } else {
        setPage(page - 1);
        page = page - 1;
      }
    } else {
      setPage(1);
      page = 1;
    }

    setloading2(false);

    callM(group);

    setLoadType(true);

    if (document.getElementsByClassName("openGroup")[0])
      document.getElementsByClassName("openGroup")[0].classList.remove("openGroup")

    if (document.getElementById(group))
      document.getElementById(group).classList.add("openGroup");
    // else
    // setTimeout(() => selectGroup(group), 100);
  }

  return (
    <>
      {!loading ? (
        <Loading>
          <CircularProgress />
        </Loading>
      ) : (
        <></>
      )}

      {displayFiltersModal ? (
        <MaterialsFilter
          materials={selectedTypeMaterials}
          currentCategory={currentCategory}
          contractId={contractId}
          modal={displayFiltersModal}
          setModal={setDisplayFiltersModal}
          setFilters={setFilters}
          apply={applyFilters}
          filters={filters}
        ></MaterialsFilter>
      ) : (
        <>
          <>
            <Container fontColor={fontColor}>
              <div className="topClass">
                <div className="leftPosition">
                  {user.type !== "professional" && !props.selectedFace && (
                    <SelectFaceAlert
                      fontColor={fontColor}
                      backgroundColor={backgroundColor}
                    >
                      <div>
                        <div className="icon-div">
                          <img src={selectFaceIcon}></img>
                        </div>
                        Para aplicar um material, primeiro selecione uma
                        superfície.
                      </div>
                    </SelectFaceAlert>
                  )}

                  {props.lastAppliedMaterials.length === 0 && (
                    <div className="noRecente"></div>
                  )}
                  {searchBarVisibility && (
                    <div className="pesquisaClass">
                      <LastAppliedList fontColor={fontColor}>
                        <h2 className="recenteTitle">Recentes</h2>
                        {props.lastAppliedMaterials.length > 0 && (
                          <div>
                            {props.lastAppliedMaterials.map(
                              (material, index) => (
                                <img
                                  key={index}
                                  src={
                                    material.miniThumbPath
                                      ? material.miniThumbPath
                                      : material.thumbPath
                                  }
                                  onClick={() => {
                                    props.handleMaterialThumbClick(material);
                                    menuOpen(false);
                                  }}
                                ></img>
                              )
                            )}
                          </div>
                        )}
                        {props.lastAppliedMaterials.length === 0 && (
                          <div className="noRecenteMsg">
                            Nenhum material usado.
                          </div>
                        )}
                      </LastAppliedList>
                      <FilterBar
                        mainContent={
                          <>
                            <SearchInput
                              action={setSearchText}
                              clearSearch={callM}
                              group={groupTitle}
                              value={searchText}
                              setFiltersModal={setDisplayFiltersModal}
                              margin={"12px 8px 12px 0"}
                              width={selectedTypeMaterials ? "110px" : "100%"}
                            />
                          </>
                        }
                      ></FilterBar>
                    </div>
                  )}
                </div>

                <div className="rightPosition">
                  <div className="bannerClass">
                    <h2>+6.000 materiais</h2>
                    <h3>+150 indústrias</h3>
                    <a href="https://www.collection.com.br/" target="_blank">
                      <button>Quero conhecer</button>
                    </a>
                  </div>
                </div>
              </div>
              {loadType ? (
                <>
                  <div className="materialsContent">
                    <div
                      className={
                        menu ? "rightSide openMenu" : "rightSide closeMenu"
                      }
                    >
                      <div className="categoriasTitle">
                        <h4>Categorias</h4>
                        <h5>Selecione uma categoria para ver os materiais.</h5>
                      </div>

                      <button
                        className={"buttonGroup class-Porcelanato"}
                        onClick={() => selectGroup("Porcelanato")}
                      >
                        Porcelanato
                      </button>
                      <button
                        className={"buttonGroup class-Pedras"}
                        onClick={() => selectGroup("Pedras")}
                      >
                        Pedras
                      </button>
                      <button
                        className={"buttonGroup class-MDF"}
                        onClick={() => selectGroup("MDF")}
                      >
                        MDF
                      </button>
                      <button
                        className={"buttonGroup class-Pastilha"}
                        onClick={() => selectGroup("Pastilha")}
                      >
                        Pastilha
                      </button>
                      <button
                        className={"buttonGroup class-LaminadoeVinilico"}
                        onClick={() => selectGroup("Laminado e Vinilico")}
                      >
                        Laminado e Vinilico
                      </button>
                      <button
                        className={"buttonGroup class-Tinta"}
                        onClick={() => selectGroup("Tinta")}
                      >
                        Tinta
                      </button>
                      <button
                        className={"buttonGroup class-Tijolo"}
                        onClick={() => selectGroup("Tijolo")}
                      >
                        Tijolo
                      </button>
                      <button
                        className={"buttonGroup class-Carpete"}
                        onClick={() => selectGroup("Carpete")}
                      >
                        Carpete
                      </button>
                      <button
                        className={"buttonGroup class-PapeldeParede"}
                        onClick={() => selectGroup("Papel de Parede")}
                      >
                        Papel de Parede
                      </button>
                      <button
                        className={"buttonGroup class-Generico"}
                        onClick={() => selectGroup("Generico")}
                      >
                        Generico
                      </button>
                    </div>
                    <div
                      className={
                        menu ? "leftSide closeItem" : "leftSide openItem"
                      }
                    >
                      <div className="filtrosClass">
                        <FilterBar
                          mainContent={
                            <>
                              {selectedTypeMaterials && (
                                <>
                                  <button
                                    className={
                                      filters.color.length > 0 ||
                                      filters.brand.length > 0 ||
                                      filters.format.length > 0
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() =>
                                      setDisplayFiltersModal(
                                        !displayFiltersModal
                                      )
                                    }
                                  >
                                    Filtros
                                    <i className="fas fa-filter"></i>
                                  </button>
                                  <button
                                    className={
                                      orderBy === "Classificar por" ? "" : ""
                                    }
                                    onClick={() =>
                                      setDisplayOrderBy(!displayOrderBy)
                                    }
                                  >
                                    {orderBy}
                                    <i className="fas fa-chevron-down"></i>
                                  </button>
                                </>
                              )}
                            </>
                          }
                        ></FilterBar>
                      </div>

                      <nav className="navbar">
                        {/* <i
                            onClick={() => handleClickBack()}
                            className="fas fa-arrow-left"
                          ></i> */}
                        <h1>
                          {" "}
                          <button
                            className="backButton"
                            onClick={() => {
                              setMenu(true);
                              setSearchBarVisibility(false);
                            }}
                          >
                            <i className="fas fa-arrow-left"></i>
                          </button>
                          {groupTitle}
                        </h1>
                      </nav>

                      {loading2 && (
                        <>
                          <MaterialsTable
                            setModal={props.setModal}
                            data={filteredMaterials}
                            onMaterialClick={props.handleMaterialThumbClick}
                            getMore={selectGroupMore}
                            groupTitle={groupTitle}
                            page={page}
                            lenghFilt={filteredMaterials.length}
                          />
                          <br />

                          {/* { window.innerWidth > 1024 &&
                          <div className="buttonsPage">
                            {page != 1 &&
                            <>
                              <button onClick={() => selectGroup(groupTitle, false)}><font>Voltar página</font> <i className="fas fa-angle-left"></i></button>
                              <span>Página {page}</span>
                              </>
                            }
                            {filteredMaterials.length === 60 &&
                              <button onClick={() => selectGroup(groupTitle, true)} className={page === 1 ? "first" : ""}><font>Proxima página</font> <i className="fas fa-angle-right"></i></button>
                            }
                            
                          </div>
                          } */}
                        </>
                      )}
                      {!loading2 && (
                        <div className="loadingClass">
                          <CircularProgress />
                        </div>
                      )}
                      <div className={displayOrderBy ? "modalOrdenar" : ""}>
                        <OrderByModal
                          display={displayOrderBy}
                          fullScreen={props.fullScreen}
                          title={"Ordenar por"}
                          fitContent={true}
                          onCloseClick={() => setDisplayOrderBy(false)}
                          mainContent={
                            <>
                              <div
                                onClick={() => setOrderBy("Mais aplicados")}
                                className={
                                  orderBy === "Mais aplicados"
                                    ? "option selected"
                                    : "option"
                                }
                              >
                                Mais aplicados
                              </div>
                              <div
                                onClick={() => setOrderBy("Ordem alfabética")}
                                className={
                                  orderBy === "Ordem alfabética"
                                    ? "option selected"
                                    : "option"
                                }
                              >
                                Ordem alfabética
                              </div>
                              <div
                                onClick={() => setOrderBy("Cor")}
                                className={
                                  orderBy === "Cor"
                                    ? "option selected"
                                    : "option"
                                }
                              >
                                Cor
                              </div>
                            </>
                          }
                        ></OrderByModal>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="materialsContent">
                    <div
                      className={
                        menu ? "rightSide openMenu" : "rightSide closeMenu"
                      }
                    >
                      <div className="categoriasTitle">
                        <h4>Categorias</h4>
                        <h5>Selecione uma categoria para ver os materiais.</h5>
                      </div>

                      {/* {groupMaterialsByTypeAndReproduction(allMaterials).map((group, index) => (
                          <>
                            <button className={"buttonGroup class-" + group.description.replace(/ /g, "")} onClick={() => selectGroup(group.description.replace(/ /g, ""))}>{group.description}</button>
                          </>
                        ))} */}

                      <button
                        className={"buttonGroup class-Porcelanato"}
                        onClick={() => selectGroup("Porcelanato")}
                      >
                        Porcelanato
                      </button>
                      <button
                        className={"buttonGroup class-Pedras"}
                        onClick={() => selectGroup("Pedras")}
                      >
                        Pedras
                      </button>
                      <button
                        className={"buttonGroup class-MDF"}
                        onClick={() => selectGroup("MDF")}
                      >
                        MDF
                      </button>
                      <button
                        className={"buttonGroup class-Pastilha"}
                        onClick={() => selectGroup("Pastilha")}
                      >
                        Pastilha
                      </button>
                      <button
                        className={"buttonGroup class-LaminadoeVinilico"}
                        onClick={() => selectGroup("Laminado e Vinilico")}
                      >
                        Laminado e Vinilico
                      </button>
                      <button
                        className={"buttonGroup class-Tinta"}
                        onClick={() => selectGroup("Tinta")}
                      >
                        Tinta
                      </button>
                      <button
                        className={"buttonGroup class-Tijolo"}
                        onClick={() => selectGroup("Tijolo")}
                      >
                        Tijolo
                      </button>
                      <button
                        className={"buttonGroup class-Carpete"}
                        onClick={() => selectGroup("Carpete")}
                      >
                        Carpete
                      </button>
                      <button
                        className={"buttonGroup class-PapeldeParede"}
                        onClick={() => selectGroup("Papel de Parede")}
                      >
                        Papel de Parede
                      </button>
                      <button
                        className={"buttonGroup class-Generico"}
                        onClick={() => selectGroup("Generico")}
                      >
                        Generico
                      </button>
                    </div>
                    <div
                      className={
                        menu ? "leftSide closeItem" : "leftSide openItem"
                      }
                    >
                      <button
                        className="backButton"
                        onClick={() => {
                          setMenu(true);
                          setSearchBarVisibility(false);
                        }}
                      >
                        <i className="fas fa-arrow-left"></i>
                      </button>

                      {searchText.length > 0 && (
                        <>
                          <br />
                          {loadingSearch && (
                            <MaterialsTable
                              setModal={props.setModal}
                              data={filteredMaterials}
                              onMaterialClick={props.handleMaterialThumbClick}
                              isSearch={true}
                            />
                          )}
                          {!loadingSearch && (
                            <div className="loadingClass">
                              <CircularProgress />
                            </div>
                          )}
                        </>
                      )}
                      {/* {searchText.length === 0 && allMaterials && (
                          <TypesTable
                            data={groupMaterialsByTypeAndReproduction(allMaterials)}
                            action={handleTypesThumbClick}
                          />
                        )} */}
                    </div>
                  </div>
                </>
              )}
            </Container>
          </>
        </>
      )}
    </>
  );
};

export default Materials;
