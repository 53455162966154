import React, { useEffect, useState } from "react";
import {
  BrandsContainer,
  Container,
  HeaderMateriais,
  ListContainer,
  MateriaisFiltrados,
  PageContainer,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../../services/api";
import * as materialsActions from "../../../../store/modules/materials/actions";

const Materias = (props) => {
  const dispatch = useDispatch();
  const { visible } = props;
  const materials = useSelector((state) => state.materialsReducer.materials);
  const isMaterialsLoading = useSelector(
    (state) => state.materialsReducer.isLoading
  );

  const [brandsSelecionados, setBrandSelecionado] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    dispatch(materialsActions.getAllMaterialsReduced(null));
  }, []);
  useEffect(() => {
    console.log(brandsSelecionados);
  }, [brandsSelecionados]);
  useEffect(() => {
    if (!isMaterialsLoading) {
      setBrands(
        Array.from(
          new Set(
            materials.map((item) => {
              return item.brand;
            })
          )
        )
      );
    }
  }, [isMaterialsLoading]);

  const onChangeHandler = (event) => {
    console.log(event.target.checked);
    console.log(event.target);
    const { name, value, id, checked } = event.target;
    if (name === "marcas") {
      debugger;
      if (!checked) {
        const newState = brandsSelecionados.filter((element) => {
          if (element != id) {
            return element;
          }
        });
        setBrandSelecionado(newState);
      } else {
        setBrandSelecionado((prevSelecionados) => [...prevSelecionados, id]);
      }
    }
  };
  return (
    <>
      {visible && (
        <>
          {isMaterialsLoading ? (
            <>bom dia</>
          ) : (
            <>
              <HeaderMateriais>
                <h1 className="text-header">Materiais</h1>
              </HeaderMateriais>
              <Container>
                <BrandsContainer>
                  <h3>Marcas</h3>

                  {brands.map((brand, index) => (
                    <ListContainer key={index}>
                      <input
                        id={brand}
                        type="checkbox"
                        name="marcas"
                        onChange={(e) => onChangeHandler(e)}
                        value={
                          brandsSelecionados.indexOf(brand) === -1
                            ? false
                            : true
                        }
                        checked={
                          brandsSelecionados.indexOf(brand) === -1
                            ? false
                            : true
                        }
                      ></input>
                      <p>{brand}</p>
                    </ListContainer>
                  ))}
                </BrandsContainer>
                <MateriaisFiltrados>
                  <h3>Materiais</h3>
                  {brandsSelecionados.length <= 0 ? (
                    <>
                      <p>selecione uma marca</p>
                    </>
                  ) : (
                    <>
                      <p>bom dia</p>
                    </>
                  )}
                </MateriaisFiltrados>
              </Container>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Materias;
