import * as types from '../types'

const initialState = {
    user: [],
    isLoading: true,
    id : undefined
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_USER_SUCCESS: {
            const newState = { ...state }
            newState.user = action.payload.data.user
            newState.id = action.payload.data.user._id
            newState.isLoading = false
            return newState
        }
        default: {
            return state;
        }
    }
}