
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const SimulatorTopBar = (props) => {

    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)    
    
    useEffect(() => {
        const TopBar = document.getElementById("TopBar")
        var iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false);

        if (iOS == true) {
            TopBar.style.marginTop = '20px';
        } else{
            TopBar.style.marginTop = '20px';
        }
    }, [])
    
    return (       
        <Container
            backgroundColor="#E4E1E1"
            fontColor={fontColor}
            brandColor={brandColor}
            brandContrastColor={brandContrastColor}
        >
            <div id="TopBar" className="navbar">
            <div
                className="arrow-left-button"
                onClick={
                (e) => {
                    props.setConfirmBackModal(true);
                } /* props.onReturnClick */
                }
            >
                <i className="fas fa-arrow-left"></i>
            </div>
            {/* <div className="undo-button" onClick={props.onUndoClick}>
                        <i className="fas fa-undo"></i>
                    </div> 
                    <div className="expand-button" onClick={props.onFullScreenClick}>
                        <i className="fas fa-expand"></i>
                    </div>*/}

            <img src="https://tinyurl.com/2zjml9oh" alt="Logotipo" className="logo-class"></img>

            {/* <div className="scene-list" onClick={props.onListClick}>
                        <i className="fas fa-list-ul"></i>
                    </div> */}

            <div className="scene-materials-css" onClick={props.onListClick}>
                <img src="https://i.ibb.co/C5QY6Tr/list.png" />{" "}
                <font className="mobile">Ver materiais utilizados</font>{" "}
                <font className="desktop">Ver lista de materiais utilizados</font>
            </div>
            
            {/* <div className="text" onClick={props.onFinishClick}>
                        CONCLUIR
                    </div> */}
            </div>
        </Container>
    );

}

export default SimulatorTopBar