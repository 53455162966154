import * as types from '../types'

const initialState = {
    rooms: [],
    isLoading: true,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case types.GET_USER_ROOMS_SUCCESS: {
            const newState = { ...state }
            newState.rooms = action.payload.data.orders
            newState.isLoading = false
            return newState
        }
        case types.RETURN_USER_ROOMS_INITIAL_STATE: {
            const newState = { ...initialState }
            return newState
        }
        default: {
            return state;
        }
    }
}