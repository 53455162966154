import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    height: ${props => props.innerHeight}px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: url(https://i.ibb.co/g9JMqMx/932cfe206c1201b4c0c69ad790eb353a.jpg), rgba(245, 245, 245, 0.85);
    background-blend-mode: overlay;
    background-size: auto 100% !important;
    font-family: 'Montserrat', sans-serif;
    overflow-y: auto;

    @media only screen and (min-width: 1024px) {
        background-size: 100% auto !important;
        background: url(https://i.ibb.co/g9JMqMx/932cfe206c1201b4c0c69ad790eb353a.jpg), rgba(245, 245, 245, 0.85);
    }

    .MuiCircularProgress-colorPrimary {
        color: #999999;
    }

    .show-password{
        position: absolute;
        top: 40%;
        right: 15px;
        cursor: pointer;
        color: #0000008c;
    }

    .teste-gratis {
        margin-bottom: 4px;
        width: 314px;
        text-align: center;
        font-weight: 500;        
    }

    .input-password{
        position: relative;
    }

    .titulo-1{
        font-size:20px;
        font-weight:500;
        margin-top: 10px;
    }

    @media (max-height:658px){
        .img-logo, .frase-2{
            display: none !important;
        }
    }

    @media (max-height:531px){
        .descricao{
            display: none !important;
        }
    }

    @media (max-height:446px){
        .cadastro{
            display: none !important;
        }
    }

    .titulo-2{
        font-size:30px;
        font-weight:700;
    }

    .titulo-3{
        font-size:20px;
        color: #9E8E75
    }

    .descricao{
        text-align: center;
        font-size:14px;
        margin: 16px 0;
        max-width: 16vw;
    }

    .input-container{
        width:15%
    }

    .input-container input, .content input{
        width:100%;
        margin: 5px 0 5px 0;
        height:38px !important;
        border: 0;
        padding: 0 10px;
        border-radius: 0 !important;
    }

    .content input:hover{
        
    }

    .submit-button{
        width: 15%;
        margin-top:20px;
        border:0;
        background-color:#404040;
        color: white;
        padding:10px;
        border-radius:0 !important;
        cursor:pointer;
    }

    @media only screen and (max-width: 1700px) {
        .submit-button{
            width: 20%;
        }

        .input-container{
            width:20%
        }
    }

    .submit-button:hover{
        transition:0.2s;
        background-color:#606060;
    }

    .esqueci{
        font-weight:bold;
        font-size:12px;
        margin-top:20px;
        cursor:pointer;
    }

    .cadastro{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(133, 142, 125, 0.5), rgba(133, 142, 125, 0.5)),url(https://i.ibb.co/98Vch40/064a8d4aba61357dec5b7914f3a3e4bd.jpg);
        width: 40%;
        background-size: 100% auto;
        padding: 30px 0;
        background-position-y: 65%;
        margin-top: 30px;
        transform: scaleX(-1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 1367px) {
        .cadastro{
            width: 50%;
            padding: 15px 0;
        }
    }

    .cadastro .content{
        transform: scaleX(-1);
        color: white
    }

    .cadastro .content .frase-1{
        font-size:18px;
    }

    .cadastro .content .frase-2{
        margin: 5px 0;
        opacity:0.9
    }

    .cadastro .content button{
        width:45%;
        padding: 10px;
        background-color: #858E7D;
        color:white;
        border:0;
        margin-top: 10px
    }

    .cadastro .content button:hover{
        background-color: #9ca396;
        transition:0.2s
    }

    .buttons-div{
        border-radius: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        background-color: #484848 !important;
    }
    
    .dMazrD div.content input:focus-within, .dMazrD div.content input:hover{
        border: 1px solid rgba(20,20,20,0.2) !important;
    }

    div.content h3{
        color: #525252;
    }

    .error{
        color: #c53434;
    }

    .spin{
        margin: 8px;
    }

    @media only screen and (max-width: 1124px) {
        .submit-button{
            width: 40%;
        }

        .input-container{
            width:40%
        }

        .cadastro {
            width: 50%;
            padding: 15px 30px;
        }
    }

    @media only screen and (max-width: 600px) {
        .submit-button{
            width: 80%;
        }

        .input-container{
            width:80%
        }

        .cadastro {
            width: 80%;
            padding: 15px 30px;
        }

        .cadastro .content button{
            width: 80%
        }

        .cadastro .content .frase-1{
            font-size: 16px
        }

        .creditos{
            font-size:10px
        }

        .descricao{
            max-width: 100vw;
        }
    }

    .creditos{
        color: #6C6C6C;
        position: absolute;
        bottom:10px;
    }

    .center{
        text-align: center
    }
`

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  height: 38px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.7);
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  img {
    height: 24px;
  }
`