import styled from 'styled-components'

export const Container = styled.div`
padding: 24px;
background-color: ${props => props.backgroundColor};
box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 20%);
border-radius: 15px;
height: 60vh;

h1 {
  margin: 0px;
  margin-bottom: 14px;
  color: ${props => props.fontColor};
}

input {
  padding: 4px 8px;
  border-radius: 15px;
  border: 1px #bebebe solid;
}

.rooms {
  border: 1px solid #EAEAEA;
  border-radius: 15px;
  width: 100%;
  height: calc(100% - 60px);
  display: -ms-flexbox;
  -ms-flex-direction: column;
  padding: 24px;
  overflow-y: scroll;
}

.slider {
  transition: transform 0.3s linear;
  ::-webkit-scrollbar {
    display: none;
  }
}

button.button-back, 
button.button-next {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  float: right;
  border: none;
  font-size: 12px;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.25);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  
}

button.button-back:disabled, 
button.button-next:disabled {
  display: none;
}

button.button-back:hover, 
button.button-next:hover {
  opacity: .8;  
}

button.button-back {
  left: -0.5vw;
}

button.button-next {
  right: 1vw;
}

.carousel-wrapper {
  overflow-x:hidden;
  margin-bottom: 32px;
}

.thumb-slider {
  overflow-x: auto;
}

.category-title {
  display: flex;
  margin-bottom: 12px;
}

h2 {
  /* margin: 24px 0px 12px 12px; */
  margin: 0px 12px;
}

.selecionar-todos-checkbox {
  display: flex;
  align-self: center;  
}

.selecionar-todos-checkbox input {
  display: flex;
  align-self: center;  
  margin: 0px 4px;
}

.rooms-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
}

.btn-novo-ambiente, .btn-vincular-contratos {
  padding: 7px 11px;
  border-radius: 15px;
  border: none;
  font-size: 12px;
  box-shadow: 0 0.2px 4px rgb(0 0 0 / 10%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: #2196F3;
}

.btn-vincular-contratos {
  width: auto!important;
  margin-top:1rem;
}

` 