import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'

// Styles
import { Container } from './styles'
import FormControl from '@material-ui/core/FormControl'
import { SketchPicker } from 'react-color'

import { contractContext } from "../../../../Contexts/contractContext";

const WhiteLabel = (props) => {

  const _brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const _brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const _backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const _fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const { currentContract, setCurrentContract, logochanged, setLogochanged } = useContext(contractContext)

  const [logoPath, setLogoPath] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('')
  const [fontColor, setFontColor] = useState('')
  const [url, setUrl] = useState('')
  const [brandColor, setBrandColor] = useState('')
  const [brandContrastColor, setBrandContrastColor] = useState('')

  const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false)
  const [displayFontColorColorPicker, setDisplayFontColorColorPicker] = useState(false)
  const [displayBrandColorColorPicker, setDisplayBrandColorColorPicker] = useState(false)
  const [displayBrandContrastColorColorPicker, setDisplayBrandContrastColorColorPicker] = useState(false)

  const inputFile = useRef(null) 

  useEffect(() => {
    if (currentContract.whiteLabel){      
      setLogoPath(currentContract.whiteLabel.logoPath)
      setBackgroundColor(currentContract.whiteLabel.backgroundColor)
      setFontColor(currentContract.whiteLabel.fontColor)    
      setUrl(currentContract.whiteLabel.url)
      setBrandColor(currentContract.whiteLabel.brandColor)
      setBrandContrastColor(currentContract.whiteLabel.brandContrastColor)
    }
    else{
      setLogoPath('')
      setBackgroundColor('')
      setFontColor('')    
      setUrl('')
      setBrandColor('')
      setBrandContrastColor('')      
    }
  }, [currentContract])
  
  useEffect(() => {

    if (currentContract){
      let whileLabelHelper = {}

      if (currentContract.whiteLabel){      
        whileLabelHelper = currentContract.whiteLabel
      }

        whileLabelHelper.logoPath = logoPath
        whileLabelHelper.backgroundColor = backgroundColor
        whileLabelHelper.fontColor = fontColor
        whileLabelHelper.url = url
        whileLabelHelper.brandColor = brandColor
        whileLabelHelper.brandContrastColor = brandContrastColor
        
        currentContract.whiteLabel = whileLabelHelper

        //setContract({whiteLabel : whileLabelHelper})
    }
  }, [logoPath, backgroundColor, fontColor, url, brandColor, brandContrastColor])

  const closeColorPickers = () => {  
    setDisplayBrandColorColorPicker(false)
    setDisplayBrandContrastColorColorPicker(false)
    setDisplayBackgroundColorPicker(false)    
    setDisplayFontColorColorPicker(false)
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
   inputFile.current.click();
  };

  const handleFileSelect = (file) => {
    setLogoPath(URL.createObjectURL(file))
    setLogochanged(true)
  }

  return (
    <Container backgroundColor={_backgroundColor} fontColor={_fontColor} brandColor={_brandColor}>
        
    <div className="whiteLabel">

      <FormControl className="whiteLabel-items logo-item">
        <label>Logo</label>
        <img className="logo-img" src={logoPath} />
        <button type="file" className="logo-btn" onClick={onButtonClick}>Selecionar</button>
        <input type='file' id='file' ref={inputFile} accept="image/png" onChange={e => handleFileSelect(e.target.files[0])} style={{display: 'none'}}/>
      </FormControl>

      <FormControl className="whiteLabel-items">
        <label>Cor de Fundo</label>
        <input type="text" value={backgroundColor} onClick={() => setDisplayBackgroundColorPicker(true) }/>
        <i id="square-color" style={{ color: `${backgroundColor}` }} class="fas fa-square" onClick={() => setDisplayBackgroundColorPicker(true)} />
        {displayBackgroundColorPicker ? 
          <div className="popover">
            <div className="cover" onClick={() => setDisplayBackgroundColorPicker(false)} />
              <SketchPicker disableAlpha={false} color={backgroundColor} onChangeComplete={ e => setBackgroundColor(e.hex) }/>              
          </div>
        : null}
      </FormControl>

      <FormControl className="whiteLabel-items">
        <label>Cor da Fonte</label>
        <input type="text" value={fontColor} onClick={() => setDisplayFontColorColorPicker(true)} />
        <i id="square-color" style={{ color: `${fontColor}` }} class="fas fa-square" onClick={() => setDisplayFontColorColorPicker(true)} />
        {displayFontColorColorPicker ? 
          <div className="popover">
            <div className="cover" onClick={() => setDisplayFontColorColorPicker(false)} />
              <SketchPicker disableAlpha={false} color={fontColor} onChangeComplete={ e => setFontColor(e.hex) }/>              
          </div>
        : null}
      </FormControl>

      <br/>
      <br/>

      <FormControl className="whiteLabel-items url-item">
        <label>URL Própria <small>(não utilizar)</small></label>
        <input type="text" value={url} onChange={e => setUrl(e.target.value)} />
      </FormControl>

      <FormControl className="whiteLabel-items">
        <label>Cor da Loja</label>
        <input type="text" value={brandColor} onClick={() => setDisplayBrandColorColorPicker(true)} />          
        <i id="square-color" style={{ color: `${brandColor}` }} class="fas fa-square" onClick={() => setDisplayBrandColorColorPicker(true)} />
        {displayBrandColorColorPicker ? 
          <div className="popover">
            <div className="cover" onClick={() => setDisplayBrandColorColorPicker(false)} />
              <SketchPicker disableAlpha={false} color={brandColor} onChangeComplete={ e => setBrandColor(e.hex) }/>              
          </div>
        : null}
      </FormControl>
      
      <FormControl className="whiteLabel-items">
        <label>Cor de Contraste</label>
        <input type="text" value={brandContrastColor} onClick={() => setDisplayBrandContrastColorColorPicker(true)} />       
        <i id="square-color" style={{ color: `${brandContrastColor}` }} class="fas fa-square" onClick={() => setDisplayBrandContrastColorColorPicker(true)} />   
        {displayBrandContrastColorColorPicker ? 
          <div className="popover">
            <div className="cover" onClick={() => setDisplayBrandContrastColorColorPicker(false)} />
              <SketchPicker disableAlpha={false} color={brandContrastColor} onChangeComplete={ e => setBrandContrastColor(e.hex) }/>              
          </div>
        : null}
      </FormControl>
      
    </div>
  </Container>
  )
}

export default WhiteLabel