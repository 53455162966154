import * as types from '../types'

export function getUserRooms(payload) {
    return {
        type: types.GET_USER_ROOMS,
        payload,
    }
}

export function getUserRoomsSuccess(payload) {
    return {
        type: types.GET_USER_ROOMS_SUCCESS,
        payload,
    }
}

export function returnUserRoomsInitialState() {
    return {
        type: types.RETURN_USER_ROOMS_INITIAL_STATE,
    }
}