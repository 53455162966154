import React from "react";
import { useSelector } from "react-redux";
import { DebounceInput } from "react-debounce-input";

// Styles
import { Input } from "./styles";

const SearchInput = ({
  action,
  value,
  setFiltersModal,
  width,
  groupTitle,
  cleanSearch,
  ...rest
}) => {
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  return (
    <Input width={width} fontColor={fontColor} brandColor={brandColor}>
      <div className="input__container">
        <i className="fas fa-search"></i>

        <DebounceInput
          minLength={3}
          debounceTimeout={300}
          onChange={(e) => action(e.target.value)}
          placeholder="Buscar materiais "
          value={value}
        />

        {value.length > 0 && (
          <i
            onClick={() => {
              action("");
            }}
            className="fas fa-times"
          ></i>
        )}
      </div>
    </Input>
  );
};

export default SearchInput;
